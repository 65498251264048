<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title"
            >
                <template v-slot:header>
                    <div class="row mb-1">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/testimony-video-library/add')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block d-flex align-items-center justify-content-center">
                        <switches
                            @input="toggleVisibility(data)"
                            :emit-on-mount="false"
                            v-model="data.isVisible" 
                            :label="data.isVisible ? 'Show' : 'Hidden'"
                            :color="data.isVisible ? 'green' : 'red'"
                            class="mr-1">
                        </switches>
                        <router-link :to="'/testimony-video-library/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue';
import Switches from 'vue-switches';

export default {
	components: {
        Table,
        Switches,
	},
	data() {
		return {
			table: {
                title: 'Testimony Video Library',
                dataTable: {
                    header: ['#', 'Name', 'Age', 'Sex', 'Condition Detail', 'Action'],
                    columns: ['counter', 'name', 'age', 'sex', 'conditionName'],
                    lists: [],
                }
			},
            limit: 10,
            isLoading: false,
		};
	},
    mounted() {
        this.getList();

        this.$root.$on("paginate", (data) => {
            this.getList(data);
        });
        this.$root.$on("changeLimit", (data) => {
            this.limit = data;
            this.getList();
        });
    },
    methods: {
        getList(page = 1) {
            this.axios.get('/testimony-library', {
                params: {
                    page,
                    limit: this.limit,
                    sortBy: 'updatedAt.DESC',
                    type: 'video'
                }
            })
                .then((resp) => {
                    this.table.dataTable.lists = {
                        ...resp.data.data,
                        rows: resp.data.data.rows.map(item => ({
                            ...item,
                            conditionName: item.condition.name,
                            sex: item.sex === 'MALE' ? 'Laki-laki' : 'Perempuan',
                        })),
                    };
                });
        },
        toggleVisibility(data) {
            this.axios.put('/testimony-library/visibility', {
                id: data.id,
                isVisible: data.isVisible,
            });
        },
        destroy(data) {
            this.$swal.fire({
                title: 'Are you sure want to delete this testimony video?',
                text: data.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (result.value) {
                    this.axios.delete(`/testimony-library/${data.id}`)
                        .then((resp) => {
                            this.$swal.fire({
                                title: 'Deleted!',
                                text: resp.message,
                                icon: 'success',
                                confirmButtonColor: '#0036A0',
                                confirmButtonText: 'OK'
                            });
                            this.getList();
                        })
                        .catch(() => {
                            this.$notify({
                                type: 'error',
                                title: 'Error!',
                                text: 'Failed to delete',
                            });
                        });
                }
            });
        },
    },
}
</script>