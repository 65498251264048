import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { HIDDEN_MENUS } from '@/constant/menu';
import Home from '../views/Home.vue'

import Undermaintenance from '../views/errors/503.vue'
import NotFound from '../views/errors/404.vue'
import Forbidden from '../views/errors/403.vue'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import EditProfile from '../views/user/EditProfile.vue'
import ChangePassword from '../views/user/ChangePassword.vue'

import AdminIndex from '../views/admin/Index.vue'
import AdminForm from '../views/admin/Form.vue'

import RolePermissionIndex from '../views/role_permission/Index.vue'
import RolePermissionEdit from '../views/role_permission/Edit.vue'

import ContentIndex from '../views/content/Index.vue'
import ContentAdd from '../views/content/Add.vue'

import QuestionnaireIndex from '../views/questionnaire/Index.vue'
import QuestionnaireForm from '../views/questionnaire/Form.vue'

import FlowIndex from '../views/flow/Index.vue'
import FlowForm from '../views/flow/Form.vue'

import ProductIndex from '../views/product/Index.vue'
import ProductForm from '../views/product/Form.vue'
import ProductRatingIndex from '../views/product/rating/Index.vue'

import TreatmentIndex from '../views/treatment/Index.vue'
import TreatmentAdd from '../views/treatment/Add.vue'

import PromotionIndex from '../views/promotion/Index.vue'
import PromotionAdd from '../views/promotion/Add.vue'
import PopupForm from '../views/popup/Form.vue'

import MasterLanding from '../views/discoverypages/MasterLanding.vue'

import AdvisorPage from '../views/discoverypages/advisor-page.vue'

import AboutUs from '../views/discoverypages/about-us.vue'

import PromotionPage from '../views/discoverypages/promotion-page.vue'

import BlogPage from '../views/discoverypages/article-page.vue'

import FaqPage from '../views/discoverypages/FaqPage.vue'

import PolyclinicIndex from '../views/discoverypages/polyclinic-library/Index.vue'
import PolyclinicForm from '../views/discoverypages/polyclinic-library/Form.vue'

import ConditionIndex from '../views/discoverypages/condition-library/Index.vue'
import ConditionForm from '../views/discoverypages/condition-library/Form.vue'

import BlogIndex from '../views/discoverypages/article-library/Index.vue'
import BlogForm from '../views/discoverypages/article-library/Form.vue'

import TagIndex from '../views/discoverypages/article-tag/Index.vue'
import TagForm from '../views/discoverypages/article-tag/Form.vue'

import CategoryIndex from '../views/discoverypages/article-category/Index.vue'
import CategoryForm from '../views/discoverypages/article-category/Form.vue'

import DoctorIndex from '../views/discoverypages/medical-advisor-library/Index.vue'
import DoctorForm from '../views/discoverypages/medical-advisor-library/Form.vue'

import SpecialistDoctorIndex from '../views/discoverypages/medical-advisor-expertise/Index.vue'
import SpecialistDoctorForm from '../views/discoverypages/medical-advisor-expertise/Form.vue'

import FaqIndex from '../views/discoverypages/faq-library/Index.vue'
import FaqForm from '../views/discoverypages/faq-library/Form.vue'

import TagFaqIndex from '../views/discoverypages/faq-tag/Index.vue'
import TagFaqForm from '../views/discoverypages/faq-tag/Form.vue'

import CategoryFaqIndex from '../views/discoverypages/faq-category/Index.vue'
import CategoryFaqForm from '../views/discoverypages/faq-category/Form.vue'

import CompanySettingIndex from '../views/discoverypages/company-settings/CompanySetting.vue'

import SpecialProductPageIndex from '../views/discoverypages/special-product-page/Index.vue'
import SpecialProductPageForm from '../views/discoverypages/special-product-page/Form.vue'

import LandingPageForm from '../views/landing/Index.vue'

import RegimenPageIndex from '../views/regimen/Index.vue'
import RegimenPageForm from '../views/regimen/Form.vue'
import MobileHome from '../views/mobileapp/MobileHome.vue'

import SchoolHomepage from '../views/school/homepage/Index.vue'

import SchoolDirectoryList from '../views/school/directory/Index.vue'
import SchoolDirectoryForm from '../views/school/directory/Form.vue'

import SchoolCardList from '../views/school/card/Index.vue'
import SchoolCardForm from '../views/school/card/Form.vue'

import InterimPageIndex from '../views/discoverypages/interim-page/Index.vue'
import InterimPageForm from '../views/discoverypages/interim-page/Form.vue'

import TestimonyLibraryIndex from '../views/discoverypages/testimony-library/Index.vue'
import TestimonyLibraryForm from '../views/discoverypages/testimony-library/Form.vue'

import TestimonyVideoLibraryIndex from '../views/discoverypages/testimony-video-library/Index.vue'
import TestimonyVideoLibraryForm from '../views/discoverypages/testimony-video-library/Form.vue'

import TestimonyPage from '../views/discoverypages/TestimonyPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile,
            },
            {
                path: '/user/change_password',
                name: 'ChangePassword',
                component: ChangePassword,
            },
            {
                path: '/admins',
                name: 'AdminIndex',
                component: AdminIndex,
            },
            {
                path: '/admins/add',
                name: 'AdminFormAdd',
                component: AdminForm,
            },
            {
                path: '/admins/edit/:id',
                name: 'AdminFormEdit',
                component: AdminForm,
            },

            // Role Permission Management
            {
                path: '/role-permissions',
                name: 'RolePermissionIndex',
                component: RolePermissionIndex,
                meta: {
                    pageTitle: 'Role & Permission',
                    icon: 'support_agent'
                }
            },
            {
                path: '/role-permissions/edit/:id',
                name: 'RolePermissionEdit',
                component: RolePermissionEdit,
                meta: {
                    pageTitle: 'Role & Permission / Edit',
                    icon: 'support_agent'
                }
            },
            // Content Management
            {
                path: '/contents',
                name: 'ContentIndex',
                component: ContentIndex,
                meta: {
                    pageTitle: 'Content Management',
                    icon: 'support_agent'
                }
            },
            {
                path: '/contents/add',
                name: 'ContentAdd',
                component: ContentAdd,
                meta: {
                    pageTitle: 'Content Management / Add',
                    icon: 'support_agent'
                }
            },
            {
                path: '/contents/edit/:id',
                name: 'ContentAdd',
                component: ContentAdd,
                meta: {
                    pageTitle: 'Content Management / Edit',
                    icon: 'support_agent'
                }
            },
            // Treatment Management
            {
                path: '/treatments',
                name: 'TreatmentIndex',
                component: TreatmentIndex,
                meta: {
                    pageTitle: 'Treatment Management',
                    icon: 'support_agent'
                }
            },
            {
                path: '/treatments/add',
                name: 'TreatmentAdd',
                component: TreatmentAdd,
                meta: {
                    pageTitle: 'Treatment Management / Add',
                    icon: 'support_agent'
                }
            },
            {
                path: '/treatments/edit/:id',
                name: 'TreatmentAdd',
                component: TreatmentAdd,
                meta: {
                    pageTitle: 'Treatment Management / Edit',
                    icon: 'support_agent'
                }
            },
            // Promotion Management
            {
                path: '/promos',
                name: 'PromotionIndex',
                component: PromotionIndex,
                meta: {
                    pageTitle: 'Promotion Management',
                    icon: 'support_agent'
                }
            },
            {
                path: '/promos/add',
                name: 'PromotionAdd',
                component: PromotionAdd,
                meta: {
                    pageTitle: 'Promotion Management / Add',
                    icon: 'support_agent'
                }
            },
            {
                path: '/promos/edit/:id',
                name: 'PromotionAdd',
                component: PromotionAdd,
                meta: {
                    pageTitle: 'Promotion Management / Edit',
                    icon: 'support_agent'
                }
            },
            {
                path: '/otc-landing-page',
                name: 'LandingPageIndex',
                component: LandingPageForm
            },
            {
                path: '/regimens',
                name: 'RegimenPageIndex',
                component: RegimenPageIndex
            },
            {
                path: '/regimens/add',
                name: 'RegimenPageFormAdd',
                component: RegimenPageForm
            },
            {
                path: '/regimens/edit/:id',
                name: 'RegimenPageFormEdit',
                component: RegimenPageForm
            },
            {
                path: '/school-homepage',
                name: 'SchoolHomePage',
                component: SchoolHomepage
            },
            {
                path: '/school-directory',
                name: 'SchoolDirectoryList',
                component: SchoolDirectoryList
            },
            {
                path: '/school-directory/add',
                name: 'SchoolDirectoryForm',
                component: SchoolDirectoryForm
            },
            {
                path: '/school-directory/edit/:id',
                name: 'SchoolDirectoryForm',
                component: SchoolDirectoryForm
            },
            {
                path: '/school-card',
                name: 'SchoolCardList',
                component: SchoolCardList
            },
            {
                path: '/school-card/add',
                name: 'SchoolCardForm',
                component: SchoolCardForm
            },
            {
                path: '/school-card/edit/:id',
                name: 'SchoolCardForm',
                component: SchoolCardForm
            },
            {
                path: '/customer',
                name: 'CustomerIndex',
                component: Undermaintenance,
            },
            {
                path: '/questionnaire',
                name: 'QuestionnaireIndex',
                component: QuestionnaireIndex,
            },
            {
                path: '/questionnaire/add',
                name: 'QuestionnaireFormAdd',
                component: QuestionnaireForm,
            },
            {
                path: '/questionnaire/edit/:id',
                name: 'QuestionnaireFormEdit',
                component: QuestionnaireForm,
            },
            {
                path: '/flows',
                name: 'FlowIndex',
                component: FlowIndex,
            },
            {
                path: '/flows/add',
                name: 'FlowFormAdd',
                component: FlowForm,
            },
            {
                path: '/flows/edit/:id',
                name: 'FlowFormEdit',
                component: FlowForm,
            },
            {
                path: '/inventories',
                name: 'ProductIndex',
                component: ProductIndex,
            },
            {
                path: '/inventories/add',
                name: 'ProductFormAdd',
                component: ProductForm,
            },
            {
                path: '/inventories/edit/:id',
                name: 'ProductFormEdit',
                component: ProductForm,
            },
            {
                path: '/inventories/rating/:id',
                name: 'ProductRatingIndex',
                component: ProductRatingIndex,
            },
            {
                path: '/master-landing-page',
                name: 'MasterLanding',
                component: MasterLanding,
            },
            {
                path: '/advisor-page',
                name: 'AdvisorPage',
                component: AdvisorPage,
            },
            {
                path: '/about-us',
                name: 'AboutUs',
                component: AboutUs,
            },
            {
                path: '/promotion-page',
                name: 'PromotionPage',
                component: PromotionPage,
            },
            {
                path: '/article-page',
                name: 'BlogPage',
                component: BlogPage,
            },
            {
                path: '/faq-page',
                name: 'FaqPage',
                component: FaqPage,
            },
            {
                path: '/article-library',
                name: 'BlogIndex',
                component: BlogIndex,
            },
            {
                path: '/article-library/add',
                name: 'BlogFormAdd',
                component: BlogForm,
            },
            {
                path: '/article-library/edit/:id',
                name: 'BlogFormEdit',
                component: BlogForm,
            },
            {
                path: '/article-tag',
                name: 'TagIndex',
                component: TagIndex,
            },
            {
                path: '/article-tag/add',
                name: 'TagFormAdd',
                component: TagForm,
            },
            {
                path: '/article-tag/edit/:id',
                name: 'TagFormEdit',
                component: TagForm,
            },
            {
                path: '/article-category',
                name: 'CategoryIndex',
                component: CategoryIndex,
            },
            {
                path: '/article-category/add',
                name: 'CategoryFormAdd',
                component: CategoryForm,
            },
            {
                path: '/article-category/edit/:id',
                name: 'CategoryFormEdit',
                component: CategoryForm,
            },
            {
                path: '/condition-library',
                name: 'ConditionIndex',
                component: ConditionIndex,
            },
            {
                path: '/condition-library/add',
                name: 'ConditionFormAdd',
                component: ConditionForm,
            },
            {
                path: '/condition-library/edit/:id',
                name: 'ConditionFormEdit',
                component: ConditionForm,
            },
            {
                path: '/polyclinic-library',
                name: 'PolyclinicIndex',
                component: PolyclinicIndex,
            },
            {
                path: '/polyclinic-library/add',
                name: 'PolyclinicFormAdd',
                component: PolyclinicForm,
            },
            {
                path: '/polyclinic-library/edit/:id',
                name: 'PolyclinicFormEdit',
                component: PolyclinicForm,
            },
            {
                path: '/medical-advisor-library',
                name: 'DoctorIndex',
                component: DoctorIndex,
            },
            {
                path: '/medical-advisor-library/add',
                name: 'DoctorFormAdd',
                component: DoctorForm,
            },
            {
                path: '/medical-advisor-library/edit/:id',
                name: 'DoctorFormEdit',
                component: DoctorForm,
            },
            {
                path: '/medical-advisor-expertise',
                name: 'SpecialistDoctorIndex',
                component: SpecialistDoctorIndex,
            },
            {
                path: '/medical-advisor-expertise/add',
                name: 'SpecialistDoctorFormAdd',
                component: SpecialistDoctorForm,
            },
            {
                path: '/medical-advisor-expertise/edit/:id',
                name: 'SpecialistDoctorFormEdit',
                component: SpecialistDoctorForm,
            },
            {
                path: '/faq-library',
                name: 'FaqIndex',
                component: FaqIndex,
            },
            {
                path: '/faq-library/add',
                name: 'FaqFormAdd',
                component: FaqForm,
            },
            {
                path: '/faq-library/edit/:id',
                name: 'FaqFormEdit',
                component: FaqForm,
            },
            {
                path: '/faq-tag',
                name: 'TagFaqIndex',
                component: TagFaqIndex,
            },
            {
                path: '/faq-tag/add',
                name: 'TagFaqFormAdd',
                component: TagFaqForm,
            },
            {
                path: '/faq-tag/edit/:id',
                name: 'TagFaqFormEdit',
                component: TagFaqForm,
            },
            {
                path: '/faq-category',
                name: 'CategoryFaqIndex',
                component: CategoryFaqIndex,
            },
            {
                path: '/faq-category/add',
                name: 'CategoryFaqFormAdd',
                component: CategoryFaqForm,
            },
            {
                path: '/faq-category/edit/:id',
                name: 'CategoryFaqFormEdit',
                component: CategoryFaqForm,
            },
            {
                path: '/company-settings',
                name: 'CompanySettingIndex',
                component: CompanySettingIndex,
            },
            {
                path: '/special-product-page',
                name: 'SpecialProductPageIndex',
                component: SpecialProductPageIndex,
            },
            {
                path: '/special-product-page/add',
                name: 'SpecialProductPageFormAdd',
                component: SpecialProductPageForm,
            },
            {
                path: '/special-product-page/edit/:id',
                name: 'SpecialProductPageFormEdit',
                component: SpecialProductPageForm,
            },
            {
                path: '/interim-page',
                name: 'InterimPageIndex',
                component: InterimPageIndex,
            },
            {
                path: '/interim-page/add',
                name: 'InterimPageFormAdd',
                component: InterimPageForm,
            },
            {
                path: '/interim-page/edit/:id',
                name: 'InterimPageFormFormEdit',
                component: InterimPageForm,
            },
            {
                path: '/testimony-library',
                name: 'TestimonyLibraryPageIndex',
                component: TestimonyLibraryIndex,
            },
            {
                path: '/testimony-library/add',
                name: 'TestimonyLibraryPageFormAdd',
                component: TestimonyLibraryForm,
            },
            {
                path: '/testimony-library/edit/:id',
                name: 'TestimonyLibraryPageFormEdit',
                component: TestimonyLibraryForm,
            },
            {
                path: '/testimony-video-library',
                name: 'TestimonyVideoLibraryPageIndex',
                component: TestimonyVideoLibraryIndex,
            },
            {
                path: '/testimony-video-library/add',
                name: 'TestimonyVideoLibraryPageFormAdd',
                component: TestimonyVideoLibraryForm,
            },
            {
                path: '/testimony-video-library/edit/:id',
                name: 'TestimonyVideoLibraryPageFormEdit',
                component: TestimonyVideoLibraryForm,
            },
            {
                path: '/testimony-page',
                name: 'TestimonyPage',
                component: TestimonyPage,
            },
            {
                path: '/mobile-home',
                name: 'MobileHome',
                component: MobileHome,
            },
            {
                path: '/login-announcement-board',
                name: 'PopupForm',
                component: PopupForm
            },
            {
                path: '/403',
                name: 'Forbidden',
                component: Forbidden
            },
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '*',
                name: 'NotFound',
                component: NotFound
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.accessToken && !localStorage.getItem('accessToken') ) {
        next({name: 'Login'})
    } else if ( to.path == '/' ) {
        let data = JSON.parse(localStorage.getItem('account'));
        const filteredMenus = data.admin.role.menus.filter(menu => !HIDDEN_MENUS.includes(menu.link));
        if (filteredMenus.length > 0) {
            next({ path: filteredMenus[0].link });
        } else {
            next({ name: 'Login' });
        }
    }else {
        next()
    }
})

export default router
