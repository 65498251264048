<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Section</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger">
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="Polyclinic" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="polyclinic">Choose Polyclinic <span class="alert-required">*</span></label>
                                <multiselect 
                                    v-model="model.selectedPolyclinic"
                                    label="namePage"
                                    name="polyclinic"
                                    track-by="id" 
                                    placeholder="Search Polyclinic"
                                    :options="polyclinics"
                                    :multiple="false"
                                    :searchable="true"
                                    :max-height="600"
                                    :disabled="isEdit"
                                >
                                    <span slot="noResult">Oops! There is no polyclinic.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Section Name" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="setionName">Section Name <span class="alert-required">*</span></label>
                                <input type="text" name="setionName" v-model="model.name" class="form-control" placeholder="Enter Section Name">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <p class="col-11">Condition Detail <span class="alert-required">*</span></p>
                    </div>
                    <div class="row align-items-center mb-1" v-for="(data, index) in model.conditions" :key="index">
                        <div class="col-11">
                            <ValidationProvider name="Condition Detail" v-slot="{ errors }" rules="required">
                                <div :class="{ 'validation-warning': errors[0] }">
                                    <multiselect
                                        v-model="data.conditionObj"
                                        label="namePage"
                                        track-by="id" 
                                        placeholder="Search Condition"
                                        :options="conditions"
                                        :multiple="false"
                                        :searchable="true"
                                        :max-height="600"
                                    >
                                        <span slot="noResult">Oops! There is no condition.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-1" v-if="model.conditions.length > 1">
                            <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="deleteCondition(index)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary mt-1" @click="addCondition">
                        <i class="fa fa-plus"></i> Add Condition
                    </button>

                    <div class="text-left mt-4">
                        <router-link to="/interim-page" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{ isEdit ? 'Update' : 'Create' }}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            model: {
                name: '',
                selectedPolyclinic: null,
                conditions: [{
                    conditionObj: null,
                }],
            },
            polyclinics: [],
            conditions: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            isEdit: Boolean(this.$route.params.id),
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getSelectedPolyclinics() {
            this.axios.get('/dc-interim-page?sortBy=updatedAt.DESC')
                .then((resp) => {
                    const selectedIds = resp.data.data.map(item => item.id);
                    this.polyclinics = this.polyclinics.filter(polyclinic => !selectedIds.includes(polyclinic.id));
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        getDetail() {
            this.axios.get('/dc-interim-page/' + this.$route.params.id)
                .then(resp => {
                    const data = resp.data.data;
                    this.model = {
                        name: data.name,
                        selectedPolyclinic: this.polyclinics.find(polyclinic => polyclinic.id === Number(this.$route.params.id)),
                        conditions: data.conditionIds.map(id => ({
                            conditionObj: this.conditions.find(c => c.id === id),
                        })),
                    };
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    });
                    this.isLoading = false;
                });
        },
        getPolyclinics() {
            return new Promise((resolve, reject) => {
                this.axios.get('/misc/poly-clinic')
                    .then(resp => {
                        this.polyclinics = resp.data.data;
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getConditions() {
            return new Promise((resolve, reject) => {
                this.axios.get('/dc-condition-page/list-all')
                .then(resp => {
                    this.conditions = resp.data.data;
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
            });
        },
        addCondition() {
            this.model.conditions.push({
                conditionObj: null,
            });
        },
        deleteCondition(index) {
            this.model.conditions = [...this.model.conditions.slice(0, index), ...this.model.conditions.slice(index + 1)];
        },
        async save() {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                this.isLoading = true;
                this.axios.put(`/dc-interim-page/${this.$route.params.id || this.model.selectedPolyclinic.id}`, {
                    name: this.model.name,
                    conditionIds: this.model.conditions.map(condition => condition.conditionObj.id),
                })
                    .then(resp => {
                        this.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        });
                        this.success = resp.data.success;
                        this.$router.push('/interim-page');
                    })
                    .catch(err => {
                        this.errors = err.response.data.message;
                        this.message_error = err.response.data.message;
                        this.success = err.response.data.success;
                        this.isLoading = false;
                    });
            }
        }
        
    },
    mounted() {
        Promise.all([this.getPolyclinics(), this.getConditions()])
            .then(() => {
                if (this.isEdit) {
                    this.getDetail();
                } else {
                    this.getSelectedPolyclinics();
                }
            })
            .catch(e => {
                console.log(e);
            })
    }
}
</script>

<style scoped>
.checkbox {
    width: 1.25rem;
    height: 1.25rem;
}
</style>