<template>
    <span>
        <h3 class="font-600 mb-2">Company Settings</h3>
        <b-tabs
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
        >
            <!-- navbar tab -->
            <b-tab active>
                <!-- title -->
                <template #title>
                    <span class="font-weight-bold">Navbar</span>
                </template>
                <CompanySettingNavbar />
            </b-tab>
            <!--/ change navbar tab -->

            <!-- change footer tab -->
            <b-tab>
                <!-- title -->
                <template #title>
                    <span class="font-weight-bold">Term and Condition</span>
                </template>
                <TermOfUse />
            </b-tab>
            <!--/ change footer tab -->

            <!-- change footer tab -->
            <b-tab>
                <!-- title -->
                <template #title>
                    <span class="font-weight-bold">Privacy Policy</span>
                </template>
                <PrivacyPolicy />
            </b-tab>
            <!--/ change footer tab -->

            <!-- change footer tab -->
            <b-tab>
                <!-- title -->
                <template #title>
                    <span class="font-weight-bold">Footer</span>
                </template>
                <CompanySettingFooter />
            </b-tab>
            <!--/ change footer tab -->

        </b-tabs>
    </span>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CompanySettingNavbar from './CompanySettingNavbar.vue'
import TermOfUse from './TermOfUse.vue'
import PrivacyPolicy from './PrivacyPolicy.vue'
import CompanySettingFooter from './CompanySettingFooter.vue'

export default {
  components: {
    BTabs,
    BTab,
    CompanySettingNavbar,
    TermOfUse,
    PrivacyPolicy,
    CompanySettingFooter
  },
  data() {
    return {
      options: {},
    }
  },
}
</script>
