<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Article Page</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="title" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Title <span class="alert-required">*</span></label>
                                <input type="text" v-model="model.title" class="form-control" placeholder="Enter Pre Title">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="bannerColor" v-slot="{ errors }" rules="" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Background Header Color</label>
                            <sketch-picker v-model="model.bannerColor" @input="updateColor"></sketch-picker>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Alt Image</label>
                                <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="image" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Image <span class="alert-required">*</span></label>
                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                                <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Alt Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider class="col">
                        <div class="form-group" v-if="model.thumbnailimage">
                            <label>Preview Image</label>
                            <img :src="model.thumbnailimage" class="responsive img-thumbnail" />
                        </div>
                        <div class="form-group" v-else>
                            <label>Preview Image</label>
                            <img :src="model.image" class="responsive img-thumbnail" />
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="articleId" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label for="descriptionInput">Articles <span class="alert-required">*</span></label>
                            <multiselect 
                                v-model="model.articleId"
                                label="title"
                                name="articles"
                                track-by="id" 
                                placeholder="Search Articles"
                                :options="articles" 
                                :multiple="true" 
                                :searchable="true" 
                                :clear-on-select="false" 
                                :close-on-select="false" 
                                :max-height="600">
                                <span slot="noResult">Oops! There is no article.</span>
                            </multiselect>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="buttonConsult1" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Button Mulai Konsultasi 1 <span class="alert-required">*</span></label>
                                <select name="buttonConsult1" id="" v-model="model.buttonConsult1" class="form-control">
                                    <option value="">Select Button</option>
                                    <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                        {{data.title}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <hr>

                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            collapseType: 'margin',
            model: {
                title: '',
                bannerColor: '',
                image: '',
                thumbnailimage: '',
                imageAlt: '',
                buttonConsult1: '',
                articleId: [],
            },
            articles: [],
            buttons: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-article-page')
                .then(resp => {
                    _.model = resp.data.data
                    _.model.articleId = _.model.articles
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        getArticles() {
           let _ = this
            _.axios.get('/dc-articles/list-all')
            .then(resp=>{
                _.articles = resp.data.data
            }) 
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("title", _.model.title);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("image", _.model.image);
                formData.append("articleId", JSON.stringify(_.model.articleId.map(p => p.id)));
                formData.append("buttonConsult1", _.model.buttonConsult1);
                _.axios.put('/dc-article-page', formData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.success = resp.data.success
                    _.$router.push('/article-page')
                    setTimeout(function() {
                        location.reload();
                    }, 3000);
                })
                .catch(err => {
                    _.errors = err.response.data.message
                    _.message_error = err.response.data.message
                    _.success = err.response.data.success
                    _.isLoading = false
                })
            }
        },
    },
    mounted() {
        let _ = this
        _.get()
        _.getArticles()
        _.getButtons()
    }
}
</script>