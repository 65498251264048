<template>
    <form @submit.prevent="submit">
      <div class="form-group">
        <label for="descriptionInput" >Landing Description</label>
        <textarea class="form-control" v-model.lazy="model.description" id="descriptionInput" placeholder="Enter description">
          
        </textarea>
        <small class="text-danger" v-show="errors.description">This field is required</small>
      </div>
      
      <div class="form-group">
        <label for="" >Landing List Information</label>
      </div>

      <div class="form-row" v-for="(opt, key) in listOptions" :key="key">
        <div class="form-group col-md-3">
          <!-- <input type="file" @change="handleFileUpload( $event, key )"/> -->
          <div style="text-align: -webkit-center;">
              <div v-if="listOptions[key].icon != null">
                  <img :src="listOptions[key].icon" class="img-fluid" alt=""><br>
                  <button class="btn btn-danger mt-1 mb-1" @click="listOptions[key].icon = null">Delete Image</button>
              </div>
              <div v-else 
                  style="
                      text-align: center; 
                      border-radius: 10px; 
                      background: #E5E7E9;
                      padding: 30px;
                      cursor: pointer;
                  ">
                  <label :for="'file-upload'+key" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                      <i class="fa fa-upload" aria-hidden="true"></i> 
                      <p style="font-size: 14px">Upload Image</p>
                  </label>
                  <input :id="'file-upload'+key" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event, key )">
              </div>                                                        
          </div>
          <small class="text-danger" v-show="image_size[key]">The image file is too large, allowed maximum size is {{max_file_size}} MB<br/></small>
          <small class="text-danger" v-show="errors.lists[key].icon">This field is required</small>
        </div>

        <div class="form-group col-md-8">
          <div class="form-row">
            <div class="form-group col-md-12">
              <input type="text" v-model.lazy="listOptions[key].title" placeholder="Enter title" class="form-control">
              <small class="text-danger" v-show="errors.lists[key].title">This field is required</small>
            </div>
            <div class="form-group col-md-12">
              <textarea v-model.lazy="listOptions[key].description" class="form-control" placeholder="Enter description">
              
              </textarea>
              <small class="text-danger" v-show="errors.lists[key].description">This field is required</small>
            </div>
          </div>
        </div>

        <div class="form-group col-md-1">
          <button class="btn btn-danger" @click="rem(key)" type="button"><i class="fa fa-trash"></i></button>
        </div>
      </div>
      <button class="btn btn-info mb-2" @click="add" type="button"><i class="fa fa-plus"></i></button>

      <hr/>
    </form>
</template>

<script>
export default {
    name: 'PageTypeLanding',
    data() {
      return {
        file: '',
        listOptions:[
          {
            id: this.randomString(),
            icon: null,
            title: '',
            description: ''
          }
        ],
        model: {
          description: ""
        },
        errors: {
          description: false,
          lists: [
            {
              title: false,
              description: false,
              icon: false
            }
          ]
        },
        max_file_size: 2,
        image_size: []
      }
    },
    beforeMount() {
      if ( this.$route.params.id ) {
        let editContent = this.$store.getters.currentEditContent
        this.model = editContent
        this.listOptions = editContent.lists

        this.listOptions.forEach(() => {
          this.errors.lists.push({
            title: false,
            description: false,
            icon: false
          })
        })
      }
    },
    methods: {
      submit() {

      },
      handleFileUpload(event, key){
        this.file = event.target.files[0]
        if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
            event.preventDefault();
            this.image_size[key] = true
            this.$forceUpdate()
            return;
        } else {
          this.image_size[key] = false
        }

        let formData = new FormData()
        formData.append('folder', 'contents')
        formData.append('file', this.file)

        this.axios.post('/misc/upload',
            formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }).then(resp => {
                if ( resp.data.success ) {
                  let file = resp.data.data.file
                  this.listOptions[key].icon = file
                } else {
                  this.listOptions[key].icon = 'https://via.placeholder.com/150?text=dummy+image'
                }
            }).catch(err => {
                console.log(err.response)
            })
      },
      add() {
        this.listOptions.push({
          id: this.randomString(),
          icon: null,
          title: '',
          description: ''
        })
        // this.$store.dispatch('addOptLanding')
        this.errors.lists.push({
          title: false,
          description: false,
          icon: false
        })
      },
      rem(key) {
        this.listOptions = this.listOptions.filter( (opt, i) => key != i )
      },
      randomString() {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 8; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
      }
    },
    watch: {
      'model.description': function(newValue) {
        this.$store.dispatch('contentLanding', { pageOption: { description: newValue } })
      },
      'listOptions': {
        handler: function(newValue) {
            this.$store.dispatch('contentLanding', {
             pageOption: {
              lists: newValue
             }
          })
        },
        deep: true
      }
    },
    mounted() {
      let _ = this
      _.$root.$on('errorListener', (data) => {
        _.errors = data
        _.$forceUpdate();
      })
      _.max_file_size = process.env.VUE_APP_MAX_FILE_SIZE
    }
}
</script>