<template>
    <form @submit.prevent="submit">
      <div class="form-group">
        <label for="pretitleInput">Essay Pre Heading</label>
        <input type="text" v-model="model.preTitle" name="pretitle" class="form-control" placeholder="Enter pre heading">
        <small class="text-danger" v-show="errors.preTitle">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Essay Title</label>
        <input type="text" v-model="model.title" class="form-control" placeholder="Enter title">
        <small class="text-danger" v-show="errors.title">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Essay Description</label>
        <textarea class="form-control" v-model="model.description" id="descriptionInput" placeholder="Enter description">
        </textarea>
        <small class="text-danger" v-show="errors.description">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Essay Information</label>
      </div>

      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="pretitleInput">Is Required?</label>
          <div class="form-check">
            <input class="form-check-input" v-model="model.essay.is_required" type="checkbox" name="exampleRadios" id="exampleRadios1" @change="check($event)">
            <label class="form-check-label" for="exampleRadios1">
              Yes
            </label>
          </div>
        </div>

        <div class="form-group col-md-10">
          <label for="pretitleInput">Placeholder</label>
          <input type="text" v-model="model.essay.placeholder" name="placeholder" placeholder="Essay placeholder" class="form-control">
          <small class="text-danger" v-show="errors.essay.placeholder">This field is required</small>
        </div>
      </div>

      <hr/>
    </form>
</template>

<script>

export default {
    name: 'PageTypeEssay',
    data() {
      return {
        model: {
          preTitle: '',
          title: '',
          description: '',
          essay:{
            is_required: '',
            placeholder: ''
          }
        },
        errors: {
          preTitle: false,
          title: false,
          essay: {
            description: false
          },
          placeholder:false
        },
      }
    },
    beforeMount() {
      if ( this.$route.params.id ) {
        let editContent = this.$store.getters.currentEditContent
        this.model = editContent
        this.model.essay.is_required = !this.model.essay.optional
      }
    },
    watch: {
      'model.preTitle': function(newValue) {
        this.$store.dispatch('contentEssay', { pageOption: { preTitle: newValue } })
      },
      'model.title': function(newValue) {
        this.$store.dispatch('contentEssay', { pageOption: { title: newValue } })
      },
      'model.description': function(newValue) {
        this.$store.dispatch('contentEssay', { pageOption: { description: newValue } })
      },
      // 'model.essay.is_required': function(newValue) {
      //   console.log('is_optional: ' + !(!!newValue))
      //   this.$store.dispatch('contentEssay', { pageOption: { essay: { optional: !(!!newValue) } } })
      // },
      'model.essay.placeholder': function(newValue) {
        this.$store.dispatch('contentEssay', { pageOption: { essay: { placeholder: newValue } } })
      },
    },
    mounted() {
      let _ = this
      _.$root.$on('errorListener', (data) => {
        _.errors = data
        _.$forceUpdate();
      })

      if ( this.model.essay.is_required ) {
        this.$store.dispatch('contentEssay', { pageOption: { essay: { optional: false } } })
      }
    },
    methods: {  
      check(e) {
        this.$store.dispatch('contentEssay', { pageOption: { essay: { optional: !( e.target.checked ) } } })
      }
    }
}
</script>