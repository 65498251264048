<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/vuexy-login-bg.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form">
                <router-link to="/login"><i class="fa fa-arrow-left"></i> Back to Login</router-link>
                
                <div class="form-logo">
                    <img src='/assets/img/logo-diri-x4.png' class="img-fluid" alt="">
                </div>

                <p class="text-blue font-500 f-18 mb-0">Forgot Password?</p>
                <p class="mb-2">Enter your email and check your email, or contact the call center</p>
                
                <form @submit.prevent="submit()">
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <input type="text" id="name" class="form-control" required v-model="model.email" placeholder="Input Email">
                                <label class="form-control-placeholder" for="name">EMAIL</label>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </div>
                    </ValidationProvider>

                    <button type="submit" class="btn btn-primary waves-effect waves-light w-100" v-if="isLoading == false">{{ state == 1 ? 'Resend Email' : 'Submit' }}</button>
                    <button type="button" class="btn btn-primary waves-effect waves-light w-100" :disabled="isLoading" v-else>Submitting <i class="fa fa-spinner fa-spin"></i></button>
                    
                    <div class="text-center mt-1" v-if="!isValid">
                        <div v-for="(v, k) in errors" :key="k">
                            <p v-for="error in v" :key="error" class="mb-0 val-error" v-html="error"></p>
                        </div>
                    </div>
                </form>                
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<script>
export default {
    data() {
        return {
            model: {
                email: ''
            },
            state: 0,
            isValid: true,
            isLoading: false,
            errors: [],
        }
    },
	methods: {
		async submit() {
            let _ = this
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let data = {
                    email: _.model.email,
                    platform: "admin"
                }
                _.axios.post('/auth/request-reset-password', data)
                    .then(()=>{
                        _.state = 1
                        _.isLoading = false
                    })
                    .catch(err=>{
                        if (err.response.status == 404) {
                            _.errors = { 'account': ['Email not registered'] }
                        } else {
                            _.errors = err.response.data.message
                        }
                        
                        _.state = 0
                        _.isValid = false
                        _.isLoading = false
                    })
            }
		}
	}
}
</script>