<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Testimony Video Library</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <div class="text-danger">
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="Customer Name" v-slot="{ errors }" rules="required|alpha_spaces|max:30" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="name">Customer Name <span class="alert-required">*</span></label>
                                <input type="text" id="name" v-model="model.name" class="form-control" placeholder="Enter Name">
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="form-group col-3">
                            <label for="age">Age</label>
                            <input type="number" id="age" @keypress="numberOnly" v-model.number="model.age" class="form-control" placeholder="Enter Age">
                        </div>
                        <ValidationProvider name="Sex" v-slot="{ errors }" rules="required" class="col-3">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="sex">Sex <span class="alert-required">*</span></label>
                                <select class="form-control" v-model="model.sex">
                                    <option value="" disabled selected hidden>Select sex</option>
                                    <option value="MALE">Laki-laki</option>
                                    <option value="FEMALE">Perempuan</option>
                                </select>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Condition Detail" v-slot="{ errors }" rules="required" class="col-6">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="condition">Condition Detail <span class="alert-required">*</span></label>
                                <multiselect
                                    v-model="model.selectedCondition"
                                    label="namePage"
                                    id="condition"
                                    track-by="id" 
                                    placeholder="Search Condition"
                                    :options="conditions"
                                    :multiple="false"
                                    :searchable="true"
                                    :max-height="600"
                                >
                                    <span slot="noResult">Oops! There is no condition.</span>
                                </multiselect>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Submission Date" v-slot="{ errors }" rules="required" class="col-3">
                            <div class="form-group">
                                <label for="date">Submission Date <span class="alert-required">*</span></label>
                                <date-picker
                                    v-model="model.date"
                                    type="date"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Choose date"
                                    class="w-100"
                                    id="date"
                                    :disabled-date="disableFutureDate">
                                </date-picker>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider
                            name="Video Thumbnail"
                            v-slot="{ errors, validate }"
                            :rules="`${isVideoThumbnailRequired ? 'required|max-dimensions:360' : ''}`"
                            class="col"
                        >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="videoThumbnail">Video Thumbnail <span class="alert-required">*</span></label>
                                <div class="form-control d-flex flex-column align-items-start" style="height: auto;">
                                    <span class="btn btn-outline-primary btn-sm" @click="$refs.videoThumbnail.click()">Select Photo</span>
                                    <img
                                        v-if="model.videoThumbnailUrl"
                                        :src="model.videoThumbnailUrl"
                                        width="270"
                                        height="480"
                                        class="mt-1 border"
                                        style="object-fit: cover; object-position: center;"
                                    />
                                    <small class="block mt-1">Aspect ratio: 9 x 16</small>
                                    <small class="block" style="margin-top: 4px;">Recommended dimensions: 360 x 640 pixels</small>
                                    <small class="block" style="margin-top: 4px;">Max width: 360 pixels</small>
                                </div>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    @change="(e) => {
                                        isVideoThumbnailRequired = true;
                                        validate(e).then((res) => storeVideoThumbnail(res, e));
                                    }"
                                    id="videoThumbnail"
                                    class="hidden"
                                    placeholder="Upload Video Thumbnail"
                                    ref="videoThumbnail"
                                >
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            name="Video"
                            v-slot="{ errors, validate }"
                            :rules="`${isVideoRequired ? 'required|size:20480' : ''}`"
                            class="col"
                        >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="video">Video <span class="alert-required">*</span></label>
                                <div class="form-control d-flex flex-column align-items-start" style="height: auto;">
                                    <span class="btn btn-outline-primary btn-sm" @click="$refs.video.click()">Select Video</span>
                                    <video controls width="270" v-if="model.videoUrl" class="mt-1 border" :key="model.videoUrl">
                                        <source :src="model.videoUrl" type="video/mp4">
                                    </video>
                                    <small class="block mt-1">Aspect ratio: 9 x 16</small>
                                    <small class="block" style="margin-top: 4px;">Format: mp4</small>
                                    <small class="block" style="margin-top: 4px;">Max file size: 20 MB</small>
                                </div>
                                <input
                                    type="file"
                                    accept=".mp4"
                                    @change="(e) => {
                                        isVideoRequired = true;
                                        validate(e).then((res) => storeVideo(res, e));
                                    }"
                                    id="video"
                                    class="hidden"
                                    placeholder="Upload Video"
                                    ref="video"
                                >
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="text-left mt-4">
                        <router-link to="/testimony-video-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{ isEdit ? 'Update' : 'Create' }}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            model: {
                name: '',
                age: null,
                sex: "",
                date: null,
                video: null,
                videoUrl: '',
                videoThumbnail: null,
                videoThumbnailUrl: '',
                selectedCondition: null,
            },
            conditions: [],
            isLoading: false,
            errors: null,
            messageError: null,
            isEdit: Boolean(this.$route.params.id),
            isVideoRequired: true,
            isVideoThumbnailRequired: true,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getDetail() {
            this.isLoading = true;
            this.axios.get(`/testimony-library/${this.$route.params.id}`)
                .then(resp => {
                    const { data } = resp.data;
                    this.model = {
                        name: data.name,
                        age: data.age,
                        sex: data.sex,
                        date: data.date,
                        videoUrl: data.video,
                        videoThumbnailUrl: data.videoThumbnail,
                        selectedCondition: this.conditions.find(c => c.id === data.conditionId),
                    };
                    this.isVideoThumbnailRequired = false;
                    this.isVideoRequired = false;
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    });
                    this.$router.push('/testimony-video-library');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getConditions() {
            return new Promise((resolve) => {
                this.axios.get('/dc-condition-page/list-all')
                    .then(resp => {
                        this.conditions = resp.data.data;
                        resolve(true);
                    });
            });
        },
        numberOnly(event) {
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        },
        disableFutureDate(date) {
		    return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
		},
        storeVideoThumbnail({ valid }, event){
            if (valid) {
                this.$set(this.model, 'videoThumbnail', event.target.files[0]);
                this.$set(this.model, 'videoThumbnailUrl', window.URL.createObjectURL(event.target.files[0]));
            }
        },
        storeVideo({ valid }, event){
            if (valid) {
                this.$set(this.model, 'video', event.target.files[0]);
                this.$set(this.model, 'videoUrl', window.URL.createObjectURL(event.target.files[0]));
            }
        },
        async save() {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                const formDataVideoThumbnail = new FormData();
                formDataVideoThumbnail.append('folder', 'contents');
                formDataVideoThumbnail.append('file', this.model.videoThumbnail);

                const formDataVideo = new FormData();
                formDataVideo.append('folder', 'contents/video');
                formDataVideo.append('file', this.model.video);

                const headers = {
                    'Content-Type': 'multipart/form-data',
                };

                try {
                    this.isLoading = true;
                    let [responseVideoThumbnail, responseVideo] = [null, null];
                    if (this.isEdit) {
                        responseVideoThumbnail = this.model.videoThumbnail
                            ? await this.axios.post('/misc/upload', formDataVideoThumbnail, { headers })
                            : null;
                        responseVideo = this.model.video
                            ? await this.axios.post('/misc/upload', formDataVideo, { headers })
                            : null;
                    } else {
                        [responseVideoThumbnail, responseVideo] = await Promise.all([
                            this.axios.post('/misc/upload', formDataVideoThumbnail, { headers }),
                            this.axios.post('/misc/upload', formDataVideo, { headers }),
                        ]);
                    }

                    const payload = {
                        name: this.model.name,
                        age: this.model.age === null || this.model.age === '' ? null : this.model.age,
                        sex: this.model.sex,
                        conditionId: this.model.selectedCondition.id,
                        date: this.model.date,
                        video: responseVideo ? responseVideo.data.data.file : this.model.videoUrl,
                        videoThumbnail: responseVideoThumbnail ? responseVideoThumbnail.data.data.file : this.model.videoThumbnailUrl,
                        type: 'video',
                    };

                    if (this.isEdit) {
                        this.axios.put(`/testimony-library/${this.$route.params.id}`, payload)
                            .then(resp => {
                                this.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                });
                                this.$router.push('/testimony-video-library');
                            })
                            .catch(err => {
                                this.errors = err.response.data.message;
                                this.messageError = err.response.data.message;
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    } else {
                        this.axios.post('/testimony-library', payload)
                            .then(resp => {
                                this.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                });
                                this.$router.push('/testimony-video-library');
                            })
                            .catch(err => {
                                this.errors = err.response.data.message;
                                this.messageError = err.response.data.message;
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    }
                } catch (err) {
                    this.isLoading = false;
                    this.errors = true;
                    this.messageError = 'Failed to upload photo';
                }
            }
        }
        
    },
    async mounted() {
        await this.getConditions();
        if (this.isEdit) {
            this.getDetail();
        }
    }
}
</script>
