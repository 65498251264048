<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">FAQ Page</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <ul class="mb-0">
                    <div v-for="(v, k) in errors" :key="k">
                        <li class="text-danger">{{v.message}}</li>
                    </div>
                </ul>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="categoryFaqId" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="descriptionInput">Category Faq <span class="alert-required">*</span></label>
                                <multiselect 
                                    v-model="model.categoryFaqId"
                                    label="name"
                                    name="categories"
                                    track-by="id" 
                                    placeholder="Search Category FAQ"
                                    :options="categories" 
                                    :multiple="true" 
                                    :searchable="true" 
                                    :clear-on-select="false" 
                                    :close-on-select="false" 
                                    :max-height="600">
                                    <span slot="noResult">Oops! There is no category faq.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="buttonConsult1" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label>Button Mulai Konsultasi 1 <span class="alert-required">*</span></label>
                            <select name="buttonConsult1" id="" v-model="model.buttonConsult1" class="form-control">
                                <option value="">Select Button</option>
                                <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                    {{data.title}}
                                </option>
                            </select>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                categoryFaqId: [],
                buttonConsult1: '',
            },
            buttons: [],
            isLoading: false,
            errors: null,
            categories: [],
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getCategory() {
            let _ = this
            _.axios.get('/dc-category-faq/list-all')
            .then(resp=>{
                _.categories = resp.data.data
            })
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        get() {
            let _ = this
            _.axios.get('/dc-faq-page/')
                .then(resp => {
                    _.model = resp.data.data
                    _.model.categoryFaqId = _.model.categories
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let model = {
                    buttonConsult1: _.model.buttonConsult1,
					categoryFaqId: _.model.categoryFaqId.map(p => p.id),
				}
                _.axios.put('/dc-faq-page/', model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.isLoading = false
                        _.errors = null
                        setTimeout(function() {
                            location.reload();
                        }, 2000);
                    })
                    .catch(err => {
                        _.errors = err.response.data.data
                        _.isLoading = false
                    })     
            }
        },
    },
    mounted() {
        let _ = this
        _.getCategory()
        _.getButtons()
        _.get()
    }
}
</script>