<template>
	<div class="card">
        <div class="card-body">
			<h3 class="font-600 mb-2">{{id ? 'Edit' : 'Add'}} Promo</h3>
        	<ValidationObserver class="card-content" ref="observer">
	            <form @submit.prevent="submit">
					<div class="row">
						<div class="col-4">
							<ValidationProvider name="promotion type" v-slot="{ errors }">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="promoType" class="required">Promotion Type</label>
									<select class="form-control" name="type" v-model="model.type" id="promoType" :disabled="id">
										<option :value="null">Banner</option>
										<option value="purchase">Purchase</option>
									</select>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
						</div>
						<div class="col-4" v-if="model.type === 'purchase'">
							<ValidationProvider name="Transaction Type" :rules="{ required: model.type === 'purchase' }" v-slot="{ errors }">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label class="required">Transaction Type</label>
									<multiselect
										v-model="selectedOrderTypes"
										label="label"
										track-by="value"
										placeholder="Select Transaction Type"
										:multiple="true"
										:options="orderTypes"
										@input="handleInputOrderTypes"
										:clear-on-select="true"
										:close-on-select="false"
									>
										<span slot="noResult">Oops! Data not found.</span>
									</multiselect>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
						</div>
						<div class="col-4">
							<ValidationProvider name="Promotion Visibility" v-slot="{ errors }">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="inputType" class="required">Promotion Visibility</label>
									<select class="form-control" v-model="model.visibilityType" :disabled="model.emails.length > 0">
										<option value="ATL">ATL</option>
										<option value="BTL">BTL</option>
									</select>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
						</div>
					</div>
					<p class="text-dark text-bold-600 mt-2">Promo Display</p>
					<div class="row">
						<div class="col-6">
							<ValidationProvider name="promotion title" v-slot="{ errors }" rules="required" >
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="" class="required">Promotion Title</label>
									<input type="text" v-model.lazy="model.name" class="form-control" id="titleInput" placeholder="Enter title">
									<small class="text-muted" v-if="errors[0] && !uniqueError.name">{{ errors[0] }}</small>
									<small class="text-danger" v-else-if="uniqueError.name">Promo title has been used</small>
								</div>
							</ValidationProvider>
						</div>
						<div class="col-6">
							<ValidationProvider name="code" v-slot="{ errors }" rules="required|alpha_num" v-if="model.type !== null">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="code" class="required">Code</label>
									<input type="text" v-model.lazy="model.code" name="code" class="form-control" id="code" placeholder="Enter code" :disabled="id">
									<small class="text-muted" v-if="errors[0] && !uniqueError.code">{{ errors[0] }}</small>
									<small class="text-danger" v-else-if="uniqueError.code">Promo code has been used</small>
								</div>
							</ValidationProvider>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<ValidationProvider name="description" v-slot="{ errors }" rules="required">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="" class="required">Description</label>
									<textarea class="form-control" name="description" v-model="model.description" id="descriptionInput" placeholder="Enter description" cols="30" rows="5"></textarea>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
						</div>
					</div>
					<div class="row">
						<ValidationProvider
							name="Thumbnail"
							v-slot="{ errors, validate }"
							class="col-6"
						>
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="thumbnail">Thumbnail</label>
								<div class="form-control" style="height: auto;">
									<span class="btn btn-outline-primary btn-sm" @click="$refs.thumbnail.click()">Select Image</span>
									<img
										v-if="model.thumbnail"
										:src="model.thumbnail"
										class="w-100 mt-1"
									/>
								</div>
								<input
									type="file"
									accept=".png, .jpg, .jpeg"
									@change="(e) => {
										validate(e).then((res) => storeThumbnail(res, e));
									}"
									id="thumbnail"
									class="hidden"
									placeholder="Upload Thumbnail"
									ref="thumbnail"
								>
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider
							name="Image"
							v-slot="{ errors, validate }"
							class="col-6"
						>
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="image">Image</label>
								<div class="form-control" style="height: auto;">
									<span class="btn btn-outline-primary btn-sm" @click="$refs.image.click()">Select Image</span>
									<img
										v-if="model.image"
										:src="model.image"
										class="w-100 mt-1"
									/>
								</div>
								<input
									type="file"
									accept=".png, .jpg, .jpeg"
									@change="(e) => {
										validate(e).then((res) => storeImage(res, e));
									}"
									id="image"
									class="hidden"
									placeholder="Upload Image"
									ref="image"
								>
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
					</div>

					<template v-if="model.type !== null">
						<p class="text-dark text-bold-600 mt-2">Promo Availability</p>
						<div class="row">
							<div class="col-3">
								<label for="platform" class="required">Platform</label>
								<select class="form-control" v-model="model.platform">
									<option value="0">All</option>
									<option value="1">Website</option>
									<option value="2">App</option>
								</select>
							</div>
							<div class="col-3">
								<ValidationProvider name="start date and time" v-slot="{ errors }" :rules="{ required: isDateRequired }">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="startDate" class="required">Start Date and Time</label>
										<datetime type="datetime" 
											input-class="form-control" 
											v-model="model.startDate" 
											use12-hour
											:min-datetime="minStartDate"
											placeholder="Enter Start Date & Time"
											auto
											class="theme-grey">
										</datetime>
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
							<div class="col-3">
								<ValidationProvider name="end date and time" v-slot="{ errors }" :rules="{ required: isDateRequired }">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="endDate" class="required">End Date and Time</label>
										<datetime type="datetime" 
											input-class="form-control" 
											v-model="model.endDate" 
											use12-hour
											:min-datetime="minEndDate"
											:disabled="!model.startDate"
											placeholder="Enter End Date & Time"
											auto
											class="theme-grey">
										</datetime>
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
							<div class="col-3">
								<ValidationProvider name="number of vouchers" v-slot="{ errors }" rules="required">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="limitInput" class="required">Number of Vouchers</label>
										<input type="number" v-model.lazy="model.limit" name="limit" class="form-control" id="limitInput" placeholder="Enter number of vouchers">
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
						</div>
					</template>

					<template v-if="showProduct">
						<p class="text-dark text-bold-600 mt-2">Product</p>
						<div class="row mb-1">
							<div class="form-group mb-0 col">
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="radio" v-model="productInputType" id="productInputTypeName" value="NAME">
									<label class="form-check-label" for="productInputTypeName">Product Name</label>
								</div>
							</div>
						</div>
						<div class="row">
							<ValidationProvider name="Product Name" v-slot="{ errors }" :rules="{ required: showProduct && productInputType === 'NAME' }" class="col">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<multiselect 
										id="productInput"
										name="product"
										v-model="model.productIds"
										label="title"
										track-by="id" 
										placeholder="Search Product"
										:options="products" 
										:multiple="true" 
										:searchable="true" 
										:clear-on-select="true" 
										:close-on-select="false" 
										:max-height="600"
										:disabled="productInputType !== 'NAME'"
									>
										<span slot="noResult">Oops! There is no products.</span>
									</multiselect>
									<small class="text-muted" v-if="errors[0]">This product field is required</small>
								</div>
							</ValidationProvider>
							<div class="col-4 text-right">
								<button type="button" class="btn btn-success mr-1" @click="selectAllProduct()" :disabled="productInputType !== 'NAME'">Select All</button>
								<button type="button" class="btn btn-danger" @click="deselectAllProduct()" :disabled="productInputType !== 'NAME'">Deselect All</button>
							</div>
						</div>
						<div class="row mb-1">
							<div class="form-group mb-0 col">
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="radio" v-model="productInputType" id="productInputTypeCategory" value="CATEGORY">
									<label class="form-check-label" for="productInputTypeCategory">Product Category</label>
								</div>
							</div>
						</div>
						<div class="row">
							<ValidationProvider name="Category Level 1" v-slot="{ errors }" :rules="{ required: showProduct && productInputType === 'CATEGORY' }" class="col">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label>Category Level 1</label>
									<multiselect
										v-model="selectedProductCategoriesLevelOne"
										label="name"
										track-by="id"
										placeholder="Select Category Level 1"
										:multiple="true"
										:options="productCategories.filter(category => category.parentId === null)"
										@input="handleInputProductCategoriesLevelOne"
										:disabled="productInputType !== 'CATEGORY'"
										:clear-on-select="true"
									>
										<span slot="noResult">Oops! Data not found.</span>
									</multiselect>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<div class="col">
								<div class="form-group">
									<label>Category Level 2</label>
									<multiselect
										v-model="selectedProductCategoriesLevelTwo"
										label="name"
										track-by="id"
										placeholder="Select Category Level 2"
										:multiple="true"
										:options="productCategories.filter(category => model.typeLevelOneIds.includes(category.parentId))"
										@input="handleInputProductCategoriesLevelTwo"
										:disabled="model.typeLevelOneIds.length === 0 || productInputType !== 'CATEGORY'"
										:clear-on-select="true"
									>
										<span slot="noResult">Oops! Data not found.</span>
									</multiselect>
								</div>
							</div>
							<div class="col">
								<div class="form-group">
									<label>Category Level 3</label>
									<multiselect
										v-model="selectedProductCategoriesLevelThree"
										label="name"
										track-by="id"
										placeholder="Select Category Level 3"
										:multiple="true"
										:options="productCategories.filter(category => model.typeLevelTwoIds.includes(category.parentId))"
										@input="handleInputProductCategoriesLevelThree"
										:disabled="model.typeLevelTwoIds.length === 0 || productInputType !== 'CATEGORY'"
										:clear-on-select="true"
									>
										<span slot="noResult">Oops! Data not found.</span>
									</multiselect>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="form-group">
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="checkbox" v-model="hasExcludedProducts" id="hasExcludedProducts" :disabled="productInputType !== 'CATEGORY'">
										<label class="form-check-label" for="hasExcludedProducts">Excluded Product Name</label>
									</div>
									<multiselect
										v-model="selectedExcludedProducts"
										label="title"
										track-by="id"
										placeholder="Select Excluded Product"
										:multiple="true"
										:options="excludedProducts"
										@input="handleInputExcludedProducts"
										:disabled="productInputType !== 'CATEGORY' || !hasExcludedProducts || (model.typeLevelOneIds.length === 0 && model.typeLevelTwoIds.length === 0 && model.typeLevelThreeIds.length === 0)"
										:clear-on-select="true"
									>
										<span slot="noResult">Oops! Data not found.</span>
									</multiselect>
								</div>
							</div>
						</div>
					</template>
					
					<template v-if="model.type !== null">
						<p class="text-dark text-bold-600 mt-2">User Eligibility</p>
						<div class="row">
							<div class="col-12">
								<ValidationProvider name="User Emails" v-slot="{ errors }" rules="emails-separate-comma">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="user-emails">User Emails</label>
										<textarea
											class="form-control"
											v-model="model.emails"
											id="user-emails"
											placeholder="Separated by comma, e.g. user1@email.com,user2@email.com"
											cols="30"
											rows="2">
										</textarea>
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
						</div>
						<p class="text-dark text-bold-600 mt-2">User Purchase History</p>
						<div class="row">
							<ValidationProvider name="purchase order" v-slot="{ errors }" class="col-4">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<input type="checkbox" v-model="model.purchaseOrder">&nbsp;(Riwayat transaksi)
								</div>
							</ValidationProvider>
							<div class="col-4" v-if="model.purchaseOrder === true">
								<ValidationProvider name="purchase condition" v-slot="{ errors }" rules="required">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label class="required">Purchase Condition</label>
										<select class="form-control" v-model="model.purchaseCondition">
											<option value="">Choose Condition</option>
											<option value="=">=</option>
											<option value="<" v-html="'<'"></option>
											<option value=">">></option>
										</select>
										<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
							<div class="col-4" v-if="model.purchaseOrder == true">
								<ValidationProvider name="number of purchase" v-slot="{ errors }" :rules="{ required: true, min_value: numberOfPurchaseMinValue }">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label class="required">Number of Purchase</label>
										<vue-numeric v-model="model.numberOfPurchase" @keypress.native="NumbersOnly" class="form-control" placeholder="Enter number of purchase" currency="" separator="."></vue-numeric>
										<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
						</div>
						<div class="row" v-if="model.purchaseOrder === true">
							<div class="form-group col">
								<label>Purchased Transaction Type</label>
								<multiselect
									v-model="selectedPurchasedOrderTypes"
									label="label"
									track-by="value"
									placeholder="Select Transaction Type"
									:multiple="true"
									:options="orderTypes"
									@input="handleInputPurchasedOrderTypes"
									:clear-on-select="true"
									:close-on-select="false"
								>
									<span slot="noResult">Oops! Data not found.</span>
								</multiselect>
							</div>
							<div class="form-group col">
								<label>Purchased Polyclinic</label>
								<multiselect
									v-model="selectedPurchasedPolyclinics"
									label="namePage"
									track-by="id"
									placeholder="Select Polyclinic"
									:multiple="true"
									:options="polyclinics"
									@input="handleInputPurchasedPolyclinics"
									:clear-on-select="true"
									:close-on-select="false"
									:disabled="isPurchasedPolyclinicsDisabled"
								>
									<span slot="noResult">Oops! Data not found.</span>
								</multiselect>
							</div>
						</div>
						<p class="text-dark text-bold-600 mt-2">Discount Condition</p>
						<div class="row">
							<div class="col-6">
								<ValidationProvider name="discount type" v-slot="{ errors }" rules="required">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="discountTypeInput" class="required">Discount Type</label>
										<select class="form-control" name="discount_type" v-model.lazy="model.discountType" id="discountTypeInput">
											<option :value="null">Select Discount Type</option>
											<option value="rupiah">Rupiah</option>
											<option value="percentage">Persentase</option>
										</select>
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
							<div class="col-6">
								<ValidationProvider name="discount amount" v-slot="{ errors }" :rules="{ required: true }">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="discountInput" class="required">Discount Amount</label>
										<vue-numeric v-if="model.discountType === 'rupiah'" v-model="model.discountRupiah" name="discount" @keypress.native="NumbersOnly" class="form-control" placeholder="Enter discount" currency="Rp" separator="."></vue-numeric>
										<input v-else type="number" v-model="model.discountPercentage" name="discount" class="form-control" placeholder="Enter discount">
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<ValidationProvider name="min. purchase" v-slot="{ errors }" :rules="{ required: true, min_value: model.discountType === 'rupiah' ? model.discountRupiah : false }">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="minPurchaseInput" class="required">Min. Purchase</label>
										<vue-numeric v-model="model.minPurchase" name="minPurchase" @keypress.native="NumbersOnly" class="form-control" placeholder="Enter min. purchase" currency="Rp" separator="."></vue-numeric>
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
							<div class="col-6" v-if="model.discountType == 'percentage'">
								<ValidationProvider name="max discount amount" v-slot="{ errors }">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label>Max Discount Amount</label>
										<vue-numeric v-model="model.maxDiscount" @keypress.native="NumbersOnly" class="form-control" placeholder="Enter max discount amount" currency="Rp" separator="."></vue-numeric>
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>
						</div>
						<div class="row">
							<ValidationProvider name="is active" v-slot="{ errors }" rules="required" class="col">
								<div class="form-group mb-0" :class="{ 'validation-warning': errors[0] }">
									<label for="minPurchaseInput" class="required">Is Active</label>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" v-model.lazy="model.isActive" name="is_active" id="inlineRadio1" :value="true">
										<label class="form-check-label" for="inlineRadio1">Yes</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" v-model.lazy="model.isActive" name="is_active" id="inlineRadio2" :value="false">
										<label class="form-check-label" for="inlineRadio2">No</label>
									</div>
								</div>
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</div>
					</template>
					<div class="row">
						<div class="col">
							<hr>
							<router-link to="/promos" class="btn text-danger">Cancel</router-link>
							<button type="submit" class="btn btn-primary " :disabled="isLoading">
								<div v-if="isLoading">{{id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
								<div v-else>{{id ? 'Update' : 'Create'}}</div>
							</button>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			id: this.$route.params.id,
			file: '',
			model: {
				name: null,
				description: null,
				thumbnail: null,
				image: null,
				startDate: null,
				endDate: null,
				code: null,
				type: null,
				discountType: null,
				discountRupiah: '',
				discountPercentage: '',
				limit: null,
				minPurchase: '',
				maxDiscount: '',
				isActive: null,
				productIds: [],
				visibilityType: 'ATL',
				purchaseOrder: false,
				purchaseCondition: '',
				numberOfPurchase: 1,
				purchasedOrderTypes: [],
				purchasedPolyclinicIds: [],
				platform: 0,
				emails: '',
				orderTypes: [],

				// selected product categories
				typeLevelOneIds: [],
				typeLevelTwoIds: [],
				typeLevelThreeIds: [],
				excludedProductIds: [],
			},
			thumbnailFile: null,
			imageFile: null,
			isDateRequired: false,
			products: [],
			polyclinics: [],
			isLoading: false,
			disabledBefore: null,
			uniqueError: {
				name: false,
				code: false
			},
			mindate:this.$moment().toISOString(),

			// order types
			orderTypes: [
				{
					label: 'Konsultasi',
					value: 1,
					withProduct: false,
					hasPolyclinic: true,
				},
				{
					label: 'Belanja Rx',
					value: 2,
					withProduct: true,
					hasPolyclinic: true,
				},
				{
					label: 'Konsultasi Belanja Rx',
					value: 3,
					withProduct: true,
					hasPolyclinic: true,
				},
				{
					label: 'Belanja OTC',
					value: 4,
					withProduct: true,
					hasPolyclinic: false,
				},
			],
			selectedOrderTypes: [],
			selectedPurchasedOrderTypes: [],
			selectedPurchasedPolyclinics: [],

			// product categories
			productInputType: 'NAME', // or 'CATEGORY'
			hasExcludedProducts: false,
			productCategories: [],
			selectedProductCategoriesLevelOne: [],
			selectedProductCategoriesLevelTwo: [],
			selectedProductCategoriesLevelThree: [],
			excludedProducts: [],
			selectedExcludedProducts: [],
		}
	},
	computed: {
		minStartDate: function() {
			return this.$moment().toISOString()
		},
		minEndDate: function() {
			return this.$moment(this.model.startDate).toISOString()
		},
		numberOfPurchaseMinValue: function() {
			switch (this.model.purchaseCondition) {
				case '=':
					return 0;
				case '<':
					return 1;
				default:
					return 0;
			}
		},
		showProduct: function() {
			const orderWithProducts = this.orderTypes
				.filter(orderType => orderType.withProduct)
				.map(orderType => orderType.value);
			const isWithProduct = this.model.orderTypes.length > 0 && this.model.orderTypes.findIndex(orderType => orderWithProducts.includes(orderType)) !== -1;
			return this.model.type === 'purchase' && isWithProduct;
		},
		isPurchasedPolyclinicsDisabled: function() {
			return this.selectedPurchasedOrderTypes.every(orderTypes => !orderTypes.hasPolyclinic);	
		},
	},
	beforeMount() {
	  if ( this.id ) {
	  	this.axios.get('/promotions/' + this.id)
	        .then(resp => {
	          let data = resp.data.data
	          this.model.name = data.name
	          this.model.description = data.description
	          this.model.startDate = this.$moment(data.startDate).toISOString()
						this.model.endDate = this.$moment(data.endDate).toISOString()
	          this.model.code = data.code
	          this.model.type = data.type
	          this.model.discountType = data.discountType
	          this.model.discountRupiah = data.discountType === 'rupiah' ? data.discount : '';
	          this.model.discountPercentage = data.discountType === 'percentage' ? data.discount : '';
	          this.model.limit = data.limit
	          this.model.minPurchase = data.minPurchase
	          this.model.isActive = data.isActive
	          this.model.productIds = data.products
						this.model.image = data.image
						this.model.thumbnail = data.thumbnail
						this.model.visibilityType = data.visibilityType
						this.model.purchaseOrder = data.purchaseOrder
						this.model.purchaseCondition = data.purchaseCondition
						this.model.numberOfPurchase = data.numberOfPurchase
						this.model.maxDiscount = data.maxDiscount === 0 ? '' : data.maxDiscount;
						this.model.platform = data.platform
						this.model.emails = data?.emails?.join(',') ?? '';

						// order types
						this.model.orderTypes = data.orderTypes || [];
						data.orderTypes.forEach(orderType => {
							const orderTypeObj = this.orderTypes.find(item => item.value === orderType);
							if (orderTypeObj) {
								this.selectedOrderTypes.push(orderTypeObj);
							}
						});

						// product categories
						this.selectedProductCategoriesLevelOne = data.typeLevelOne;
						this.selectedProductCategoriesLevelTwo = data.typeLevelTwo;
						this.selectedProductCategoriesLevelThree = data.typeLevelThree;
						this.model.typeLevelOneIds = data.typeLevelOne.map(type => type.id);
						this.model.typeLevelTwoIds = data.typeLevelTwo.map(type => type.id);
						this.model.typeLevelThreeIds = data.typeLevelThree.map(type => type.id);
						this.productInputType = data.typeLevelOne.length > 0 ? 'CATEGORY' : 'NAME';
						this.selectedExcludedProducts = data.excludedProducts;
						this.model.excludedProductIds = data.excludedProducts.map(product => product.id);
						this.hasExcludedProducts = data.excludedProducts.length > 0;

						if (this.model.typeLevelThreeIds.length > 0) {
							this.getExcludedProducts({ typeLevelThreeId: `in:${this.model.typeLevelThreeIds.join(',')}` });
						} else if (this.model.typeLevelTwoIds.length > 0) {
							this.getExcludedProducts({ typeLevelTwoId: `in:${this.model.typeLevelTwoIds.join(',')}` });
						} else if (this.model.typeLevelOneIds.length > 0) {
							this.getExcludedProducts({ typeLevelOneId: `in:${this.model.typeLevelOneIds.join(',')}` });
						}

						// purchased order types
						this.model.purchasedOrderTypes = data.purchasedOrderTypes || [];
						data.purchasedOrderTypes.forEach(orderType => {
							const orderTypeObj = this.orderTypes.find(item => item.value === orderType);
							if (orderTypeObj) {
								this.selectedPurchasedOrderTypes.push(orderTypeObj);
							}
						});

						// purchased polyclinics
						this.model.purchasedPolyclinicIds = data.purchasedPolyclinics ? data.purchasedPolyclinics.map(polyclinic => polyclinic.id) : [];
						this.selectedPurchasedPolyclinics = data.purchasedPolyclinics;
	        })
	        .catch(err => console.log(err))
	  }
    },
	mounted() {
		this.getListProduct();
		this.getPolyclinicsList();
		this.getProductCategories();
	},
	methods: {
		disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        disableTime(date) {  
            return date < new Date();  
        },
		async submit() {
			this.isDateRequired = true;
			const isValid = await this.$refs.observer.validate()
			if (isValid) {
				const formDataThumbnail = new FormData();
				formDataThumbnail.append('folder', 'promotion');
				formDataThumbnail.append('file', this.thumbnailFile);

				const formDataImage = new FormData();
				formDataImage.append('folder', 'promotion');
				formDataImage.append('file', this.imageFile);

				const headers = {
					'Content-Type': 'multipart/form-data',
				};

				try {
					this.isLoading = true;
	
					// upload images first
					let [responseThumbnail, responseImage] = [null, null];
					responseThumbnail = this.thumbnailFile
						? await this.axios.post('/misc/upload', formDataThumbnail, { headers })
						: null;
					responseImage = this.imageFile
						? await this.axios.post('/misc/upload', formDataImage, { headers })
						: null;

					const isProductInputTypeName = this.productInputType === 'NAME';
					const isBanner = this.model.type === null;
					const data = isBanner ? {
						name: this.model.name,
						description: this.model.description,
						thumbnail: responseThumbnail ? responseThumbnail.data.data.file : this.model.thumbnail,
						image: responseImage ? responseImage.data.data.file : this.model.image,
						type: this.model.type,
						visibilityType: this.model.visibilityType,
						isActive: true,
					} : {
						name: this.model.name,
						description: this.model.description,
						thumbnail: responseThumbnail ? responseThumbnail.data.data.file : this.model.thumbnail,
						image: responseImage ? responseImage.data.data.file : this.model.image,
						startDate: this.model.startDate,
						endDate: this.model.endDate,
						type: this.model.type,
						discountType: this.model.discountType,
						discount: this.model.discountType === 'rupiah' ? this.model.discountRupiah : this.model.discountPercentage,
						limit: this.model.limit,
						minPurchase: this.model.minPurchase,
						maxDiscount: this.model.discountType === 'rupiah' || this.model.maxDiscount === '' ? 0 : this.model.maxDiscount,
						isActive: this.model.isActive,
						productIds: isProductInputTypeName ? this.model.productIds.map(p => p.id) : [],
						visibilityType: this.model.visibilityType,
						purchaseOrder: this.model.purchaseOrder,
						purchaseCondition: this.model.purchaseOrder ? this.model.purchaseCondition : '',
						numberOfPurchase: this.model.purchaseOrder ? this.model.numberOfPurchase : 1,
						purchasedOrderTypes: this.model.purchaseOrder ? this.model.purchasedOrderTypes : [],
						purchasedPolyclinicIds: this.model.purchaseOrder && !this.isPurchasedPolyclinicsDisabled ? this.model.purchasedPolyclinicIds : [],
						platform: this.model.platform,
						emails: this.model.emails.length > 0 ? this.model.emails.split(',') : [],
						orderTypes: this.model.orderTypes,
						typeLevelOneIds: isProductInputTypeName ? [] : this.model.typeLevelOneIds,
						typeLevelTwoIds: isProductInputTypeName ? [] : this.model.typeLevelTwoIds,
						typeLevelThreeIds: isProductInputTypeName ? [] : this.model.typeLevelThreeIds,
						excludedProductIds: !isProductInputTypeName && this.hasExcludedProducts ? this.model.excludedProductIds : [],
					};

					if (this.id) {
						this.axios.put(`/promotions/${this.id}`, data)
							.then((resp) => {
								this.$notify({
									type: 'success',
									title: 'Success!',
									text: resp.data.message
								});
								this.$router.push('/promos');
							})
							.catch(err => {
								this.isLoading = false;
								this.$notify({
									type: 'error',
									title: 'Error',
									text: err.response.data.message
								});
							});
					} else {
						this.axios.post('/promotions', { ...data, code: this.model.code })
							.then((resp) => {
								this.$notify({
									type: 'success',
									title: 'Success!',
									text: resp.data.message
								});
								this.$router.push('/promos');
							})
							.catch(err => {
								this.isLoading = false;
								this.$notify({
									type: 'error',
									title: 'Error',
									text: err.response.data.message
								});
							});
					}
				} catch(err) {
					this.isLoading = false;
					this.$notify({
						type: 'error',
						title: 'Error',
						text: 'Failed to upload images',
					});
				}
			}
		},
		getListProduct() {
			this.axios.get('products')
				.then(resp => {
					this.products = resp.data.data.rows
				})
				.catch(err => {
					console.log(err)
				})
		},
		getPolyclinicsList() {
			this.axios.get('/misc/poly-clinic')
				.then(res => {
					this.polyclinics = res.data.data;
				})
				.catch(err => {
					this.$notify({
							type: 'error',
							title: 'Error',
							text: err.response.data.message,
					});
				});
		},
	    disabledEndDate(date) {
		    return this.model.startDate && date.setHours(0, 0, 0, 0) < new Date(this.model.startDate).setHours(0, 0, 0, 0);
		},
		restrictStartDate(date) {
			return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
		},
		NumbersOnly(evt) {
          evt = (evt) ? evt : window.event
          var charCode = (evt.which) ? evt.which : evt.keyCode
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault()
          } else {
            return true
          }
        },
		storeThumbnail({ valid }, event) {
			if (valid) {
				this.thumbnailFile = event.target.files[0];
				this.$set(this.model, 'thumbnail', window.URL.createObjectURL(event.target.files[0]));
			}
		},
		storeImage({ valid }, event) {
			if (valid) {
				this.imageFile = event.target.files[0];
				this.$set(this.model, 'image', window.URL.createObjectURL(event.target.files[0]));
			}
		},
		check( type, key )
		{
			let _ = this
			let request = {}

			if (_.$route.params.id) {
				request.id = _.$route.params.id
			} 

			if ( type == 'name' ) {
				request.name = key
			} else {
				request.code = key
			}
			
			_.axios.post('/promotions/check', request)
				.then( resp => {
					if (!resp.data.success) {
						if (type == 'name') {
							_.uniqueError.name = true
						} else {
							_.uniqueError.code = true
						}
					} else {
						if (type == 'name') {
							_.uniqueError.name = false
						} else {
							_.uniqueError.code = false
						}
					}
				})
		},
		selectAllProduct()
		{
			let _ = this
			_.model.productIds = []
			for (let i = 0; i < this.products.length; i++) {
				_.model.productIds.push(this.products[i])				
			}
		},
		deselectAllProduct()
		{
			let _ = this
			_.model.productIds = []
		},
		getProductCategories() {
			this.axios.get('/misc/levels')
				.then(res => {
					this.productCategories = res.data.data;
				});
		},
		getExcludedProducts(params) {
			this.axios.get('/products', { params })
				.then(res => {
					this.excludedProducts = res.data.data.rows;
				});
		},
		handleInputProductCategoriesLevelOne(values) {
			this.model.typeLevelOneIds = values.map(value => value.id);
			this.model.typeLevelTwoIds = [];
			this.model.typeLevelThreeIds = [];
			this.model.excludedProductIds = [];
			this.selectedProductCategoriesLevelTwo = [];
			this.selectedProductCategoriesLevelThree = [];
			this.selectedExcludedProducts = [];
			if (values.length > 0) {
				this.getExcludedProducts({ typeLevelOneId: `in:${this.model.typeLevelOneIds.join(',')}` });
			} else {
				this.excludedProducts = [];
			}
		},
		handleInputProductCategoriesLevelTwo(values) {
			this.model.typeLevelTwoIds = values.map(value => value.id);
			this.model.typeLevelThreeIds = [];
			this.model.excludedProductIds = [];
			this.selectedProductCategoriesLevelThree = [];
			this.selectedExcludedProducts = [];
			if (values.length > 0) {
				this.getExcludedProducts({ typeLevelTwoId: `in:${this.model.typeLevelTwoIds.join(',')}` });
			} else {
				this.getExcludedProducts({ typeLevelOneId: `in:${this.model.typeLevelOneIds.join(',')}` });
			}
		},
		handleInputProductCategoriesLevelThree(values) {
			this.model.typeLevelThreeIds = values.map(value => value.id);
			this.model.excludedProductIds = [];
			this.selectedExcludedProducts = [];
			if (values.length > 0) {
				this.getExcludedProducts({ typeLevelThreeId: `in:${this.model.typeLevelThreeIds.join(',')}` });
			} else {
				this.getExcludedProducts({ typeLevelTwoId: `in:${this.model.typeLevelTwoIds.join(',')}` });
			}
		},
		handleInputExcludedProducts(values) {
			this.model.excludedProductIds = values.map(value => value.id);
		},
		handleInputOrderTypes(values) {
			this.model.orderTypes = values.map(value => value.value);
		},
		handleInputPurchasedOrderTypes(values) {
			this.model.purchasedOrderTypes = values.map(value => value.value);
		},
		handleInputPurchasedPolyclinics(values) {
			this.model.purchasedPolyclinicIds = values.map(value => value.id);
		},
	},
	watch: {
    	'model.startDate': function(newValue, oldValue) {
				if (oldValue !== null) {
					this.model.endDate = null;
				}
    	},
			'model.code': function(newValue) {
				this.model.code = newValue.toUpperCase()
				if (newValue != '') {
					this.check( 'code', this.model.code )
				}		
			},
			'model.name': function(newValue) {
				this.model.name = newValue.toUpperCase()
				if (newValue != '') {
					this.check( 'name', newValue )
				}		
			},
			'model.emails': function(emails) {
				if (emails.length > 0) {
					this.model.visibilityType = 'BTL';
				}
			},
			productInputType: function(productInputType) {
				if (productInputType !== 'CATEGORY') {
					this.hasExcludedProducts = false;
				}
			},
    }
}
</script>
<style>
	.theme-grey .vdatetime-popup__header {
		background: #333;
	}

	.theme-grey .vdatetime-calendar__month__day--selected > span > span,
	.theme-grey .vdatetime-calendar__month__day--selected:hover > span > span {
		background: #0036A0;
	}

	.theme-grey .vdatetime-year-picker__item--selected,
	.theme-grey .vdatetime-time-picker__item--selected,
	.theme-grey .vdatetime-popup__actions__button {
		color: #0036A0;
	}
</style>