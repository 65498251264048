<template>
    <div class="card">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Role & Permissions</h3>
            <ValidationObserver ref="observer">
                <form @submit.prevent="submit">
                    <ValidationProvider name="role name" v-slot="{ errors }" rules="required">
                        <div class="form-group">
                            <label for="roleNameInput" class="required">Role Name</label>
                            <input type="text" class="form-control" v-model="roles.name" id="roleNameInput" placeholder="Enter role name">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <label for="" class="required">Permissions</label>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width: 100px;">#</th>
                                    <th>Permission Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(menu, key) in menus.rows" :key="key">
                                    <td class="text-center"><input class="" type="checkbox" :id="'inlineCheckbox' + key" v-model="menuIds" :value="menu.id"></td>
                                    <td class="text-left">{{ menu.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <PaginateList :data="menus" v-if="menus.total != 0"/>
                    <ValidationProvider name="permissions" v-slot="{ errors }" rules="required">
                        <div class="form-group">
                            <input type="hidden" class="form-control" v-model="menuIds">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}, at least 1 permission is selected</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class=" mt-1">
                        <router-link to="/role-permissions" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary " :disabled="isLoading">
                            <div v-if="isLoading">Updating<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Update</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
    components: {
        PaginateList
    },
    data() {
        return {
            roles: {},
            menus: {},
            menuIds: [],
            isLoading: false,
            limit: 10,
        }
    },
    mounted() {
        let _ = this
        _.getRoles()
        _.getMenus()

        _.$root.$on("paginate", (data) => {
            _.getMenus(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getRoles() {
            let _ = this
            _.axios.get('/roles/' + _.$route.params.id)
                .then(resp=>{
                    _.roles = resp.data.data
                    _.menuIds = _.roles.menus.map(m => m.id)
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getMenus(page) {
            let _ = this
            _.axios.get('/menus?limit=' + _.limit + '&page=' + (page ? page : 1))
                .then(resp=>{
                    _.menus = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        hasPermissions(id) {
            let _ = this
            let menu = _.roles.menus.filter(mn => {
                return mn.id == id
            })

            return menu.length > 0
        },
        async submit() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
            _.isLoading = true
            _.axios.put('/roles/' + _.$route.params.id, {
                    name: _.roles.name,
                    menuIds: _.menuIds
                })
                .then(response => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: response.data.message
                    })
                    _.$router.push('/role-permissions')
                    _.isLoading = false
                })
                .catch(err => {
                    console.log(err.response)
                    _.isLoading = false
                })
            }
        }
    },
    watch: {
        limit: function(){
            this.getMenus()
        }
    }
}
</script>