<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">OTC Landing Page</h3>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Top Banner Section">
                            <ValidationProvider name="Top Banner Status" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="models.isActiveTopBannerSection">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div v-for="(banner, index) in models.topBannerSections" :key="index">
                                <div class="row">
                                    <ValidationProvider :name="`Top Banner URL ${index + 1}`" v-slot="{ errors }" class="col" rules="url">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Url Image</label>
                                            <input type="url" v-model="banner.route" class="form-control" placeholder="Enter redirect url">
                                            <small class="text-muted" v-if="errors[0]">The link field format is invalid</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="`Top Banner Image ${index + 1}`" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:1024">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Image</label>
                                            <input 
                                                type="file" 
                                                accept=".png, .jpg, .jpeg" 
                                                @change="(value) => {
                                                    validate(value)
                                                    .then((resp) => validateImage(resp, value, 'topBannerSections', 'imageUrl', index));
                                                }" 
                                                label="Image" 
                                                :name="`imagePage-${index}`" 
                                                class="form-control">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <label>Preview Image</label>
                                    <img v-if="banner.imageUrl" :src="getUrlOfFile(banner.imageUrl)" class="responsive img-thumbnail aspect-ratio-2/1" />
                                    <div v-else class="row justify-content-center">
                                        <skeleton-img class="col-5 aspect-ratio-2/1"/>
                                    </div>
                                </div>
                                
                                <div :class="{'mb-2': index !== models.topBannerSections.length - 1}">
                                    <button v-if="models.topBannerSections.length > 1" type="button" class="btn btn-danger mr-2" @click="deleteItem('topBannerSections', index)">Delete Content</button>&nbsp;
                                    <button v-if="index === models.topBannerSections.length - 1" type="button" class="btn btn-primary" @click="pushItem('topBannerSections')">Add Content</button>
                                </div>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Product">
                            <ValidationProvider name="Product Status" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="models.isActiveProductSection">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div v-for="(product, index) in models.productSections" :key="index">
                                <div class="row mt-3">
                                    <ValidationProvider :name="`Product Title ${index + 1}`" v-slot="{ errors }" class="col" rules="maxWords:225">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title</label>
                                            <input type="text" v-model="product.title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="`Product Sub Title ${index + 1}`" v-slot="{ errors }" class="col" rules="maxWords:225">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Sub Title</label>
                                            <input type="text" v-model="product.subtitle" class="form-control" placeholder="Enter Sub Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <ValidationProvider :name="`Product Icon ${index + 1}`" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:55">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <h5 class="font-500 mb-2">Section Icon</h5>
                                        <div class="row">
                                            <div class="position-relative col-6 col-sm-3">
                                                <img v-if="product.iconUrl" @click="$refs.productIcon[index].click()" :src="getUrlOfFile(product.iconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail"/>
                                                <skeleton-img class="img-icon-thumbnail" v-else @click="$refs.productIcon[index].click()"/>

                                                <button v-if="product.iconUrl" @click="product.iconUrl = null" class="btn btn-rounded btn-danger position-absolute" 
                                                style="top: -10px; right: -5px"
                                                    title="Delete Image"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <input 
                                            ref="productIcon"
                                            type="file" 
                                            accept=".png, .jpg, .jpeg" 
                                            @change="(value) => {
                                                validate(value)
                                                .then((resp) => validateImage(resp, value, 'productSections', 'iconUrl', index));
                                            }" 
                                            label="Image" 
                                            :name="`imagePage-${index}`" 
                                            class="form-control d-none">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider :name="`Product Description ${index + 1}`" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea id="" cols="30" rows="3" v-model="product.description" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider :name="`Product Catalog ${index + 1}`" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="descriptionInput">Catalog Product</label>
                                        <multiselect
                                            v-model="product.catalogProduct"
                                            label="title"
                                            name="products"
                                            track-by="id" 
                                            placeholder="Search Product"
                                            :options="masters.products" 
                                            multiple
                                            searchable
                                            :clear-on-select="false" 
                                            :close-on-select="false" 
                                            :max-height="600">
                                            <span slot="noResult">Oops! There is no product.</span>
                                        </multiselect>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <div :class="{'mb-2': index !== models.productSections.length - 1}">
                                    <button v-if="models.productSections.length > 1" type="button" class="btn btn-danger mr-2" @click="deleteItem('productSections', index)">Delete Content</button>&nbsp;
                                    <button v-if="index === models.productSections.length - 1" type="button" class="btn btn-primary" @click="pushItem('productSections')">Add Content</button>
                                </div>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Quiz">
                            <ValidationProvider name="Quiz Status" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="models.isActiveQuizSection">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row mt-3">
                                <ValidationProvider name="Quiz Title" v-slot="{ errors }" class="col" rules="maxWords:225">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="models.quizSectionTitle" class="form-control" placeholder="Enter Title">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Quiz Sub Title" v-slot="{ errors }" class="col" rules="maxWords:225">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Sub Title</label>
                                        <input type="text" v-model="models.quizSectionSubtitle" class="form-control" placeholder="Enter Sub Title">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="Quiz Icon" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:55">
                                    <div class="form-group row" :class="{ 'validation-warning': errors[0] }">
                                        <div class="col col-sm-6">
                                            <h5 class="font-500 mb-2">Section Icon</h5>
                                            <div class="position-relative">
                                                <img v-if="models.quizSectionIconUrl" @click="$refs.quizIcon.click()" :src="getUrlOfFile(models.quizSectionIconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail" />
                                                <skeleton-img class="img-icon-thumbnail" v-else @click="$refs.quizIcon.click()"/>

                                                <button v-if="models.quizSectionIconUrl" @click="removeImage('quizSectionIconUrl')" class="btn btn-rounded btn-danger position-absolute" 
                                                style="top: -10px; right: -5px"
                                                    title="Delete Image"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                            <input 
                                                ref="quizIcon" 
                                                type="file" 
                                                accept=".png, .jpg, .jpeg" 
                                                @change="(value) => {
                                                    validate(value)
                                                    .then((resp) => validateImage(resp, value, 'quizSectionIconUrl'));
                                                }"
                                                name="quizIcon" 
                                                class="form-control d-none">
                                        </div>
                                        <small class="pl-1 text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Quiz Banner" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:1024">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <h5 class="font-500 mb-2">Banner</h5>
                                        <div class="position-relative">
                                            <img v-if="models.quizSectionBannerUrl" @click="$refs.quizBanner.click()" :src="getUrlOfFile(models.quizSectionBannerUrl)" class="responsive m-0 cursor-pointer img-fluid aspect-ratio-2/1" />
                                            <skeleton-img class="aspect-ratio-2/1" v-else @click="$refs.quizBanner.click()"/>

                                            <button v-if="models.quizSectionBannerUrl" @click="removeImage('quizSectionBannerUrl')" class="btn btn-rounded btn-danger position-absolute" 
                                                style="top: -10px; right: -5px"
                                                title="Delete Image"
                                                type="button">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                        <input 
                                            ref="quizBanner"
                                            type="file" 
                                            accept=".png, .jpg, .jpeg" 
                                            @change="(value) => {
                                                validate(value)
                                                .then((resp) => validateImage(resp, value, 'quizSectionBannerUrl'));
                                            }"
                                            name="quizBanner" 
                                            class="form-control d-none">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="Quiz Description" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea id="" cols="30" rows="3" v-model="models.quizSectionDescription" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="Quiz Catalog" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="quiz">Quiz</label>
                                    <multiselect
                                        id="quiz"
                                        v-model="models.quizSectionQuizId"
                                        label="text"
                                        name="quiz"
                                        track-by="value" 
                                        placeholder="Search Quiz"
                                        :options="masters.quiz"
                                        searchable
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no quiz.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Regimen">
                            <ValidationProvider name="Regimen Status" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="models.isActiveRegimenSection">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div v-for="(regimen, index) in models.regimenSections" :key="index">
                                <div class="row mt-3">
                                    <ValidationProvider :name="`Regimen Title ${index + 1}`" v-slot="{ errors }" class="col" rules="maxWords:225">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title</label>
                                            <input type="text" v-model="regimen.title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="`Regimen Sub Title ${index + 1}`" v-slot="{ errors }" class="col" rules="maxWords:225">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Sub Title</label>
                                            <input type="text" v-model="regimen.subtitle" class="form-control" placeholder="Enter Sub Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <ValidationProvider :name="`Regimen Icon ${index + 1}`" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:55">
                                    <div class="form-group row" :class="{ 'validation-warning': errors[0] }">
                                        <div class="col col-sm-3">
                                            <h5 class="font-500 mb-2">Section Icon</h5>
                                            <div class="position-relative">
                                                <img v-if="regimen.iconUrl" @click="$refs.regimenIcon[index].click()" :src="getUrlOfFile(regimen.iconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail" />
                                                <skeleton-img class="img-icon-thumbnail" v-else @click="$refs.regimenIcon[index].click()"/>

                                                <button v-if="regimen.iconUrl" @click="removeImage('regimenSections', 'iconUrl', index)" class="btn btn-rounded btn-danger position-absolute" 
                                                    style="top: -10px; right: -5px"
                                                    title="Delete Image"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                            <input 
                                                ref="regimenIcon" 
                                                type="file" 
                                                accept=".png, .jpg, .jpeg" 
                                                @change="(value) => {
                                                    validate(value)
                                                    .then((resp) => validateImage(resp, value, 'regimenSections', 'iconUrl', index));
                                                }"
                                                name="regimenIcon" 
                                                class="form-control d-none">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider :name="`Regimen Description ${index + 1}`" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea id="" cols="30" rows="3" v-model="regimen.description" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider :name="`Regimen Catalog ${index + 1}`" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="descriptionInput">Catalog Regimen</label>
                                        <multiselect
                                            v-model="regimen.catalogRegimen"
                                            label="name"
                                            name="regimens"
                                            track-by="id" 
                                            value="id"
                                            placeholder="Search regimen"
                                            :custom-label="regimenCustomLabel"
                                            :options="masters.regimens" 
                                            multiple
                                            searchable
                                            :clear-on-select="false" 
                                            :close-on-select="false" 
                                            :max-height="600">
                                            <span slot="noResult">Oops! There is no Regimen.</span>
                                        </multiselect>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <div :class="{'mb-2': index !== models.regimenSections.length - 1}">
                                    <button v-if="models.regimenSections.length > 1" type="button" class="btn btn-danger mr-2" @click="deleteItem('regimenSections', index)">Delete Content</button>&nbsp;
                                    <button v-if="index === models.regimenSections.length - 1" type="button" class="btn btn-primary" @click="pushItem('regimenSections')">Add Content</button>
                                </div>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Banner">
                            <ValidationProvider name="Banner Status" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="models.isActiveBannerSection">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div v-for="(banner, index) in models.bannerSections" :key="index">
                                <div class="row mt-3">
                                    <ValidationProvider :name="`Banner Title ${index + 1}`" v-slot="{ errors }" class="col" rules="maxWords:225">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title</label>
                                            <input type="text" v-model="banner.title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <ValidationProvider :name="`Banner Icon ${index + 1}`" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:1024">
                                    <div class="form-group row" :class="{ 'validation-warning': errors[0] }">
                                        <div class="col col-sm-6">
                                            <h5 class="font-500 mb-2">Banner</h5>
                                            <div class="position-relative">
                                                <img v-if="banner.imageUrl" @click="$refs.bannerIcon[index].click()" :src="getUrlOfFile(banner.imageUrl)" class="responsive m-0 cursor-pointer img-fluid aspect-ratio-2/1" />
                                                <skeleton-img class="aspect-ratio-2/1" v-else @click="$refs.bannerIcon[index].click()"/>

                                                <button v-if="banner.imageUrl" @click="removeImage('bannerSections', 'imageUrl', index)" class="btn btn-rounded btn-danger position-absolute" 
                                                    style="top: -10px; right: -5px"
                                                    title="Delete Image"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                            <input 
                                                ref="bannerIcon" 
                                                type="file" 
                                                accept=".png, .jpg, .jpeg" 
                                                @change="(value) => {
                                                    validate(value)
                                                    .then((resp) => validateImage(resp, value, 'bannerSections', 'imageUrl', index));
                                                }"
                                                name="bannerIcon" 
                                                class="form-control d-none">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider :name="`Banner Description ${index + 1}`" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea id="" cols="30" rows="3" v-model="banner.description" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider :name="`Banner Sub Title ${index + 1}`" v-slot="{ errors }" rules="url" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <div class="d-flex flex-row align-items-baseline">
                                            <label class="mr-4" style="flex: none">Banner URL</label>
                                            <div class="d-flex flex-column w-100">
                                                <input type="url" v-model="banner.route" class="form-control w-100" placeholder="Enter URL">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </ValidationProvider>

                                <div :class="{'mb-2': index !== models.bannerSections.length - 1}">
                                    <button v-if="models.bannerSections.length > 1" type="button" class="btn btn-danger mr-2" @click="deleteItem('bannerSections', index)">Delete Content</button>&nbsp;
                                    <button v-if="index === models.bannerSections.length - 1" type="button" class="btn btn-primary" @click="pushItem('bannerSections')">Add Content</button>
                                </div>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section FAQ">
                            <div class="row">
                                <ValidationProvider name="FAQ Status" v-slot="{ errors }" class="col">
                                    <div class="form-group" style="width: 40%" :class="{ 'validation-warning': errors[0] }">
                                        <label>Is Active ?</label>
                                        <select class="form-control" v-model="models.isActiveFaqSection">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="FAQ Icon" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:55">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <h5 class="font-500 mb-2">Section Icon</h5>
                                        <div class="row">
                                            <div class="col-5">
                                                <div class="position-relative">
                                                    <img v-if="models.faqIconUrl" @click="$refs.faqIcon.click()" :src="getUrlOfFile(models.faqIconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail" />
                                                    <skeleton-img class="img-icon-thumbnail" v-else @click="$refs.faqIcon.click()"/>
    
                                                    <button v-if="models.faqIconUrl" @click="removeImage('faqIconUrl')" class="btn btn-rounded btn-danger position-absolute" 
                                                        style="top: -10px; right: -5px"
                                                        title="Delete Image"
                                                        type="button">
                                                        <i class="fa fa-times"></i>
                                                    </button>
                                                </div>
                                                <input 
                                                    ref="faqIcon" 
                                                    type="file" 
                                                    accept=".png, .jpg, .jpeg" 
                                                    @change="(value) => {
                                                        validate(value)
                                                        .then((resp) => validateImage(resp, value, 'faqIconUrl'));
                                                    }"
                                                    name="faqIcon" 
                                                    class="form-control d-none">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row mt-3">
                                <ValidationProvider name="`FAQ Title" v-slot="{ errors }" class="col" rules="maxWords:225">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="models.faqTitle" class="form-control" placeholder="Enter Title">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="`FAQ Sub Title" v-slot="{ errors }" class="col" rules="maxWords:225">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Sub Title</label>
                                        <input type="text" v-model="models.faqSubtitle" class="form-control" placeholder="Enter Sub Title">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>

                            <ValidationProvider name="FAQ Catalog" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Catalog FAQ</label>
                                    <multiselect
                                        v-model="models.catalogFaq"
                                        label="question"
                                        name="faq"
                                        track-by="id" 
                                        placeholder="Search FAQ"
                                        :options="masters.faqs" 
                                        multiple
                                        searchable
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no Regimen.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <button @click="resetForm" type="button" class="btn btn-outline-primary mr-1 float-left">
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Update</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import {getUrlOfFile, getImages, convertFiletoURL, isFalsyObj} from '@/helper'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            models: {
                isActiveTopBannerSection: true,
                topBannerSections: [
                    {
                        imageUrl: null,
                        route: null
                    }
                ],
                isActiveProductSection: true,
                productSections: [
                    {
                        title: null,
                        subtitle: null,
                        iconUrl: null,
                        description: null,
                        catalogProduct: []
                    }
                ],
                isActiveQuizSection: true,
                quizSectionTitle: null,
                quizSectionSubtitle: null,
                quizSectionIconUrl: null,
                quizSectionBannerUrl: null,
                quizSectionDescription: null,
                quizSectionQuizId: null,
                isActiveRegimenSection: true,
                regimenSections: [
                    {
                        title: null,
                        subtitle: null,
                        iconUrl: null,
                        description: null,
                        catalogRegimen: []
                    }
                ],
                isActiveBannerSection: true,
                bannerSections: [
                    {
                        title: null,
                        description: null,
                        imageUrl: null,
                        route: null
                    }
                ],
                isActiveFaqSection: true,
                faqTitle: null,
                faqSubtitle: null,
                faqIconUrl: null,
                catalogFaq: []
            },
            duplicate_models: {},
            skeleton_models: {},
            masters: {
                products: [],
                regimens: [],
                faqs: [],
                quiz: [
                    {
                        text: 'Perawatan jenis apa yang membantu menghilangkan muka kusam?',
                        value: 1
                    }
                ]
            },
            isLoading: false,
            getUrlOfFile,
            getImages
        }
    },
    methods: {
        getListOptions(){
			let _ = this
			const getProducts = _.axios.get('misc/products')
			const getFaqs = _.axios.get('dc-master-faq/list-all')
			const getRegimens = _.axios.get('misc/regimens')

			Promise.all([getProducts, getFaqs, getRegimens])
				.then(res => {
					const [products, faqs, regimens] = res
					this.masters.products = products.data.data
					this.masters.faqs = faqs.data.data
					this.masters.regimens = regimens.data.data.map(regimen => {
                        if(!regimen.isActive) {
                            regimen.$isDisabled = true
                        }
                        return regimen
                    })
				})
		},
        async validateImage({valid}, event, type, key, index) {
            if(valid) {
                const file = await getImages(event)
                if(file) {
                    if(typeof index === 'number') {
                        this.$set(this.models[type][index], `${key}`, file)
                    } else {
                        this.models[type] = file
                    }
                }
            } else {
                event.target.value = null
            }
        },
        removeImage(type, key, index) {
            if (typeof index === 'number') {
               this.$set(this.models[type][index], key, null)
            } else if (!key) {
               this.models[type] = null
            } else {
               this.models[type][key] = null
            }
        },
        pushItem(type) {
            this.models[type].push(JSON.parse(JSON.stringify(this.skeleton_models[type][0])))
        },
        deleteItem(type, index) {
            this.models[type].splice(index, 1);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        regimenCustomLabel({name, isActive}) {
            if(isActive) {
                return name
            } else {
                return `${name} (inactive)`
            }
        },
        get() {
            let _ = this
            _.axios.get('/otc/landing-pages')
                .then((resp) => {
                    const {data: landing} = resp.data
                    _.models.isActiveTopBannerSection = landing.isActiveTopBannerSection
                    _.models.topBannerSections = landing.topBannerSections.filter(banner => {
                        return banner
                    })
                        
                    _.models.isActiveProductSection = landing.isActiveProductSection
                    _.models.productSections = landing.otcProductSection.filter(product => {
                        product.catalogProduct = [...product.products]
                        delete product.products
                        return product
                    })
                    _.models.isActiveQuizSection = landing.isActiveQuizSection
                    _.models.quizSectionTitle = landing.quizSectionTitle
                    _.models.quizSectionSubtitle = landing.quizSectionSubtitle
                    _.models.quizSectionIconUrl = landing.quizSectionIconUrl
                    _.models.quizSectionBannerUrl = landing.quizSectionBannerUrl
                    _.models.quizSectionDescription = landing.quizSectionDescription
                    _.models.quizSectionQuizId = landing.quizSectionQuizId === 1 ? {
                        text: 'Perawatan jenis apa yang membantu menghilangkan muka kusam?',
                        value: 1
                    } : null
                    _.models.isActiveRegimenSection = landing.isActiveRegimenSection
                    _.models.regimenSections = landing.otcRegimenSection.filter(regimen => {
                            regimen.catalogRegimen = [...regimen.regimens]
                            delete regimen.regimens
                            return regimen
                    })
                    _.models.isActiveBannerSection = landing.isActiveBannerSection
                    _.models.bannerSections = landing.bannerSections.filter(banner => {
                        return banner
                    })
                    _.models.isActiveFaqSection = landing.isActiveFaqSection
                    _.models.faqTitle = landing.faqTitle
                    _.models.faqSubtitle = landing.faqSubtitle
                    _.models.faqIconUrl = landing.faqIconUrl
                    _.models.catalogFaq = landing.otcFaq.filter(faq => {
                        if(faq.faq) {
                            return faq.faq
                        }
                    }).map(faq => faq.faq)

                    // eslint-disable-next-line no-undef
                    _.duplicate_models = structuredClone(_.models)
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                })
        },
        modifyOptionsObject(newModels) {
            if(newModels.productSections.length) {
                newModels.productSections.forEach(otc => {
                    if(otc.catalogProduct.length) {
                        otc.catalogProduct = otc.catalogProduct.map(product => product.id)
                    }

                    if(otc.id) {
                        delete otc.id
                    }
                })
            }

            if(newModels.regimenSections.length) {
                newModels.regimenSections.forEach(otc => {
                    if(otc.catalogRegimen.length) {
                        otc.catalogRegimen = otc.catalogRegimen.map(regimen => regimen.id)
                    }
                    
                    if(otc.id) {
                        delete otc.id
                    }
                })
            }

            if(newModels.catalogFaq.length) {
                newModels.catalogFaq = newModels.catalogFaq.map(faq => faq.id)
            }

            if(newModels.quizSectionQuizId) {
                newModels.quizSectionQuizId = newModels.quizSectionQuizId.value
            }

            const arrayFields = ['topBannerSections', 'productSections', 'regimenSections', 'bannerSections']
            arrayFields.forEach(field => {
                if(newModels[field].length) {
                    newModels[field] = newModels[field].filter(item => {
                        if(!isFalsyObj(item)) {
                            return item
                        }
                    })
    
                    if(!newModels[field].length) {
                        newModels[field] = this.skeleton_models[field]
                    }
                }
            })

            return newModels
        },
        async save() {
            let _ = this
            _.isLoading = true
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                const modelsToURL = await convertFiletoURL(this.models, 'landing')
                if(modelsToURL) {
                    const newModels = this.modifyOptionsObject(modelsToURL)
                    try {
                        const response = await _.axios.post('otc/landing-pages', newModels)
                        if(response.status === 200) {
                            _.get()
                            _.$notify({
					           type: 'success',
						          title: 'Success',
						          text: `OTC landing successfully updated`
					        })
                        } 
                        _.isLoading = false
                    } catch (err) {
                        _.isLoading = false
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })   
                    }
                }
            } else {
                this.scrollToTop()
                _.isLoading = false
            }
        },
        setInitialValue() {
            let _ = this
            _.skeleton_models = JSON.parse(JSON.stringify(this.models))
        },
        resetForm() {
            let _ = this
            // eslint-disable-next-line no-undef
            _.models = structuredClone(_.duplicate_models)
            window.scrollTo(0,0)
        }
    },
    created() {
        this.setInitialValue()
        this.getListOptions()
        this.$nextTick(() => {
            this.get()
        })
    }
}
</script>