export default {
	login({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},
	tryAutoLogin({ commit }) {
		let updateUser = {
			accessToken: localStorage.getItem('accessToken'),	
			account: JSON.parse(localStorage.getItem('account'))				
		}				
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	},
	content({ commit }, payload) {
		commit('SET_CONTENT_DATA', payload)
	},
	contentLanding({ commit }, payload) {
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_LANDING_DATA', payload)
	},
	contentTransition({ commit }, payload) {
		commit('SET_PRETITLE', payload)
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_TRANSITION_DATA', payload)
	},
	contentImageOpt({ commit }, payload) {
		commit('SET_PRETITLE', payload)
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_IMG_OPT_DATA', payload)
	},
	contentEssay({ commit }, payload) {
		commit('SET_PRETITLE', payload)
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_ESSAY_DATA', payload)
	},
	contentSingleAnswer({ commit }, payload) {
		commit('SET_PRETITLE', payload)
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_SINGLE_ANSWER_DATA', payload)
	},
	contentSingleAnswerWithTextbox({ commit }, payload) {
		commit('SET_PRETITLE', payload)
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_SINGLE_ANSWER_WITH_TEXTBOX_DATA', payload)
	},
	contentMultipleAnswer({ commit }, payload) {
		commit('SET_PRETITLE', payload)
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_MULTIPLE_ANSWER_DATA', payload)
	},
	contentUploadPhoto({ commit }, payload) {
		commit('SET_TITLE', payload)
		commit('SET_DESCRIPTION', payload)
		commit('SET_CONTENT_UPLOAD_PHOTO_DATA', payload)
	},
	removeOpt({ commit }, payload) {
		commit('REMOVE_OPT_DATA', payload)
	},
	addOptLanding({ commit }) {
		commit('ADD_OPT_DATA')
	},
	resetPageOption({ commit }, payload) {
		commit('RESET_PAGE_OPTION', payload)
	},
	contentEdit({ commit }, payload) {
		commit('SET_EDIT_CONTENT_DATA', payload)
	},
}