<template>
    <section>
        <div class="row">
            <div class="col-12 col-md-4 col-lg-4 pointer" @click="$router.push('/')">
                <div class="card text-center">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <i class="material-icons mt-1 mb-1 text-primary" style="font-size: 50px;">shopping_cart</i>
                            </div>
                            <div class="ic-title large">99</div>
                            <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate f-14" data-placement="bottom" data-original-title="Dummy">Dummy</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-8">
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-12 pointer" @click="$router.push('/')">
                        <div class="card mb-2">
                            <div class="card-body ic-statistic d-flex align-items-start">
                                <div class="ic-avatar ic-left-block text-center">
                                    <i class="material-icons text-primary" style="font-size: 49px;">groups</i>
                                </div>
                                <div class="ic-right-block">
                                    <div class="ic-title">99</div>
                                    <p class="mb-0 line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Dummy">Dummy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12 pointer" @click="$router.push('/')">
                        <div class="card mb-2">
                            <div class="card-body ic-statistic d-flex align-items-start">
                                <div class="ic-avatar ic-left-block text-center">
                                    <i class="material-icons text-primary" style="font-size: 49px;">category</i>
                                </div>
                                <div class="ic-right-block">
                                    <div class="ic-title">99</div>
                                    <p class="mb-0 line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Dummy">Dummy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12 pointer" @click="$router.push('/')">
                        <div class="card">
                            <div class="card-body ic-statistic d-flex align-items-start">
                                <div class="ic-avatar ic-left-block text-center">
                                    <i class="material-icons text-primary" style="font-size: 49px;">people</i>
                                </div>
                                <div class="ic-right-block">
                                    <div class="ic-title">99</div>
                                    <p class="mb-0 line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Dummy">Dummy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12 pointer" @click="$router.push('/')">
                        <div class="card">
                            <div class="card-body ic-statistic d-flex align-items-start">
                                <div class="ic-avatar ic-left-block text-center">
                                    <i class="material-icons text-primary" style="font-size: 49px;">support_agent</i>
                                </div>
                                <div class="ic-right-block">
                                    <div class="ic-title">99</div>
                                    <p class="mb-0 line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Dummy">Dummy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="card card-statistic">
                    <div class="card-header">
                        <h5 class="card-title">Penjualan Tahun Ini</h5>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li>
                                    <a data-action="reload">
                                        <i class="feather icon-rotate-cw" @click="getReportYear()"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="body-chart">
                            <apexchart type="bar" height="300" :options="yearOptions" :series="yearData"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="card card-statistic">
                    <div class="card-header">
                        <h5 class="card-title">Penjualan Bulan Ini</h5>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li>
                                    <a data-action="reload">
                                        <i class="feather icon-rotate-cw" @click="getReportMonth()"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="body-chart">
                            <apexchart type="line" height="300" :options="monthOptions" :series="monthData"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            yearData: [
                {
                    name: 'Penjualan',
                    data: [1,2,3,4,5,2,3,1,3,4,5,15]
                }
            ],
            yearOptions: {
                chart: {
                    height: 400,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                colors: ['#D65D51'],
                markers: {
                    size: 4,
                    colors: ["#D65D51"],
                    strokeColors: "#fff",
                    strokeWidth: 2,
                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                }
            },
            monthData: [
                {
                    name: 'Penjualan',
                    data: [1,2,3,4,5,2,3,1,3,4,5,15,1,2,3,4,5,2,3,1,3,4,5,15,1,2,3,4,5,2]
                }
            ],
            monthOptions: {
                chart: {
                    height: 400,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                colors: ['#D65D51'],
                markers: {
                    size: 4,
                    colors: ["#D65D51"],
                    strokeColors: "#fff",
                    strokeWidth: 2,
                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [
                        '1 Okt 2021', 
                        '2 Okt 2021', 
                        '3 Okt 2021', 
                        '4 Okt 2021', 
                        '5 Okt 2021', 
                        '6 Okt 2021',
                        '7 Okt 2021', 
                        '8 Okt 2021', 
                        '9 Okt 2021', 
                        '10 Okt 2021', 
                        '11 Okt 2021', 
                        '12 Okt 2021',
                        '13 Okt 2021',
                        '14 Okt 2021',
                        '15 Okt 2021',
                        '16 Okt 2021',
                        '17 Okt 2021',
                        '18 Okt 2021',
                        '19 Okt 2021',
                        '20 Okt 2021',
                        '21 Okt 2021',
                        '22 Okt 2021',
                        '23 Okt 2021',
                        '24 Okt 2021',
                        '25 Okt 2021',
                        '26 Okt 2021',
                        '27 Okt 2021',
                        '28 Okt 2021',
                        '29 Okt 2021',
                        '30 Okt 2021',
                    ],
                }
            }
        }
    },
    methods: {
        getReportYear(){
            //
        },
        getReportMonth(){
            //
        }
    }
}
</script>
