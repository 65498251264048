<template>
	<div>
        <h3 class="font-600 mb-1">{{ title }}</h3>
        <slot name="header"></slot>
        <div class="table-responsive">
            <table class="table mb-0">
                <slot name="thead"></slot>
                <tbody>
                    <tr v-for="(data, key) in dataTable.lists.rows" :key="key">
                        <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn" :class="column == 'counter' ? 'text-center' : ''">
                            <span v-if="(column == 'counter')">
                                {{ withPagination ? ((dataTable.lists.currentPage - 1) * dataTable.lists.perPage + key + 1) : key + 1 }}
                            </span>
                            <span v-else-if="column == 'created_at' || column == 'updated_at' || column == 'updatedAt' || column == 'createdAt'">
                                {{ data[column] | moment("DD/MM/YYYY, HH:mm") }}
                            </span>
                            <span v-else-if="column == 'date'">
                                {{ data[column] | moment("DD/MM/YYYY") }}
                            </span>
                            <span v-else-if="column === 'category.name'">
                                {{ data['category']['name'] }}
                            </span>
                            <span v-else-if="column == 'tags'">
                                <tr class="table-tag" v-for="(row, index) in data.tags" :key="index">
                                    <td>{{ row.name }}</td>
                                </tr>
                            </span>
                            <span v-else-if="column == 'images'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid pointer img-table" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'avatar'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid avatar-small border-0" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'image'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid pointer img-table" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'icon'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid pointer img-table" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'imageThumbnail'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid pointer img-table" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'imagePage'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid pointer img-table" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'mwebIcon'">
                                <img v-if="data[column] != null" :src="data[column]" class="img-fluid pointer img-table" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'description'">
                                <span v-html="data[column] && data[column].length > 250 ? data[column].substring(0, 250) + '...' : data[column]"></span>
                            </span>
                            <span v-else-if="column == 'ourTreatment'">
                                <span v-html="data[column] && data[column].length > 250 ? data[column].substring(0, 250) + '...' : data[column]"></span>
                            </span>
                            <span v-else-if="column == 'answer'">
                                <span v-html="data[column] && data[column].length > 250 ? data[column].substring(0, 250) + '...' : data[column]"></span>
                            </span>
                            <span v-else-if="column == 'review'">
                                <span v-html="data[column] && data[column].length > 255 ? data[column].substring(0, 255) + '...' : data[column]"></span>
                            </span>
                            <span v-else-if="column == 'commission'">
                                {{ data[column] }} %
                            </span>
                            <span v-else-if="column == 'stock'">
                                <template v-if="data[column] === '-'">-</template>
                                <template v-else>{{ data[column] | numFormat }}</template>
                            </span>
                             <span v-else-if="column === 'account.email'">
                                {{ data['account']['email'] }}
                            </span>
                            <span v-else-if="column === 'author.fullName'">
                                {{ data['author'] ? data['author']['fullName'] : '-' }}
                            </span>
                            <span v-else-if="column === 'pageType.name'">
                                {{ data['pageType'] ? data['pageType']['name'] : '-' }}
                            </span>
                            <span v-else-if="column === 'editedBy.fullName'">
                                {{ data['editedBy'] ? data['editedBy']['fullName'] : '-' }}
                            </span>
                            <span v-else-if="column === 'polyClinic.namePage'">
                                {{ data['polyClinic'] ? data['polyClinic']['namePage'] : '-' }}
                            </span>
                            <span v-else-if="column === 'flow.title'">
                                {{ data['flow'] ? data['flow']['title'] : '-' }}
                            </span>
                            <span v-else-if="column === 'role.name'">
                                {{ data['role']['name'] }}
                            </span>
                            <span v-else-if="column == 'price' || column == 'discountPrice'">
                                <template v-if="data[column] === null">-</template>
                                <template v-else>{{ data[column] | currency }}</template>
                            </span>
                            <span v-else-if="column == 'remainingCounter'">
                                <span v-if="data.limit != 0">{{ data.limit - data.limitCounter | numFormat }}</span>
                                <span v-else>∞</span>
                            </span>
                            <span v-else-if="column == 'promoStatus'">
                                <span v-if="data.isActive" class="text-success">Active</span>
                                <span v-else class="text-danger">Inactive</span>
                            </span>
                            <span v-else-if="column == 'rating'" style="white-space: nowrap;">
                                <span class="" v-for="i in data[column]" :key="i"><i class="fa fa-star mr-1 text-warning f-16"></i></span>
                            </span> 
                            <span v-else-if="column == 'isPopular'">
                                <span v-if="data.isPopular" class="bg-warning text-white rounded" style="padding: 0.25rem 0.5rem">Popular</span>
                                <span v-else>-</span>
                            </span> 
                            <span v-else v-html="data[column] == null ? '-' : data[column]"></span>
                        </td>
                        <td v-if="showAction">
                            <slot name="action" v-bind:data="data" v-bind:index="key"></slot>
                        </td>
                    </tr>
                </tbody>           
            </table>
            <div class="col text-center mt-1">
                <p class="f-14" v-if="dataTable.lists.count == 0 || !dataTable.lists.rows">There is no data</p>
            </div>                                                                           
        </div>
        <div v-if="withPagination">
            <PaginateList :data="dataTable.lists" v-if="dataTable.lists.total != 0"/>
        </div>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'
export default {
    props: {
        title: String,
        dataTable: Object,
        withPagination: {
            type: Boolean,
            default: true,
        },
        showAction: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        PaginateList
    },
    methods: {
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
    },
    mounted() {
        this.$moment.locale('id')
    }
}
</script>