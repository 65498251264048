<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <ValidationObserver ref="observer">
                <form @submit.prevent="save()">
                    <h3 class="font-600 mb-2">Edit Profile</h3>
                    <div class="row">
                        <div class="col-lg-4 col-xs-12 text-center mb-2">
                            <div class="profile-avatar">
                                <img :src="model.avatar ? model.avatar:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                <div class="edit-avatar">
                                    <label for="file-upload" class="btn-edit">
                                        <i class="fa fa-pencil"></i>
                                    </label>
                                    <input id="file-upload" class="input-file-avatar" type="file" v-on:change="handleFileUpload">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Email</label>
                                    <input type="text" v-model="model.email" class="form-control" placeholder="Enter email">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Name</label>
                                    <input type="text" v-model="model.fullName" class="form-control" placeholder="Enter name">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="no. telp" v-slot="{ errors }">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Phone Number</label>
                                    <input type="number" v-model="model.mobileNumber" class="form-control" placeholder="Enter phone number">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <hr>
                    <div class="">
                        <router-link to="/" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary" :disabled="isLoading">
                            <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Save</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                id: null,
                email: "",
                fullName: "",
                mobileNumber: "",
                avatar: ""
            },
            temp_photo: null,
            isLoading: false
        }
    },
    mounted() {
        let _ = this
        _.me()
    },
    methods: {
        save() {
            let _ = this
            let data = {
                email: _.model.email,
                firstName: _.model.fullName.split(' ')[0],
                lastName: _.model.fullName.split(' ').filter( (n,i) => i > 0 ).join(' '),
                mobileNumber: _.model.mobileNumber,
                avatar: _.model.avatar
            }
            _.isLoading = true
            _.axios.put('admins/' + _.model.id, data)
                .then(() => {
                    _.$swal.fire({
                      title: 'Data Saved!',
                      text: 'Profile Updated',
                      icon: 'success',
                      confirmButtonColor: '#0036A0',
                      confirmButtonText: 'OK'
                    })

                    _.isLoading = false
                    _.me()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    });
                    _.isLoading = false
                })
        },
        me() {
            let _ = this
            _.axios.get('auth/me')
                .then(resp => {
                    let data = resp.data.data
                    _.model.id = data.id
                    _.model.email = data.email
                    _.model.fullName = data.admin.fullName
                    _.model.mobileNumber = data.admin.mobileNumber
                    _.model.avatar = data.admin.avatar

                    _.$root.$emit('changeProfile', resp.data.data)
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    })
                })
        },
        handleFileUpload(event){
            this.file = event.target.files[0]

            let formData = new FormData()
            formData.append('folder', 'avatars')
            formData.append('file', this.file)

            this.axios.post('/misc/upload',
                formData,
                {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
                }).then(resp => {
                    if ( resp.data.success ) {
                      let file = resp.data.data.file
                      this.model.avatar = file
                    } else {
                      this.model.avatar = 'https://via.placeholder.com/150?text=dummy+image'
                    }
                }).catch(err => {
                    console.log(err.response)
                })
          },
    }
}
</script>