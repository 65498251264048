<template>
	<div class="card">
		<div class="card-body">
			<h3 class="font-600 mb-2">School Directory Detail</h3>
			<ValidationObserver class="card-content" ref="observer">
				<form @submit.prevent="submit">
					<ValidationProvider name="title" v-slot="{ errors }" rules="maxWords:20|required">
						<div class="form-group" :class="{ 'validation-warning': isTitleExists || errors[0] }">
							<label for="title" class="required">Title</label>
							<input type="text" v-model="models.title" name="title" class="form-control" id="title" placeholder="Enter title" ref="titleField">
							<small class="text-muted" v-if="isTitleExists">The title is already exist</small>
							<small class="text-muted" v-else-if="errors[0]">{{ errors[0] }}</small>
						</div>
					</ValidationProvider>

          <h5>Card Section</h5>
          <p>Choose card to be displayed per group, 1 group must be consist of 5 cards</p>
          <div class="row align-items-start" v-for="(card, index) in models.schoolGroups" :key="index">
            <ValidationProvider :name="`Card ${index + 1}`" v-slot="{ errors }" class="col" rules="required|lengthCard:5">
              <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                <multiselect
                  v-model="card.schoolCardIds"
                  :max="5"
                  label="title"
                  placeholder="Search card"
                  track-by="id"
                  :options="masters.schoolCards"
                  multiple
                  searchable
                  :clear-on-select="false" 
                  :close-on-select="false" 
                  :max-height="600">
                  <span slot="noResult">Oops! There is no card content.</span>
                </multiselect>
                <small class="text-muted" v-if="errors[0]">1 card section must have 5 contents</small>
              </div>
            </ValidationProvider>
            <div class="col-2">
              <button v-if="index === models.schoolGroups.length - 1" @click="pushItem('schoolGroups')" type="button" class="btn btn-info mr-1 btn-sm"><i class="fa fa-plus"></i></button>
              <button v-if="models.schoolGroups.length > 1" @click="deleteItem('schoolGroups', index)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
            </div>
          </div>
					<div class="">
						<router-link to="/school-directory" class="btn text-danger">Cancel</router-link>
						<button type="submit" class="btn btn-primary " :disabled="isLoading">
							<div v-if="isLoading">{{id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
							<div v-else>{{id ? 'Update' : 'Create'}}</div>
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			models: {
        title: "",
        schoolGroups: [
          {
            schoolCardIds: []
          }
        ]
			},
      duplicate_title: '',
      isTitleExists: false,
      duplicate_models: {},
      masters:{
        schoolCards: []
      },
			isLoading: false,
		}
	},
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  created() {
		let _ = this
		_.duplicate_models = JSON.parse(JSON.stringify(_.models))
		_.getListOptions()
		_.getById()
	},
  methods: {
    setTitleWatcher() {
      let _ = this
      _.$watch('models.title', () => {
        clearTimeout(_.debounce)
        _.debounce = setTimeout(async () => {
          if(_.models.title.toLowerCase() !== _.duplicate_title.toLowerCase()) {
            // Check if title exist
            try {
              const {status} = await _.axios.post('otc/school-directory/title-exist', {
                title: _.models.title
              })
              if(status === 200) {
                _.isTitleExists = false
              }
            } catch (error) {
              const { status } = error.response
              if(status === 400) {
                _.isTitleExists = true
              }
            }
          }
        }, 200)
      })
    },
    getListOptions(){
			let _ = this
			const getDirectories = _.axios.get('misc/school-card')

			Promise.all([getDirectories])
				.then(res => {
					const [schoolCards] = res
					this.masters.schoolCards = schoolCards.data.data
				})
		},
		getById() {
			let _ = this
			if (_.$route.params.id) {
        _.axios.get(`otc/school-directory/${_.$route.params.id}`)
					.then(res => {
						const {data} = res.data
            _.models.title = data.title
            _.duplicate_title = data.title
            _.models.schoolGroups = data.schoolGroups.length ? data.schoolGroups.map(group => {
              return {
                schoolCardIds: group.schoolCardIds.map(card => card.schoolCard)
              }
            }) : [{ schoolCardIds: null }]

            _.$nextTick(() => {
              _.setTitleWatcher()
            })
					})
			} else {
        _.setTitleWatcher()
      }
		},
		pushItem(type) {
			this.models[type].push(JSON.parse(JSON.stringify(this.duplicate_models[type][0])))
		},
		deleteItem(type, index) {
			this.models[type].splice(index, 1);
		},
		modifyOptionsObject(newModels) {
      newModels.schoolGroups = newModels.schoolGroups.map(group => {
        if(group.schoolCardIds) {
          return {
            schoolCardIds: group.schoolCardIds.map(card => card.id)
          }
        }
      }).filter(valid => valid)
			return newModels
		},
		async submit() {
			let _ = this
      if(this.isTitleExists) {
        _.$refs.titleField.scrollIntoView({ 
					behavior: "smooth",
					block: 'center'
				})
        return false
			}
			const isValid = await _.$refs.observer.validate()
			if ( isValid ) {
        _.isLoading = true
        // eslint-disable-next-line no-undef
        const modelsToURL = structuredClone(_.models)
				if(modelsToURL) {
          const newModels = _.modifyOptionsObject(modelsToURL)
          let request = ''
          let message = 'created'
          
          try {
            if (!_.$route.params.id) {
              request = await _.axios.post('otc/school-directory', newModels)
            } else {
              message = 'updated'
              request = await _.axios.put(`otc/school-directory/${_.$route.params.id}`, newModels)
            }
            if(request.status === 200) {
              _.$notify({
                type: 'success',
                title: 'Success',
                text: `Homepage successfully ${message}`
              })
              _.isLoading = false
              _.$router.push('/school-directory')
            }
          } catch (error) {
            _.$notify({
              type: 'error',
              title: 'Failed',
              text: error.response.data.message
            })
            _.isLoading = false
          }
        }
      }
		},
  }
}
</script>