import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import VueAxios from 'vue-axios'
import VModal from 'vue-js-modal'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full.esm'
import { alpha_spaces, alpha_num, min, max, regex, email as emailRule } from 'vee-validate/dist/rules'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToast from 'vue-toast-notification'
import VueCurrencyFilter from 'vue-currency-filter'
import VueFormWizard from 'vue-form-wizard'
import DatePicker from 'vue2-datepicker'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import VueNumericInput from 'vue-numeric-input'
import VueQuillEditor from 'vue-quill-editor'
import VCalendar from 'v-calendar'
import Switches from 'vue-switches'
import VueMoment from 'vue-moment'
import moment from 'moment'
import Geocoder from '@pderas/vue2-geocoder'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueCarousel from 'vue-carousel'
import Notifications from 'vue-notification'
import VueTimepicker from 'vue2-timepicker'
import VueNumeric from 'vue-numeric'
import JsonViewer from 'vue-json-viewer'
import { Sketch } from 'vue-color'
import { Datetime } from 'vue-datetime'
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
import SkeletonImg from '@/layouts/components/SkeletonImg.vue'

import 'vue2-datepicker/index.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-select/dist/vue-select.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(VueAxios, axios)
Vue.use(VModal)
Vue.use(VueMoment, { moment })
Vue.use(VueFormWizard)
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2)
Vue.use(VueNumericInput)
Vue.use(VueQuillEditor)
Vue.use(VueCarousel)
Vue.use(Notifications)
Vue.use(JsonViewer)
Vue.use(Datetime)
Vue.use(VueToast, {
  position: 'top'
})

Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	}
])

Vue.use(VCalendar, {
	componentPrefix: 'vc'
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places',
    },
    installComponents: true
})

Vue.use(Geocoder, {
    defaultCountryCode: null,
    defaultLanguage: null,
    defaultMode: 'lat-lng',
    googleMapsApiKey: process.env.VUE_APP_GOOGLE_KEY
});

Vue.component('DatePicker', DatePicker)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('multiselect', Multiselect)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect)
Vue.component('switches', Switches)
Vue.component('VueTimepicker', VueTimepicker)
Vue.component('VueNumeric', VueNumeric)
Vue.component('sketch-picker', Sketch)
Vue.component('datetime', Datetime)
Vue.component('skeleton-img', SkeletonImg)

// Quill modules
Quill.register('modules/imageUploader', ImageUploader);

// Vee Validate Rules
extend('alpha_spaces', alpha_spaces);
extend('alpha_num', alpha_num);
extend('min', min);
extend('max', max);
extend('regex', regex);
extend('image', value => {
	const regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|JPG|JPEG|PNG)$/)
	if (regex.test(value[0].type)) {
		return true;
	}
	return 'Allowed file extensions are : *.jpg, *.jpeg, *.png';
});
extend('maxWords', {
	validate(value, {max}) {
		if(value.length > max) {
			return `Max ${max} chars`
		}
		return true
	},
	params: ['max'],
})
extend('lengthCard', {
	validate(value, {max, except}) {
		if(except) {
			if(value.length == max) {
				return `Can’t save ${max} contents, please remove 1 or add 1 more content`
			}
		} else {
			if(value.length > max || value.length < max) {
				return `1 card section must have ${max} contents`
			}
		}
		return true
	},
	params: ['max', 'except'],
})
extend('size', {
	validate(value) {
		return value
	},
	params: ['fileSize'],
	message: (_, {fileSize, _value_}) =>  {
		const sizeInMb = fileSize / 1000
		const sizeLimit = 1024 * 1024 * Number(sizeInMb)
		
		if(_value_.size > sizeLimit) {
			return `File is too large, allowed maximum size ${sizeInMb} is MB`
		} 
	}
});
extend('url', value => {
	const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/);
	if (regex.test(value)) {
		return true;
	}
	return 'The link field format is invalid';
});
extend('slug', value => {
	const regex = new RegExp(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/);
	if (regex.test(value)) {
		return true;
	}
	return 'The slug format is invalid';
});
extend('url-max-words', {
  validate(value, args) {
		const regex = new RegExp(`^[a-z\\d]+(-[a-z\\d]+){0,${args.max - 1}}$`);
		if (regex.test(value)) {
			return true;
		}
    return `Wrong URL format. Max ${args.max} words (lowercase or number only) separated by dash (-)`;
  },
  params: ['max']
});
extend('emails-separate-comma', {
  validate(value) {
		return value.split(',').reduce((prev, email) => prev && emailRule.validate(email), true);
  },
});
extend('max-width-dimensions', {
  async validate(files, { width }) {
		const validateDimensions = (file, width) => {
			const URL = window.URL || window.webkitURL;
			return new Promise(resolve => {
				const image = new Image();
				image.onerror = () => resolve("Can't load image");
				image.onload = () => {
					const isWidthValid = typeof width === 'undefined' ? true : image.width <= Number(width);
					resolve(isWidthValid);
				};
				image.src = URL.createObjectURL(file);
			});
		};

		const isValidArray = await Promise.all(files.map(file => validateDimensions(file, width)));
		if (isValidArray.every(isValid => isValid)) {
			return true;
		}

		const widthErrorMsg = typeof width !== 'undefined' ? ` ${width} pixels` : '';
		return `Width image is too large, allowed maximum size is ${widthErrorMsg}`;
  },
  params: ['width'],
});
extend('max-dimensions', {
  async validate(files, { width, height }) {
		const validateDimensions = (file, width, height) => {
			const URL = window.URL || window.webkitURL;
			return new Promise(resolve => {
				const image = new Image();
				image.onerror = () => resolve("Can't load image");
				image.onload = () => {
					const isWidthValid = typeof width === 'undefined' ? true : image.width <= Number(width);
					const isHeightValid = typeof height === 'undefined' ? true : image.height <= Number(height);
					resolve(isWidthValid && isHeightValid);
				};
				image.src = URL.createObjectURL(file);
			});
		};
		const isValidArray = await Promise.all(files.map(file => validateDimensions(file, width, height)));
		if (isValidArray.every(isValid => isValid)) {
			return true;
		}

		const widthErrorMsg = typeof width !== 'undefined' ? ` ${width} pixel width` : '';
		const heightErrorMsg = typeof height !== 'undefined' ? ` ${height} pixel height` : '';
		
		return `Max image dimensions is${widthErrorMsg}${heightErrorMsg}`;
  },
  params: ['width', 'height'],
});

Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL

Vue.directive('can', {
	inserted(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (localStorage.getItem('role').indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = '';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (localStorage.getItem('role').indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = '';
			}
		}
	},
	update(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (localStorage.getItem('role').indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = '';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (localStorage.getItem('role').indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = '';
			}
		}
	},
	bind(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (localStorage.getItem('role').indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = '';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (localStorage.getItem('role').indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = '';
			}
		}
	}
});

new Vue({
	store,
	router,
	render: h => h(App),
	mounted() {
		if (localStorage.getItem('token')){
			this.$store.dispatch('tryAutoLogin')
		}
	}
}).$mount('#app')
