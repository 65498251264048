<template>
  <form>
    <ValidationProvider name="title section" ref="title section" v-slot="{ errors }" rules="required">
      <div class="form-group">
        <label for="titleSection">Title Section</label>
        <input type="text" v-model="model.title" name="Title" id="titleSection" class="form-control" placeholder="Enter title">
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>

    <ValidationProvider name="description section" ref="description section" v-slot="{ errors }" rules="required">
      <div class="form-group">
        <label for="descriptionSection">Description Section</label>
        <textarea class="form-control" v-model="model.description" id="descriptionSection" placeholder="Enter description"></textarea>
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>

    <div class="form-group">
      <label>Tips</label>
    </div>

    <div class="row" v-for="(_, index) in model.tips" :key="index">
      <ValidationProvider name="tip" :ref="`tip${index}`" v-slot="{ errors }" rules="required" class="form-group col-10">
        <input type="text" v-model="model.tips[index]" placeholder="Enter tip" class="form-control">
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </ValidationProvider>

      <div class="form-group col-2">
        <button class="btn btn-danger" @click="removeTip(index)" type="button">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
    <button class="btn btn-info mb-2" @click="addTip" type="button" v-if="model.tips.length < 3">
      <i class="fa fa-plus"></i>
      Add Tip
    </button>

    <div class="form-group">
      <label>Guides</label>
    </div>

    <div class="row">
      <div class="col-12 d-flex" style="gap: 1rem; flex-wrap: wrap;">
        <div v-for="(guide, index) in model.guides" :key="index">
          <div class="position-relative">
            <img :src="guide" width="140" height="140" style="object-fit: cover; border-radius: 10px;" alt="">
            <div
              class="position-absolute bg-danger rounded-circle text-center"
              style="top: -10px; right: -10px; width: 21px; height: 21px; cursor: pointer;"
              @click="removeGuide(index)"
            >
              <i class="fa fa-close text-white f-14"></i> 
            </div>
          </div>
        </div>
        <div v-if="model.guides.length < 4" class="upload-image-button">
          <label for="file-upload" class="f-24" style="cursor: pointer;">
            <i class="fa fa-upload" aria-hidden="true"></i> 
            <p class="f-14">{{ isUploading ? 'Uploading...' : 'Add Image'}}</p>
          </label>
          <input id="file-upload" class="input-file-avatar d-none" type="file" accept="image/png, image/jpeg" @change="handleFileUpload($event)" :disabled="isUploading" />
        </div>
      </div>
    </div>
    <hr/>
  </form>
</template>

<script>
export default {
    name: 'PageTypeUploadPhoto',
    data() {
      return {
        model: {
          title: '',
          description: '',
          tips: [],
          guides: [],
        },
        isUploading: false,
        maxFileSize: 2,
      }
    },
    beforeMount() {
      if (this.$route.params.id) {
        this.model = this.$store.getters.currentEditContent;
      }
    },
    methods: {
      handleFileUpload(event){
        const file = event.target.files[0];
        if (file.size > ((1024 * 1024) * this.maxFileSize)) {
          this.$toast.open({
            type: 'error',
            message: `The image file is too large, allowed maximum size is ${this.maxFileSize} MB`,
          });
          return;
        }

        const formData = new FormData();
        formData.append('folder', 'contents');
        formData.append('file', file);

        this.isUploading = true;

        this.axios.post('/misc/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(resp => {
            if (resp.data.success) {
              this.model.guides.push(resp.data.data.file);
            }
          }).catch(err => {
            console.log(err.response);
          }).finally(() => {
            this.isUploading = false;
          });
      },
      addTip() {
        this.model.tips.push('');
      },
      removeTip(index) {
        this.model.tips = [...this.model.tips.slice(0, index), ...this.model.tips.slice(index + 1)];
      },
      removeGuide(index) {
        this.model.guides = [...this.model.guides.slice(0, index), ...this.model.guides.slice(index + 1)];
      },
      updateStore(payload) {
        this.$store.dispatch('contentUploadPhoto', payload);
      },
    },
    watch: {
      'model.title': function(newValue) {
        this.updateStore({ pageOption: { title: newValue } });
      },
      'model.description': function(newValue) {
        this.updateStore({ pageOption: { description: newValue } });
      },
      'model.tips': function(newValue) {
        this.updateStore({ pageOption: { tips: newValue } });
      },
      'model.guides': function(newValue) {
        this.updateStore({ pageOption: { guides: newValue } });
      },
    },
    mounted() {
      this.maxFileSize = process.env.VUE_APP_MAX_FILE_SIZE;
    }
}
</script>

<style scoped>
.upload-image-button {
  text-align: center;
  border-radius: 10px;
  background: #E5E7E9;
  cursor: pointer;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>