<template>
    <div class="card">
        <div class="card-body">
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <ul class="mb-0">
                    <div v-for="(v, k) in errors" :key="k">
                        <li class="text-danger">{{v.message}}</li>
                    </div>
                </ul>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="clinic1" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Clinic 1</label>
                                <select name="clinic1" v-model="model.clinic1" class="form-control">
                                    <option value="">Select Polyclinic</option>
                                    <option v-for="(data,index) in polys" :key="index" :value="data.id">
                                        {{data.namePage}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="clinic2" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Clinic 2</label>
                                <select name="clinic2" v-model="model.clinic2" class="form-control">
                                    <option value="">Select Polyclinic</option>
                                    <option v-for="(data,index) in polys" :key="index" :value="data.id">
                                        {{data.namePage}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="clinic3" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Clinic 3</label>
                                <select name="clinic3" v-model="model.clinic3" class="form-control">
                                    <option value="">Select Polyclinic</option>
                                    <option v-for="(data,index) in polys" :key="index" :value="data.id">
                                        {{data.namePage}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="clinic4" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Clinic 4</label>
                                <select name="clinic4" v-model="model.clinic4" class="form-control">
                                    <option value="">Select Polyclinic</option>
                                    <option v-for="(data,index) in polys" :key="index" :value="data.id">
                                        {{data.namePage}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="clinic5" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Clinic 5</label>
                                <select name="clinic5" v-model="model.clinic5" class="form-control">
                                    <option value="">Select Polyclinic</option>
                                    <option v-for="(data,index) in polys" :key="index" :value="data.id">
                                        {{data.namePage}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="clinic6" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Clinic 6</label>
                                <select name="clinic6" v-model="model.clinic6" class="form-control">
                                    <option value="">Select Polyclinic</option>
                                    <option v-for="(data,index) in polys" :key="index" :value="data.id">
                                        {{data.namePage}}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            collapseType: 'margin',
            model: {
                clinic1: '',
                clinic2: '',
                clinic3: '',
                clinic4: '',
                clinic5: '',
                clinic6: '',
                buttonConsult1: ''
            },
            polys: [],
            buttons: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getPolyclinics() {
            let _ = this
            _.axios.get('/dc-poly/list-all')
            .then(resp=>{
                _.polys = resp.data.data
            })
        },
        get() {
            let _ = this
            _.axios.get('/dc-navbar')
                .then(resp => {
                    _.model = resp.data.data
                    _.model.clinic1 = _.model.polys.id
                    _.model.clinic2 = _.model.polys.id
                    _.model.clinic3 = _.model.polys.id
                    _.model.clinic4 = _.model.polys.id
                    _.model.clinic5 = _.model.polys.id
                    _.model.clinic6 = _.model.polys.id
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.put('/dc-navbar', _.model)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                })
                .catch(err => {
                    _.errors = err.response.data.data
                    _.isLoading = false
                })
            }
        },
    },
    mounted() {
        let _ = this;
        _.getPolyclinics()
        _.getButtons()
        _.get()
    },
};
</script>
