<template>
    <form @submit.prevent="submit">
      <div class="form-group">
        <label for="pretitleInput">Image Option Pre Heading</label>
        <input type="text" v-model="model.preTitle" name="pretitle" class="form-control" placeholder="Enter pre heading">
        <small class="text-danger" v-show="errors.preTitle">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Image Option Title</label>
        <input type="text" v-model="model.title" name="title" class="form-control" placeholder="Enter title">
        <small class="text-danger" v-show="errors.title">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Image Option Description</label>
        <textarea class="form-control" v-model="model.description" id="descriptionInput" placeholder="Enter description">
          
        </textarea>
        <small class="text-danger" v-show="errors.description">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >List Image Option</label>
      </div>

      <div class="form-row" v-for="(opt, key) in listOptions" :key="key">
        <div class="form-group col-md-3">
          <!-- <input type="file" @change="handleFileUpload( $event, key )"/> -->
          <div style="text-align: -webkit-center;">
              <div v-if="listOptions[key].file != null">
                  <img :src="listOptions[key].file" class="img-fluid" alt=""><br>
                  <button class="btn btn-danger mt-1 mb-1" @click="listOptions[key].file = null">Delete Image</button>
              </div>
              <div v-else 
                  style="
                      text-align: center; 
                      border-radius: 10px; 
                      background: #E5E7E9;
                      padding: 30px;
                      cursor: pointer;
                  ">
                  <label :for="'file-upload'+key" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                      <i class="fa fa-upload" aria-hidden="true"></i> 
                      <p style="font-size: 14px">Upload Image</p>
                  </label>
                  <input :id="'file-upload'+key" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event, key )">
              </div>                                                        
          </div>
          <small class="text-danger" v-show="image_size[key]">The image file is too large, allowed maximum size is {{max_file_size}} MB<br/></small>
          <small class="text-danger" v-show="errors.choices[key].file">This field is required</small>
        </div>

        <div class="form-group col-md-8">
          <input type="text" v-model="listOptions[key].title" placeholder="Enter title" class="form-control">
          <small class="text-danger" v-show="errors.choices[key].title">This field is required</small>
        </div>

        <div class="form-group col-md-1">
          <button class="btn btn-danger" @click="rem(key)" type="button"><i class="fa fa-trash"></i></button>
        </div>
      </div>
      <button class="btn btn-info mb-2" @click="add" type="button"><i class="fa fa-plus"></i></button>

      <hr/>
    </form>
</template>

<script>
export default {
    name: 'PageTypeImageOption',
    data() {
      return {
        file: '',
        listOptions: [
          {
            id: this.randomString(),
            file: null,
            title: null
          }
        ],
        model: {
          preTitle: '',
          title: '',
          description: ''
        },
        errors: {
          preTitle: false,
          title: false,
          description: false,
          choices: [
            {
              file: false,
              title: false
            }
          ]
        },
        image_size: [],
        max_file_size: 2
      }
    },
    beforeMount() {
      if ( this.$route.params.id ) {
        let editContent = this.$store.getters.currentEditContent
        this.model = editContent
        this.listOptions = editContent.choices
        this.listOptions.forEach((ele, i) => {
          this.errors.choices[i] = {
            file: false,
            title: false
          }
        })
      }
    },
    methods: {
      submit() {

      },
      handleFileUpload(event, key){
        this.file = event.target.files[0]
        if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
            event.preventDefault();
            this.image_size[key] = true
            this.$forceUpdate()
            return;
        } else {
          this.image_size[key] = false
        }

        let formData = new FormData()
        formData.append('folder', 'contents')
        formData.append('file', this.file)

        this.axios.post('/misc/upload',
            formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }).then(resp => {
                if ( resp.data.success ) {
                  let file = resp.data.data.file
                  this.listOptions[key].file = file
                } else {
                  this.listOptions[key].file = 'https://via.placeholder.com/150?text=dummy+image'
                }

                this.$forceUpdate()
            }).catch(err => {
                console.log(err.response)
            })
      },
      add() {
        this.listOptions.push({
          id: this.randomString(),
          file: null,
          title: ''
        })

        this.errors.choices.push({
          file: false,
          title: false
        })
      },
      rem(key) {
        this.listOptions = this.listOptions.filter( (opt, i) => key != i )
      },
      randomString() {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 8; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
      }
    },
    watch: {
      'model.preTitle': function(newValue) {
        this.$store.dispatch('contentImageOpt', { pageOption: { preTitle: newValue } })
      },
      'model.title': function(newValue) {
        this.$store.dispatch('contentImageOpt', { pageOption: { title: newValue } })
      },
      'model.description': function(newValue) {
        this.$store.dispatch('contentImageOpt', { pageOption: { description: newValue } })
      },
      'listOptions': {
        handler: function(newValue) {
            this.$store.dispatch('contentImageOpt', {
             pageOption: {
              choices: newValue
             }
          })
        },
        deep: true
      }
    },
    mounted() {
      let _ = this
      _.$root.$on('errorListener', (data) => {
        _.errors = data
        _.$forceUpdate();
      })
      _.max_file_size = process.env.VUE_APP_MAX_FILE_SIZE
    }
}
</script>