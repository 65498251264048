import axios from '../axios'

export const getUrlOfFile = (file, user) => {
  const urlImg = user ? 'user-default.jpeg' : 'image-default.png' 
  try {
    return URL.createObjectURL(file)
  } catch (error) {
    return file || `/assets/img/default/${urlImg}`
  }
}

export const truncate = (str, n) => (str ? str.length > n ? `${str.substring(0, n - 1)}...` : str : '-')

export const getImages = (event) => {
  const file = event.target.files[0]
  return file
}

export const strSlug = (string) => {
  var slug = "";
  // Change to lower case
  var titleLower = string.toLowerCase();
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
  // Letter "d"
  slug = slug.replace(/đ/gi, 'd');
  // Trim the last whitespace
  slug = slug.replace(/\s*$/g, '');
  // Change whitespace to "-"
  slug = slug.replace(/\s+/g, '-');
  
  return slug;
}

export const uploadFile = async (file, folder) => {
  if (file) {
    try {
      const form = new FormData()
      form.append('folder', folder)
      form.append('file', file)
      const response = await axios.post('misc/upload', form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
      if (response.status === 200) {
        return response.data.data.file
      }
    } catch (error) {
      return ''
    }
  }
}

export const isFalsyObj = (obj) => {
  const result = Object.values(obj).every(value => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return true;
    }
    return false;
  });

  return result
}

export const convertFiletoURL = async (models, folder) => {
  // eslint-disable-next-line no-undef
  const newModels = structuredClone(models)
  
  const keyIncludes = ['imageurl', 'iconurl', 'bannerurl', 'thumbnailurl']
  for (const key in newModels) {
    if(Array.isArray(newModels[key])) {
      await Promise.all(
        newModels[key].map(async (val, index) => {
          for (const child in val)  { 
            const keyLower = child.toLowerCase()
            const found = keyIncludes.some((item) => keyLower.includes(item))
            if(found) {
              if(newModels[key][index][child] && typeof newModels[key][index][child] !== 'string') {
                newModels[key][index][child] = await uploadFile(newModels[key][index][child], folder)
              }
            }
          }
        })
      ) 
    } else {
      const keyLower = key.toLowerCase()
      const found = keyIncludes.some((item) => keyLower.includes(item))
      if(found) {
        if(newModels[key] && typeof newModels[key] !== 'string') {
          newModels[key] = await uploadFile(newModels[key], folder)
        }
      }
    }
  }
  
  return newModels
}