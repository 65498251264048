<template>
	<div class="card">
		<div class="card-body">
			<h3 class="font-600 mb-2">School Homepage</h3>
			<ValidationObserver class="card-content" ref="observer">
				<form @submit.prevent="save">
          <h5 class="mb-2">Banner Section</h5>
          <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
            <div class="form-group d-flex align-items-center" style="width: 30%;" :class="{ 'validation-warning': errors[0] }">
              <label for="status" class="w-auto mr-1" style="min-width: max-content;">Is Active ?</label>
              <select id="status" class="form-control" v-model="models.isActiveBanner">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
              </select>
              <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="row" v-for="(banner, index) in models.banners" :key="index" >
            <ValidationProvider ref="bannerImageRef" :name="`Image ${index + 1}`" v-slot="{ errors, validate }" class="col-4" rules="image|size:2000|max-width-dimensions:1024">
              <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                <div class="row">
                  <div class="col-12">
                    <div class="position-relative">
                      <img v-if="banner.imageUrl" @click="$refs.imageBanner[index].click()" :src="getUrlOfFile(banner.imageUrl)" class="responsive m-0 cursor-pointer img-fluid aspect-ratio-2/1" />
                      <skeleton-img v-else @click="$refs.imageBanner[index].click()" class="aspect-ratio-2/1"/>

                      <button v-if="banner.imageUrl" @click="banner.imageUrl = $refs.bannerImageRef[index].value = null" class="btn btn-rounded btn-danger position-absolute" 
                          style="top: -10px; right: -5px"
                          title="Delete Image"
                          type="button">
                          <i class="fa fa-times"></i>
                      </button>
                    </div>
                    <input 
                        ref="imageBanner" 
                        type="file" 
                        accept=".png, .jpg, .jpeg" 
                        @change="(value) => {
                            validate(value)
                            .then((resp) => validateImage(resp, value, 'banners', 'imageUrl', index));
                        }"
                        name="faqIcon" 
                        class="form-control d-none">
                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <div class="col">
              <ValidationProvider :name="`Banner URL ${index + 1}`" v-slot="{ errors }" rules="url">
                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                  <label for="bannerUrl" >Banner URL</label>
                  <input type="url" v-model="banner.route" name="bannerUrl" class="form-control" id="bannerUrl" placeholder="Enter banner url">
                  <small class="text-muted" v-if="errors[0]">{{errors[0]}}</small>
                </div>
              </ValidationProvider>
              <div class="row m-0">
                <button v-if="models.banners.length > 1" @click="deleteItem('banners', index)"  type="button" class="btn btn-danger btn-sm mr-1">Remove Content</button>
                <button  v-if="index === models.banners.length - 1" @click="pushItem('banners')"  type="button" class="btn btn-primary btn-sm">Add Content</button>
              </div>
            </div>
          </div>

					<ValidationProvider name="Directory" v-slot="{ errors }" class="col">
            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                <label for="directory">Directory Section</label>
                <multiselect
                    id="directory"
                    v-model="models.schoolDirectoryIds"
                    label="title"
                    track-by="id"
                    placeholder="Search directory"
                    :options="masters.schoolDirectories" 
                    multiple
                    searchable
                    :clear-on-select="false" 
                    :close-on-select="false" 
                    :max-height="600">
                    <span slot="noResult">Oops! There is no school directories.</span>
                </multiselect>
                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
					<div class="">
						<button type="button" @click="resetForm" class="btn text-danger">Cancel</button>
						<button type="submit" class="btn btn-primary " :disabled="isLoading">
							<div v-if="isLoading">Updating<i class="fa fa-spinner fa-spin"></i></div>
							<div v-else>Update</div>
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import {getUrlOfFile, getImages, convertFiletoURL, isFalsyObj} from '@/helper'

export default {
	data(){
		return {
			models: {
        isActiveBanner: true,
        banners: [
          {
            imageUrl: null,
            route: null
          },
        ],
        schoolDirectoryIds: []
			},
      duplicate_models: {},
      skeleton_models: {},
      masters: {
        schoolDirectories: []
      },
			isLoading: false,
			getUrlOfFile,
      getImages
		}
	},
  
  created() {
    this.setInitialValue()
    this.getListOptions()
    this.$nextTick(() => {
      this.get()
    })
  },
  methods: {
    getListOptions(){
			let _ = this
			const getSchoolDir = _.axios.get('otc/school-directory/list?isActive=true')

			Promise.all([getSchoolDir])
				.then(res => {
					const [directory] = res
					this.masters.schoolDirectories = directory.data.data
				})
		},
    async validateImage({valid}, event, type, key, index) {
      if(valid) {
          const file = await getImages(event)
          if(file) {
            this.$set(this.models[type][index], key, file)
          }
          event.target.value = null
      } else {
          event.target.value = null
      }
    },
    pushItem(type) {
      this.models[type].push(JSON.parse(JSON.stringify(this.skeleton_models[type][0])))
    },
    deleteItem(type, index) {
      if(typeof index === 'number') {
        this.models[type].splice(index, 1);
      } else {
        this.models[type].splice(-1);
      }

      if(!this.models[type].length) {
        this.models[type].push(JSON.parse(JSON.stringify(this.skeleton_models[type][0])))
      }
    },
    get() {
      let _ = this
      _.axios.get('otc/school-homepage')
        .then((resp) => {
          const {data} = resp.data

          _.models.isActiveBanner = data.isActiveBanner
          _.models.banners = data.banners.length ? [...data.banners] : [{
            imageUrl: null,
            route: null
          }],
          _.models.schoolDirectoryIds = data.schoolHomepageDirectories.map(dir => {
            delete dir.schoolDirectory.schoolDirectoryCards
            return dir.schoolDirectory
          }).filter(dir => dir.isActive)

          // eslint-disable-next-line no-undef
          _.duplicate_models = structuredClone(_.models)
        })
        .catch(err => {
          _.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message
          })
        })
    },
    modifyOptionsObject(newModels) {
      newModels.schoolDirectoryIds = newModels.schoolDirectoryIds.map(directory => directory.id)

      if(newModels['banners'].length) {
        newModels['banners'] = newModels['banners'].filter(item => {
          if(!isFalsyObj(item)) {
              return item
          }
        })
      }
      return newModels
    },
    async save() {
      let _ = this
      _.isLoading = true
      const isValid = await _.$refs.observer.validate()
      if (isValid){
          const modelsToURL = await convertFiletoURL(this.models, 'school-homepage')
          if(modelsToURL) {
              const newModels = this.modifyOptionsObject(modelsToURL)
              try {
                  const response = await _.axios.post('otc/school-homepage', newModels)
                  if(response.status === 200) {
                    _.get()
                    _.$notify({
                      type: 'success',
                      title: 'Success',
                      text: `School homepage successfully updated`
                    })
                  } 
                  _.isLoading = false
              } catch (err) {
                  _.isLoading = false
                  _.$notify({
                      type: 'error',
                      title: 'Error!',
                      text: err.response.data.message
                  })   
              }
          }
      } else {
          _.isLoading = false
      }
    },
    setInitialValue() {
        let _ = this
        // temporary
        _.duplicate_models = JSON.parse(JSON.stringify(this.models))
        // /////////
        _.skeleton_models = JSON.parse(JSON.stringify(this.models))

    },
    resetForm() {
        let _ = this
        // eslint-disable-next-line no-undef
        _.models = structuredClone(_.duplicate_models)
        window.scrollTo(0,0)
    },
  }
}
</script>