<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title"
                :withPagination="false"
            >
                <template v-slot:header>
                    <div class="row mb-1">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/interim-page/add')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data, index }">
                    <div class="btn-action-block">
                        <router-link :to="'/interim-page/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                        <button type="button" class="btn btn-rounded btn-primary waves-effect waves-light" title="Move up" @click="move('up', data)" :disabled="index === 0">
                            <i class="fa fa-arrow-up"></i>
                        </button>
                        <button type="button" class="btn btn-rounded btn-primary waves-effect waves-light" title="Move down" @click="move('down', data)" :disabled="index + 1 === table.dataTable.lists.rows.length">
                            <i class="fa fa-arrow-down"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Interim Page',
                dataTable: {
                    header: ['#', 'Section Name', 'Condition Detail', 'Action'],
                    columns: ['counter', 'name', 'conditions'],
                    lists: [],
                }
			},
            isLoading: false,
		};
	},
    mounted() {
        this.getList();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getList() {
            this.axios.get('/dc-interim-page?sortBy=updatedAt.DESC')
                .then((resp) => {
                    this.table.dataTable.lists = {
                        count: resp.data.data.length,
                        rows: resp.data.data.map(item => ({
                            ...item,
                            conditions: item.conditions.join('<br />'),
                        })),
                    };
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        destroy(data) {
            this.$swal.fire({
                title: 'Are you sure want to delete this section?',
                text: data.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        this.axios.put('/dc-interim-page', {
                            polyclinicIds: this.table.dataTable.lists.rows
                                .map(item => item.id)
                                .filter(id => id !== data.id)
                        })
                            .then((resp) => {
                                if (resp.data.success) {
                                    this.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    });
                                    this.getList();
                                } else {
                                    this.$notify({
                                        type: 'error',
                                        title: 'Error!',
                                        text: 'Failed to delete',
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err.response);
                            })
                    }
                });
        },
        move(direction, data) {
            const isUp = direction === 'up';
            this.$swal.fire({
                title: `Are you sure want to move this section ${direction}?`,
                text: data.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        const ids = this.table.dataTable.lists.rows.map(item => item.id);
                        const index = ids.findIndex(id => id === data.id);
                        const destinationIndex = isUp ? index - 1 : index + 1;
                        // do swap
                        [ids[index], ids[destinationIndex]] = [ids[destinationIndex], ids[index]];

                        this.axios.put('/dc-interim-page', {
                            polyclinicIds: ids
                        })
                            .then((resp) => {
                                if (resp.data.success) {
                                    this.$swal.fire({
                                        title: 'Updated!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    });
                                    this.getList();
                                } else {
                                    this.$notify({
                                        type: 'error',
                                        title: 'Error!',
                                        text: 'Failed to update',
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err.response);
                            })
                    }
                });
        },
    },
}
</script>