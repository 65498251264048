<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/regimens/add')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Search by keyword...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data, index }">
                    <div class="btn-action-block d-flex justify-content-start align-items-center">
                        <div class="custom-control custom-switch">
                            <input
                                v-model="data.isActive"
                                @change="switchStatus(data.id, data.isActive)" 
                                type="checkbox" 
                                class="custom-control-input" 
                                :id="`customSwitch + ${index}`"
                            >
                            <label class="custom-control-label" :for="`customSwitch + ${index}`"></label>
                        </div>
                        <router-link :to="'/regimens/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button v-if="!data.isQuiz" type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Regimen Library',
                dataTable: {
                    header: ['#', 'Regimen Name', 'Category', 'Selected Flow', 'Action'],
                    columns: ['counter', 'name', 'polyClinic.namePage', 'flow.title'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            debounce: null
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('otc/regimens?page=' + (page ? page : 1) + '&search=' + _.search + '&limit=' + _.limit + '&sortBy=updatedAt.DESC')
                .then((resp)=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete regimen?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                focusCancel: true
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('otc/regimens/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        switchStatus(regimenId, status) {
            let _ = this
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                const statusActivate = status ? 'activate' : 'deactivate'
                _.axios.post(`/otc/regimens/${regimenId}/${statusActivate}`)
                    .then(() => {
                        _.$notify({
                            type: 'success',
                            title: 'Success',
                            text: `Successfully change status regimen`
                        })
                    })
                    .catch(() => {
                        const selectedRow = this.table.dataTable.lists.rows.find(data => data.id === regimenId)
                        if(selectedRow) {
                            _.$swal.fire({
                                title: 'Failed!',
                                text: 'Failed to change status',
                                icon: 'warning',
                                confirmButtonColor: '#f2b430',
                                confirmButtonText: 'OK'
                            })
                            selectedRow.isActive = !selectedRow.isActive
                        }
                    })
            }, 200)
           
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>