<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Polyclinic</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Section Page">
                            <div class="row">
                                <ValidationProvider name="namePage" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Name <span class="alert-required">*</span></label>
                                        <input type="text" name="namePage" v-model="model.namePage" class="form-control" placeholder="Enter Pre Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="startPricePage" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Start Price <span class="alert-required">*</span></label>
                                        <vue-numeric v-model="model.startPricePage" name="startPricePage" @keypress.native="NumbersOnly" class="form-control" placeholder="Enter Start Price" currency="Rp" separator="."></vue-numeric>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="descriptionPage" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description <span class="alert-required">*</span></label>
                                    <quill-editor v-model="model.descriptionPage" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imagePageAlt" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Alt Image</label>
                                    <input type="text" v-model="model.imagePageAlt" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imagePage" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Image <span class="alert-required">*</span></label>
                                    <input type="file" accept=".png, .jpg, .jpeg" @change="storeImagePage" label="Image" name="imagePage" class="form-control">
                                     <input type="hidden" v-model="model.imagePage" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimagePage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimagePage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.imagePage" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Banner">
                            <div class="row">
                                <ValidationProvider name="preTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Pre Title</label>
                                        <input type="text" v-model="model.preTitle" class="form-control" placeholder="Enter Pre Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Button Consult Text" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Consult Text</label>
                                        <input type="text" v-model="model.bannerButtonConsultText" class="form-control" placeholder="Enter Button Text">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="title" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Title</label>
                                    <quill-editor v-model="model.title" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="description" v-slot="{ errors }">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <quill-editor v-model="model.description" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="bannerColor" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Background Header Color</label>
                                    <sketch-picker v-model="model.bannerColor" @input="updateColor"></sketch-picker>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Alt Image</label>
                                    <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="image" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Image</label>
                                    <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Image">
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.image" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Condition">
                            <ValidationProvider name="sectionCondition" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionCondition">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="conditionTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Condition Title</label>
                                        <input type="text" v-model="model.conditionTitle" class="form-control" placeholder="Enter Pre Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="conditionDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Condition Description</label>
                                        <quill-editor v-model="model.conditionDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="conditionId" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="descriptionInput">Conditions</label>
                                        <multiselect 
                                            v-model="model.conditionId"
                                            label="namePage"
                                            name="conditionpages"
                                            track-by="id" 
                                            placeholder="Search Condition"
                                            :options="conditionpages" 
                                            :multiple="true" 
                                            :searchable="true" 
                                            :clear-on-select="false" 
                                            :close-on-select="false" 
                                            :max-height="600">
                                            <span slot="noResult">Oops! There is no condition.</span>
                                        </multiselect>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Info">
                            <section>
                                <ValidationProvider name="sectionInfo" v-slot="{ errors }">
                                    <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                        <label>Is Active ?</label>
                                        <select class="form-control" v-model="model.sectionInfo">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <ValidationProvider name="info1Title" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title 1</label>
                                        <input type="text" v-model="model.info1Title" class="form-control" placeholder="Enter Title">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="info1Description" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description 1</label>
                                        <quill-editor v-model="model.info1Description" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="info2Title" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title 2</label>
                                        <input type="text" v-model="model.info2Title" class="form-control" placeholder="Enter Title">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="info2Description" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description 2</label>
                                        <quill-editor v-model="model.info2Description" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Product">
                            <ValidationProvider name="sectionProduct" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionProduct">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="productTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.productTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="productPrice" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Price</label>
                                        <vue-numeric v-model="model.productPrice" name="productPrice" @keypress.native="NumbersOnly" class="form-control" placeholder="Enter Start Price" currency="Rp" separator="."></vue-numeric>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>  
                            <div class="row">
                                <ValidationProvider name="productDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <quill-editor v-model="model.productDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="productId" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Special Product</label>
                                        <select name="productId" v-model="model.productId" class="form-control">
                                            <option value='null'>Select Special Product</option>
                                            <option v-for="(data,index) in special_product" :key="index" :value="data.id">
                                                {{data.namePage}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="productImageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.productImageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="productImage" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeProductImage" label="Image" name="productImage" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" v-model="model.productImage" class="form-control" placeholder="Enter Image">
                            </div>
                            <div class="row">
                                <ValidationProvider class="col">
                                    <div class="form-group" v-if="model.thumbnailproductImage">
                                        <label>Preview Image</label>
                                        <img :src="model.thumbnailproductImage" class="responsive img-thumbnail" />
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Preview Image</label>
                                        <img :src="model.productImage" class="responsive img-thumbnail" />
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Step Consul">
                            <ValidationProvider name="sectionStepConsul" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionStepConsul">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="stepConsulTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.stepConsulTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="stepConsulDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <quill-editor v-model="model.stepConsulDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="stepConsulImageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.stepConsulImageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="stepConsulImage" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeStepConsulImage" label="Image" name="stepConsulImage" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider class="col">
                                    <div class="form-group" v-if="model.thumbnailstepConsulImage">
                                        <label>Preview Image</label>
                                        <img :src="model.thumbnailstepConsulImage" class="responsive img-thumbnail" />
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Preview Image</label>
                                        <img :src="model.stepConsulImage" class="responsive img-thumbnail" />
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr/>
                            <h5 class="font-600 mb-2">Section Step Consul</h5>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="stepConsul1Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 1</label>
                                            <input type="text" v-model="model.stepConsul1Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="stepConsul1Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 1</label>
                                            <quill-editor v-model="model.stepConsul1Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="stepConsul2Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 2</label>
                                            <input type="text" v-model="model.stepConsul2Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="stepConsul2Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 2</label>
                                            <quill-editor v-model="model.stepConsul2Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="stepConsul3Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 3</label>
                                            <input type="text" v-model="model.stepConsul3Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="stepConsul3Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 2</label>
                                            <quill-editor v-model="model.stepConsul3Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                        </app-collapse-item> 
                        <app-collapse-item title="Section Testimony">
                            <ValidationProvider name="Section Testimony Activation" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionBeforeAfter">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="beforeAfterTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.beforeAfterTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="beforeAfterDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.beforeAfterDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Clinical Team">
                            <ValidationProvider name="sectionDoctor" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionDoctor">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="doctorTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.doctorTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="doctorDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <quill-editor v-model="model.doctorDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="doctorId" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Clinical Team</label>
                                    <multiselect 
                                        v-model="model.doctorId"
                                        label="name"
                                        name="doctors"
                                        track-by="id" 
                                        placeholder="Search Doctor"
                                        :options="doctors" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no doctor.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Article">
                            <ValidationProvider name="sectionArticle" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionArticle">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="articleTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.articleTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="articleDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <quill-editor v-model="model.articleDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="articleId" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Articles</label>
                                    <multiselect 
                                        v-model="model.articleId"
                                        label="title"
                                        name="articles"
                                        track-by="id" 
                                        placeholder="Search Article"
                                        :options="article" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no article.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Ingredients">
                            <ValidationProvider name="sectionMaterial" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionMaterial">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="materialTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.materialTitle" class="form-control" placeholder="Enter Title">      
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="materialDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <quill-editor v-model="model.materialDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="model.materialDescription">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <h5 class="font-600 mb-2">Child Section Ingredients</h5>
                            <section>
                                <span v-for="(page, idx) in model.materials" :key="idx">
                                    <div class="row mt-3">
                                        <ValidationProvider name="title" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Title Ingredients {{idx+1}}</label>
                                                <input type="text" v-model="model.materials[idx].title" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="description" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Description Ingredients {{idx+1}}</label>
                                                <quill-editor v-model="model.materials[idx].description" :options="editorOption"></quill-editor>
                                                <small class="text-muted">if more than 1 add a comma ex: title 1, title 2</small>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Alt Image Ingredients {{idx+1}}</label>
                                                <input type="text" v-model="model.materials[idx].imageAlt" class="form-control" placeholder="Enter Alt Image">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="imageLink" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Image Ingredients {{idx+1}}</label>
                                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageMaterial($event, idx)" label="Image" name="imageLink" class="form-control">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <input type="hidden" v-model="model.materials[idx].image" class="form-control" placeholder="Enter Alt Image">
                                    </div>
                                    <div class="row">
                                        <ValidationProvider class="col">
                                            <div class="form-group" v-if="model.materials[idx].thumbnailimage">
                                                <label>Preview Image Ingredients {{idx+1}}</label>
                                                <img :src="model.materials[idx].thumbnailimage" class="responsive img-thumbnail" />
                                            </div>
                                            <div class="form-group" v-else>
                                                <label>Preview Image Ingredients {{idx+1}}</label>
                                                <img :src="model.materials[idx].image" class="responsive img-thumbnail" />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <button type="button" class="btn btn-danger mr-2" @click="deleteMaterial(idx)" v-if="model.materials.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                                </span>
                                <button type="button" class="btn btn-primary" @click="addMaterial()"><i class="fa fa-plus"></i> Add Content</button>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section F.A.Q">
                            <section>
                                <ValidationProvider name="sectionFaq" v-slot="{ errors }" rules="required">
                                    <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                        <label>Is Active ? <span class="alert-required">*</span></label>
                                        <select class="form-control" v-model="model.sectionFaq">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <ValidationProvider name="faqTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title F.A.Q</label>
                                        <input type="text" v-model="model.faqTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="faqSubtitle" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Subtitle F.A.Q</label>
                                        <textarea name="" id="" cols="30" rows="3" v-model="model.faqSubtitle" class="form-control" placeholder="Enter Subtitle"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="row">
                                    <div class="form-group col">
                                        <label for="descriptionInput">FAQ</label>
                                        <multiselect
                                            v-model="model.faqs"
                                            label="question"
                                            name="faqs"
                                            track-by="id"
                                            placeholder="Search FAQ"
                                            :options="faqs"
                                            :multiple="true"
                                            :searchable="true"
                                            :clear-on-select="false"
                                            :close-on-select="false"
                                            :max-height="600"
                                        >
                                            <span slot="noResult">Oops! There is no FAQs.</span>
                                        </multiselect>
                                    </div>
                                </div>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Button Sitemap">
                            <div class="row">
                                <ValidationProvider name="buttonConsult1" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 1</label>
                                        <select name="buttonConsult1" id="" v-model="model.buttonConsult1" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="buttonConsult2" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 2</label>
                                        <select name="buttonConsult2" id="" v-model="model.buttonConsult2" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="buttonConsultBox" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Konsultasi 3</label>
                                        <select name="buttonConsultBox" id="" v-model="model.buttonConsultBox" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                        </app-collapse-item>
                        <app-collapse-item title="Section Catalog">
                            <ValidationProvider name="sectionCatalog" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionCatalog">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="catalogTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.catalogTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="catalogDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <quill-editor v-model="model.catalogDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="catalogProductId" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Catalog Product</label>
                                    <multiselect 
                                        v-model="model.catalogProductId"
                                        label="title"
                                        name="catalog"
                                        track-by="id" 
                                        placeholder="Search Catalog Product"
                                        :options="catalog" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no catalog product.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section SEO" :isVisible="false">
                            <div class="row">
                                <ValidationProvider name="Page Title" v-slot="{ errors }" class="col" rules="required|min:30|max:200">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="metaTitle">Page Title <span class="alert-required">*</span></label>
                                        <textarea rows="5" class="form-control" v-model="model.metaTitle" id="metaTitle" placeholder="Min 30 chars, max 200 chars"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Meta Description" v-slot="{ errors }" class="col" rules="required|min:100|max:200">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="metaDescription">Meta Description <span class="alert-required">*</span></label>
                                        <textarea rows="5" class="form-control" v-model="model.metaDescription" id="metaDescription" placeholder="Min 100 chars, max 200 chars"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                        </app-collapse-item>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <router-link to="/polyclinic-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { replaceAllEnterWithSpace } from '@/helper/string';
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            model: {
                namePage: '',
                descriptionPage: '',
                imagePage: '',
                thumbnailimagePage: '',
                imagePageAlt: '',
                startPricePage: '',
                preTitle: '',
                title: '',
                description: '',
                bannerColor: '',
                image: '',
                imageAlt: '',
                thumbnailimage: '',
                sectionCondition: true,
                conditionTitle: '',
                conditionDescription: '',
                conditionId: [],
                sectionInfo: true,
                buttonConsultBox: '',
                info1Title: '',
                info1Description: '',
                info2Title: '',
                info2Description: '',
                sectionProduct: true,
                productTitle: '',
                productDescription: '',
                productPrice: '',
                productImage: '',
                productId: '',
                thumbnailproductImage: '',
                productImageAlt: '',
                stepCompositionProd: true,
                compositionProdTitle: '',
                compositionProdDescription: '',
                sectionStepConsul: true,
                stepConsulImage: '',
                thumbnailstepConsulImage: '',
                stepConsulImageAlt: '',
                stepConsulTitle: '',
                stepConsulDescription: '',
                stepConsul1Title: '',
                stepConsul1Description: '',
                stepConsul2Title: '',
                stepConsul2Description: '',
                stepConsul3Title: '',
                stepConsul3Description: '',
                sectionBeforeAfter: true,
                beforeAfterTitle: '',
                beforeAfterDescription: '',
                sectionDoctor: true,
                doctorTitle: '',
                doctorDescription: '',
                doctorId: [],
                sectionArticle: true,
                articleTitle: '',
                articleDescription: '',
                articleId: [],
                buttonConsult1: '',
                buttonConsult2: '',
                buttonConsult3: '',
                sectionCatalog: true,
                catalogTitle: '',
                catalogDescription: '',
                catalogProductId: [],
                sectionFaq: true,
                faqTitle: '',
                faqSubtitle: '',
                faqs: [
                    {
                        question: null,
                        answer: null,
                    }
                ],
                sectionMaterial: true,
                materialTitle: '',
                materialDescription: '',
                isActive: true,
                materials: [
                    {
                        title: null,
                        description: null,
                        image: null,
                        imageLink: null,
                        thumbnailimage: null,
                        imageAlt: null

                    }
                ],
            },
            article: [],
            conditionpages: [],
            doctors: [],
            special_product: [],
            buttons: [],
            catalog: [],
            conditions: [],
            faqs: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        addMaterial() {
            let _ = this
            _.model.materials.push({
                title: null,
                description: null,
                image: null,
                imageAlt: null
            })
        },
        deleteMaterial(x) {
            let _ = this
            _.model.materials.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        get() {
            let _ = this
            _.axios.get('/dc-poly/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.model.conditionId = _.model.conditionpages
                    _.model.doctorId = _.model.doctors
                    _.model.articleId = _.model.article
                    _.model.catalogProductId =_.model.catalog
                    _.model.productId = _.model.special_product.id
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getConditions() {
           let _ = this
            _.axios.get('/dc-condition-page/list-all')
            .then(resp=>{
                _.conditionpages = resp.data.data
            }) 
        },
        getDoctors() {
           let _ = this
            _.axios.get('/dc-doctor/list-all')
            .then(resp=>{
                _.doctors = resp.data.data
            }) 
        },
        getArticles() {
           let _ = this
            _.axios.get('/dc-articles/list-all')
            .then(resp=>{
                _.article = resp.data.data
            }) 
        },
        getProducts() {
           let _ = this
            _.axios.get('/dc-special-page/list-all')
            .then(resp=>{
                _.special_product = resp.data.data
            }) 
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        getCatalogProduct() {
           let _ = this
            _.axios.get('/misc/products')
            .then(resp=>{
                _.catalog = resp.data.data
            }) 
        },
        getFaqs() {
            this.axios.get('/dc-master-faq/list-all')
                .then(res => {
                    this.faqs = res.data.data;
                });
        },
        storeImagePage(event){
            this.model.imagePage = event.target.files[0];
            this.$set(this.model, 'thumbnailimagePage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeProductImage(event){
            this.model.productImage = event.target.files[0];
            this.$set(this.model, 'thumbnailproductImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeStepConsulImage(event){
            this.model.stepConsulImage = event.target.files[0];
            this.$set(this.model, 'thumbnailstepConsulImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageMaterial(event, idx){
            this.model.materials[idx].imageLink = event.target.files[0];
            this.$set(this.model.materials[idx], 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "materials");
            formData.append("file", this.model.materials[idx].imageLink);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.materials[idx].image = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("namePage", _.model.namePage);
                formData.append("descriptionPage", _.model.descriptionPage);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("imagePage", _.model.imagePage);
                formData.append("imagePageAlt", _.model.imagePageAlt);
                formData.append("startPricePage", _.model.startPricePage);
                formData.append("preTitle", _.model.preTitle);
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("image", _.model.image);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("sectionCondition", _.model.sectionCondition);
                formData.append("conditionTitle", _.model.conditionTitle);
                formData.append("conditionDescription", _.model.conditionDescription);
                formData.append("conditionId", JSON.stringify(_.model.conditionId.map(p => p.id)));
                formData.append("sectionInfo", _.model.sectionInfo);
                formData.append("info1Title", _.model.info1Title);
                formData.append("info1Description", _.model.info1Description);
                formData.append("info2Title", _.model.info2Title);
                formData.append("info2Description", _.model.info2Description);
                formData.append("sectionProduct", _.model.sectionProduct);
                formData.append("productTitle", _.model.productTitle);
                formData.append("productDescription", _.model.productDescription);
                formData.append("productPrice", _.model.productPrice);
                formData.append("productImage", _.model.productImage);
                formData.append("productId", _.model.productId);
                formData.append("productImageAlt", _.model.productImageAlt);
                formData.append("compositionProdTitle", _.model.compositionProdTitle);
                formData.append("compositionProdDescription", _.model.compositionProdDescription);
                formData.append("sectionStepConsul", _.model.sectionStepConsul);
                formData.append("stepConsulImage", _.model.stepConsulImage);
                formData.append("stepConsulImageAlt", _.model.stepConsulImageAlt);
                formData.append("stepConsulTitle", _.model.stepConsulTitle);
                formData.append("stepConsulDescription", _.model.stepConsulDescription);
                formData.append("stepConsul1Title", _.model.stepConsul1Title);
                formData.append("stepConsul1Description", _.model.stepConsul1Description);
                formData.append("stepConsul2Title", _.model.stepConsul2Title);
                formData.append("stepConsul2Description", _.model.stepConsul2Description);
                formData.append("stepConsul3Title", _.model.stepConsul3Title);
                formData.append("stepConsul3Description", _.model.stepConsul3Description);
                formData.append("sectionBeforeAfter", _.model.sectionBeforeAfter);
                formData.append("beforeAfterTitle", _.model.beforeAfterTitle);
                formData.append("beforeAfterDescription", _.model.beforeAfterDescription);
                formData.append("sectionDoctor", _.model.sectionDoctor);
                formData.append("doctorTitle", _.model.doctorTitle);
                formData.append("doctorDescription", _.model.doctorDescription);
                formData.append("doctorId", JSON.stringify(_.model.doctorId.map(p => p.id)));
                formData.append("sectionArticle", _.model.sectionArticle);
                formData.append("articleTitle", _.model.articleTitle);
                formData.append("articleDescription", _.model.articleDescription);
                formData.append("articleId", JSON.stringify(_.model.articleId.map(p => p.id)));
                formData.append("sectionFaq", _.model.sectionFaq);
                formData.append("faqTitle", _.model.faqTitle);
                formData.append("faqSubtitle", _.model.faqSubtitle);
                formData.append("faqIds", JSON.stringify(_.model.faqs.map(faq => faq.id)));
                formData.append("sectionMaterial", _.model.sectionMaterial);
                formData.append("materialTitle", _.model.materialTitle);
                formData.append("materialDescription", _.model.materialDescription);
                formData.append("materials", JSON.stringify(_.model.materials));
                formData.append("buttonConsult1", _.model.buttonConsult1);
                formData.append("buttonConsult2", _.model.buttonConsult2);
                formData.append("buttonConsultBox", _.model.buttonConsultBox);
                formData.append("sectionCatalog", _.model.sectionCatalog);
                formData.append("catalogTitle", _.model.catalogTitle);
                formData.append("catalogDescription", _.model.catalogDescription);
                formData.append("catalogProductId", JSON.stringify(_.model.catalogProductId.map(p => p.id)));
                formData.append("bannerButtonConsultText", _.model.bannerButtonConsultText);
                formData.append("metaTitle", replaceAllEnterWithSpace(_.model.metaTitle));
                formData.append("metaDescription", replaceAllEnterWithSpace(_.model.metaDescription));
                if (!_.$route.params.id) {
                    _.axios.post('/dc-poly', formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/polyclinic-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })     
                } else {
                    _.axios.put('/dc-poly/' + _.$route.params.id, formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/polyclinic-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })   
                }  
            }
        },
        NumbersOnly(evt) {
          evt = (evt) ? evt : window.event
          var charCode = (evt.which) ? evt.which : evt.keyCode
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault()
          } else {
            return true
          }
        }        
    },
    mounted() {
        if (this.$route.params.id) {
            this.get();
        }
        this.getConditions();
        this.getDoctors();
        this.getArticles();
        this.getProducts();
        this.getButtons();
        this.getCatalogProduct();
        this.getFaqs();
    }
}
</script>