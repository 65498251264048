<template>
	<div class="card">
		<div class="card-body">
			<h3 class="font-600 mb-2">{{id ? 'Edit' : 'Add'}} Treatment</h3>
			<ValidationObserver class="card-content" ref="observer">
				<form @submit.prevent="submit">
					<ValidationProvider name="title" v-slot="{ errors }" rules="required">
						<div class="form-group" :class="{ 'validation-warning': errors[0] }">
							<label for="" class="required">Title</label>
							<input type="text" v-model.lazy="model.title" name="title" class="form-control" id="titleInput" placeholder="Enter title">
							<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
						</div>
					</ValidationProvider>
					<ValidationProvider name="description" v-slot="{ errors }" rules="required">
						<div class="form-group" :class="{ 'validation-warning': errors[0] }">
							<label for="" class="required">Description</label>
							<textarea class="form-control" v-model="model.description" name="description" id="descriptionInput" placeholder="Enter description"></textarea>
							<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
						</div>
					</ValidationProvider>
					<div class="form-row" v-for="(item, kim) in model.items" :key="kim">
						<ValidationProvider :name="'product ' + (kim+1)" v-slot="{ errors }" rules="required" class="form-group col-md-6">
							<div :class="{ 'validation-warning': errors[0] }">
								<label for="" class="required">Product</label>
								<multiselect 
									v-model="selectedProduct[kim]"
									label="title"
									track-by="id" 
									placeholder="Choose Product"
									:options="products" 
									:allow-empty="false"
									@input="model.items[kim].productId = selectedProduct[kim].id">
									<span slot="noResult">Oops! Data not found.</span>
								</multiselect>
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider :name="'maximum order quantity ' + (kim+1)" v-slot="{ errors }" rules="required" class="form-group col-md-5">
							<div :class="{ 'validation-warning': errors[0] }">
								<label for="quantityProduct" class="required">Maximum Order Quantity</label>
								<input v-model="model.items[kim].quantity" type="number" class="form-control" placeholder="Enter quantity">
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<div class="form-group">
							<label class="pb-2">&nbsp;</label>
							<button @click="removeItem(kim)" type="button" class="btn btn-danger"><i class="fa fa-trash"></i></button>
						</div>
					</div>
					<button @click="addItem" type="button" class="btn btn-info"><i class="fa fa-plus"></i></button>
					<hr>
					<div class="">
						<router-link to="/treatments" class="btn text-danger">Cancel</router-link>
						<button type="submit" class="btn btn-primary " :disabled="isLoading">
							<div v-if="isLoading">{{id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
							<div v-else>{{id ? 'Update' : 'Create'}}</div>
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			id: this.$route.params.id,
			model: {
				title: "",
				description: "",
				items: [
					{
						productId: "",
						quantity: ""
					}
				]
			},
			products: [],
			isLoading: false,
			selectedProduct: []
		}
	},
	beforeMount() {
		if (this.id) {
			this.axios.get('/treatments/' + this.id)
				.then(resp => {
					let data = resp.data.data
					this.model.title = data.title
					this.model.description = data.description
					this.model.items = data.products.map(p => {
						return {
							productId: p.treatment_product.productId,
							quantity: p.treatment_product.quantity
						}
					})

					this.selectedProduct = data.products
				})
				.catch(err => console.log(err.response))
		}
    },
	mounted() {
		this.getListProduct()
	},
	methods: {
		async submit() {
			let _ = this
			_.isLoading = true
            const isValid = await _.$refs.observer.validate()
            if ( isValid ) {
            	let model = {
					title: this.model.title,
					description: this.model.description,
					items: this.model.items
				}

				if ( this.id ) {
					this.axios.put('/treatments/' +this.id, model)
						.then((resp) => {
							_.$notify({
								type: 'success',
								title: 'Success!',
								text: resp.data.message
							})

				            this.$router.push('/treatments')
							_.isLoading = false
						})
						.catch(err => {
							console.log(err.response)
							_.isLoading = false
						})
				} else {
					this.axios.post('/treatments', model)
						.then((resp) => {
							_.$notify({
								type: 'success',
								title: 'Success!',
								text: resp.data.message
							})

				            this.$router.push('/treatments')
							_.isLoading = false
						})
						.catch(err => {
							console.log(err.response)
							_.isLoading = false
						})
				}
            } else {
				_.isLoading = false
			}
		},
		getListProduct() {
			this.axios.get('products?limit=500')
				.then(resp => {
					this.products = resp.data.data.rows
				})
				.catch(err => {
					console.log(err.response)
				})
		},
		addItem() {
			this.model.items.push({
				productId: "",
				quantity: ""
			})
		},
		removeItem(k) {
			this.model.items = [...this.model.items.slice(0, k), ...this.model.items.slice(k + 1)];
			this.selectedProduct = [...this.selectedProduct.slice(0, k), ...this.selectedProduct.slice(k + 1)];
		}
	}
}
</script>