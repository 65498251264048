<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} FAQ Category</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <div class="text-danger">
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="name" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Category <span class="alert-required">*</span></label>
                                <input type="text" v-model="model.name" class="form-control" placeholder="Enter Category">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <hr>
                    <div class="text-left mt-1">
                        <router-link to="/faq-category" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>
                                {{ $route.params.id ? 'Update' : 'Create' }}
                            </div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            model: {
                name: '',
            },
            isLoading: false,
            errors: null,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-category-faq/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("name", _.model.name);
                if (!_.$route.params.id) {
                    _.axios.post('/dc-category-faq', formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.errors = null
                            _.$router.push('/faq-category')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.isLoading = false
                        })     
                } 
                else {
                    _.axios.put('/dc-category-faq/' + _.$route.params.id, formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.errors = null
                            _.$router.push('/faq-category')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.isLoading = false
                        })   
                }  
            }
        },
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>