<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">About Us</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Section Banner">
                            <div class="row">
                                <ValidationProvider name="title" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required">*</span></label>
                                        <quill-editor v-model="model.title" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="description" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description <span class="alert-required">*</span></label>
                                    <textarea name="" id="" cols="30" rows="5" v-model="model.description" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="bannerColor" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Background Header Color</label>
                                    <sketch-picker v-model="model.bannerColor" @input="updateColor"></sketch-picker>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Alt Image</label>
                                    <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="image" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Image <span class="alert-required">*</span></label>
                                    <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                                    <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.image" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Body">
                            <div class="row">
                                <ValidationProvider name="bodyTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.bodyTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="bodyDesc1" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description 1</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.bodyDesc1" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="bodyDesc2" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description 2</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.bodyDesc2" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="bodyImageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.bodyImageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="bodyImage" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storebodyImage" label="Image" name="bodyImage" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" v-model="model.bodyImage" class="form-control" placeholder="Enter Alt Image">
                            </div>
                            <div class="row">
                                <ValidationProvider class="col">
                                    <div class="form-group" v-if="model.thumbnailbodyImage">
                                        <label>Preview Image</label>
                                        <img :src="model.thumbnailbodyImage" class="responsive img-thumbnail" />
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Preview Image</label>
                                        <img :src="model.bodyImage" class="responsive img-thumbnail" />
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="visionTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Vision Title</label>
                                        <input type="text" v-model="model.visionTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="visionDesc" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Vision Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.visionDesc" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="missionTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Mission Title</label>
                                        <input type="text" v-model="model.missionTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="missionDesc" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Mission Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.missionDesc" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="goalTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Goal Title</label>
                                        <input type="text" v-model="model.goalTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="goalDesc" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Goal Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.goalDesc" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Consultation">
                            <ValidationProvider name="sectionConsultation" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionConsultation">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="consultationTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.consultationTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="consultationDesc" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea name="" id="" cols="30" rows="5" v-model="model.consultationDesc" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <div class="row">
                                <ValidationProvider name="consultationImageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.consultationImageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="consultationImage" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeconsultationImage" label="Image" name="consultationImage" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" v-model="model.consultationImage" class="form-control" placeholder="Enter Alt Image">
                            </div>
                            <div class="row">
                                <ValidationProvider class="col">
                                    <div class="form-group" v-if="model.thumbnailconsultationImage">
                                        <label>Preview Image</label>
                                        <img :src="model.thumbnailconsultationImage" class="responsive img-thumbnail" />
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Preview Image</label>
                                        <img :src="model.consultationImage" class="responsive img-thumbnail" />
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item> 
                        <app-collapse-item title="Section Button Sitemap">
                            <div class="row">
                                <ValidationProvider name="buttonConsult1" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 1 <span class="alert-required">*</span></label>
                                        <select name="buttonConsult1" id="" v-model="model.buttonConsult1" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                        </app-collapse-item>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            model: {
                title: '',
                description: '',
                bannerColor: '',
                image: '',
                thumbnailimage: '',
                imageAlt: '',
                bodyImage: '',
                thumbnailbodyImage: '',
                bodyImageAlt: '',
                bodyTitle: '',
                bodyDesc1: '',
                bodyDesc2: '',
                visionTitle: '',
                visionDesc: '',
                missionTitle: '',
                missionDesc: '',
                goalTitle: '',
                goalDesc: '',
                sectionConsultation: true,
                consultationTitle: '',
                consultationDesc: '',
                consultationImage: '',
                thumbnailconsultationImage: '',
                consultationImageAlt: '',
                buttonConsult1: '',
            },
            buttons: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-about-us')
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storebodyImage(event){
            this.model.bodyImage = event.target.files[0];
            this.$set(this.model, 'thumbnailbodyImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeconsultationImage(event){
            this.model.consultationImage = event.target.files[0];
            this.$set(this.model, 'thumbnailconsultationImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("image", _.model.image);
                formData.append("bodyImage", _.model.bodyImage);
                formData.append("bodyImageAlt", _.model.bodyImageAlt);
                formData.append("bodyTitle", _.model.bodyTitle);
                formData.append("bodyDesc1", _.model.bodyDesc1);
                formData.append("bodyDesc2", _.model.bodyDesc2);
                formData.append("visionTitle", _.model.visionTitle);
                formData.append("visionDesc", _.model.visionDesc);
                formData.append("missionTitle", _.model.missionTitle);
                formData.append("missionDesc", _.model.missionDesc);
                formData.append("goalTitle", _.model.goalTitle);
                formData.append("goalDesc", _.model.goalDesc);
                formData.append("sectionConsultation", _.model.sectionConsultation);
                formData.append("consultationTitle", _.model.consultationTitle);
                formData.append("consultationDesc", _.model.consultationDesc);
                formData.append("consultationImage", _.model.consultationImage);
                formData.append("consultationImageAlt", _.model.consultationImageAlt);
                formData.append("buttonConsult1", _.model.buttonConsult1);
                _.axios.put('/dc-about-us', formData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.success = resp.data.success
                    _.$router.push('/about-us')
                    setTimeout(function() {
                        location.reload();
                    }, 3000);
                })
                .catch(err => {
                    _.errors = err.response.data.message
                    _.message_error = err.response.data.message
                    _.success = err.response.data.success
                    _.isLoading = false
                })
            }
        },
    },
    mounted() {
        let _ = this
        _.get()
        _.getButtons()
    }
}
</script>