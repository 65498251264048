<template>
  <div @click="onClick" class="skeleton-wrapper">
    <i class="fa fa-image fa-2x"></i>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style>
  .skeleton-wrapper {
    width: 100%;
    background-color: #E3E8ED;
    cursor: pointer;

    display: grid;
    place-items: center;
  }

</style>