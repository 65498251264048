<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/article-library/add')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Search...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>    
                    <div class="card mb-4">
                        <label class="vs-input--label">Filter</label>
                        <div class="row mt-1">
                            <div class="col-6">
                                <multiselect 
                                    v-model="model.categoryArticle"
                                    label="name"
                                    name="categoryArticle"
                                    track-by="id" 
                                    placeholder="Search Category"
                                    :options="categories"
                                    class="single-select"
                                    :allow-empty="false"
                                    :max-height="600">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                            </div>
                            <div class="col-6">
                                <multiselect 
                                    v-model="model.tagArticle"
                                    label="name"
                                    name="tags"
                                    track-by="id" 
                                    placeholder="Search Tag"
                                    :options="tags"
                                    class="single-select"
                                    :allow-empty="false"
                                    :max-height="600">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                            </div>
                        </div>
                        <br />
                        <div class="btn-filter">
                            &nbsp;
                            <button class="btn btn-primary" @click="getFilter" >Cari</button>
                            &nbsp;
                            <button class="btn text-danger btn-outline-primary" @click="resetFilter" >Reset</button>
                        </div>
                    </div>            
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index" :class="data == 'Image' ? 'text-center' : ''">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <router-link :to="'/article-library/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Article Library',
                dataTable: {
                    header: ['#', 'Title', 'Category', 'Tags', 'Last Update', 'Action'],
                    columns: ['counter', 'title', 'category.name', 'tags', 'updatedAt'],
                    lists: [],
                }
			},
            model: {
                categoryArticle: '',
                tagArticle: ''
            },
            filter: '',
            search: '',
            categories: [],
            tags: [],
            limit: 10,
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getCategory()
        _.getTag()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getList(page) {
            let _ = this
            _.axios.get('/dc-articles?page=' + (page ? page : 1) + '&sortBy=updatedAt.DESC' + '&search=' + _.search + '&limit=' + _.limit)
            .then(resp=>{
                _.table.dataTable.lists = resp.data.data
            })
            .catch(err=>{
                console.log(err.response)
            })
        },
        getFilter() {
            let _ = this;
            console.log("tag :", _.model.tagArticle)
            console.log("cat :", _.model.categoryArticle)
            if (_.model.categoryArticle == '' && _.model.tagArticle != '') {
                _.axios.get('/dc-articles?page=1' + '&sortBy=updatedAt.DESC' + '&search=' + _.search + '&tagArticle=' + 'like:%'+_.model.tagArticle.id+'%' + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
            } else if (_.model.tagArticle == '' && _.model.categoryArticle != '') {
                _.axios.get('/dc-articles?page=1' + '&sortBy=updatedAt.DESC' + '&search=' + _.search + '&categoryArticle=' +_.model.categoryArticle.id + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
            } else if (_.model.categoryArticle == '' && _.model.tagArticle == '' ) {
                _.axios.get('/dc-articles?page=1' + '&sortBy=updatedAt.DESC' + '&search=' + _.search + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
            } else {
                _.axios.get('/dc-articles?page=1' + '&sortBy=updatedAt.DESC' + '&search=' + _.search + '&tagArticle=' + 'like:%'+_.model.tagArticle.id+'%' + '&categoryArticle=' + _.model.categoryArticle.id + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
            }
        },
        resetFilter() {
            let _ = this;
            _.model.tagArticle = '';
            _.model.categoryArticle = '';
            _.getList();
        },
        getCategory() {
            let _ = this
            _.axios.get('/dc-category-articles/list-all')
            .then(resp=>{
                _.categories = resp.data.data
            })
        },
        getTag() {
            let _ = this
            _.axios.get('/dc-tag-articles/list-all')
            .then(resp=>{
                _.tags = resp.data.data
            })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Article?',
                text: x.fullName,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/dc-articles/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>