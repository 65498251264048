<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Special Product Page</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Section Page">
                            <div class="row">
                                <ValidationProvider name="namePage" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Name <span class="alert-required">*</span></label>
                                        <input type="text" name="namePage" v-model="model.namePage" class="form-control" placeholder="Enter Pre Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="imagePageAlt" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Alt Image</label>
                                    <input type="text" v-model="model.imagePageAlt" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imagePage" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Image <span class="alert-required">*</span></label>
                                    <input type="file" accept=".png, .jpg, .jpeg" @change="storeImagePage" label="Image" name="imagePage" class="form-control">
                                    <input type="hidden" v-model="model.imagePage" name="imagePage" class="form-control">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimagePage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimagePage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.imagePage" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Banner">
                            <div class="row">
                                <ValidationProvider name="title" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required">*</span></label>
                                        <quill-editor v-model="model.title" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="description" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description <span class="alert-required">*</span></label>
                                    <textarea name="" id="" cols="30" rows="5" v-model="model.description" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="bannerColor" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Background Header Color</label>
                                    <sketch-picker v-model="model.bannerColor" @input="updateColor"></sketch-picker>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <div class="row">
                                <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="image" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image <span class="alert-required">*</span></label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                                        <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.image" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Ingredient">
                            <ValidationProvider name="sectionIngredients" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionIngredients">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="ingredientsTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.ingredientsTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="ingredientsDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.ingredientsDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="ingredientsImageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.ingredientsImageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="ingredientsImage" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeIngredientImage" label="Image" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" v-model="model.ingredientsImage" label="Image" name="ingredientsImage" class="form-control">
                            </div>
                            <div class="row">
                                <ValidationProvider class="col">
                                    <div class="form-group" v-if="model.thumbnailingredientsImage">
                                        <label>Preview Image</label>
                                        <div class="close-preview" v-if="model.thumbnailingredientsImage !=''">
                                            <button type="button" @click="deleteIngredientImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                        </div>
                                        <img :src="model.thumbnailingredientsImage" class="responsive img-thumbnail" />
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Preview Image</label>
                                        <div class="close-preview" v-if="model.ingredientsImage !=''">
                                            <button type="button" @click="deleteIngredientImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                        </div>
                                        <img :src="model.ingredientsImage" class="responsive img-thumbnail" />
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr/>
                            <h5 class="font-600 mb-2">Section Ingredients 1</h5>
                            <section>
                                <ValidationProvider name="ingredients1Title" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title 1</label>
                                        <input type="text" v-model="model.ingredients1Title" class="form-control" placeholder="Enter Title 1">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="ingredients1Description" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description 1</label>
                                        <quill-editor v-model="model.ingredients1Description" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="ingredients1Icon" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeIngredient1Icon" label="Image" name="ingredients1Icon" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" v-model="model.ingredients1Icon" name="ingredients1Icon" class="form-control">
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailingredients1Icon">
                                            <label>Preview Image</label>
                                            <div class="close-preview" v-if="model.thumbnailingredients1Icon !=''">
                                                <button type="button" @click="deleteIngredient1Icon" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                            </div>
                                            <img :src="model.thumbnailingredients1Icon" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <div class="close-preview" v-if="model.ingredients1Icon !=''">
                                                <button type="button" @click="deleteIngredient1Icon" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                            </div>
                                            <img :src="model.ingredients1Icon" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <ValidationProvider name="ingredients2Title" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title 2</label>
                                        <input type="text" v-model="model.ingredients2Title" class="form-control" placeholder="Enter Title 2">
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="ingredients2Description" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description 2</label>
                                        <quill-editor v-model="model.ingredients2Description" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="ingredients2Icon" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeIngredient2Icon" label="Image" name="ingredients2Icon" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" v-model="model.ingredients2Icon" name="ingredients1Icon" class="form-control">
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailingredients2Icon">
                                            <label>Preview Image</label>
                                            <div class="close-preview" v-if="model.thumbnailingredients2Icon !=''">
                                                <button type="button" @click="deleteIngredient2Icon" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                            </div>
                                            <img :src="model.thumbnailingredients2Icon" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <div class="close-preview" v-if="model.ingredients2Icon !=''">
                                                <button type="button" @click="deleteIngredient2Icon" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                            </div>
                                            <img :src="model.ingredients2Icon" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr>
                            <h5 class="font-600 mb-2">Section Ingredients 2</h5>
                            <section>
                                    <ValidationProvider name="ingDetail1preTitle" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Pre Title 1</label>
                                            <input type="text" v-model="model.ingDetail1preTitle" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                <div class="row">
                                    <ValidationProvider name="ingDetail1Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 1</label>
                                            <quill-editor v-model="model.ingDetail1Title" placeholder="Enter Title" :options="editorOption"></quill-editor>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="ingDetail1Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 1</label>
                                            <quill-editor v-model="model.ingDetail1Description" placeholder="Enter Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="ingDetail1ImageAlt" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Alt Image</label>
                                            <input type="text" v-model="model.ingDetail1ImageAlt" class="form-control" placeholder="Enter Alt Image">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="ingDetail1Image" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Image</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeingDetail1Image" label="Image" name="ingDetail1Image" class="form-control">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            
                                        </div>
                                    </ValidationProvider>
                                    <input type="hidden" v-model="model.ingDetail1Image" name="ingDetail1Image" class="form-control">
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailingDetail1Image">
                                            <label>Preview Image</label>
                                            <img :src="model.thumbnailingDetail1Image" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <img :src="model.ingDetail1Image" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr>
                            <section>
                                    <ValidationProvider name="ingDetail2preTitle" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Pre Title 2</label>
                                            <input type="text" v-model="model.ingDetail2preTitle" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                <div class="row">
                                    <ValidationProvider name="ingDetail2Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 2</label>
                                            <quill-editor v-model="model.ingDetail2Title" placeholder="Enter Title" :options="editorOption"></quill-editor>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="ingDetail2Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 2</label>
                                            <quill-editor v-model="model.ingDetail2Description" placeholder="Enter Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="ingDetail2ImageAlt" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Alt Image</label>
                                            <input type="text" v-model="model.ingDetail2ImageAlt" class="form-control" placeholder="Enter Alt Image">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="ingDetail2Image" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Image</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeingDetail2Image" label="Image" name="ingDetail2Image" class="form-control">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            
                                        </div>
                                    </ValidationProvider>
                                    <input type="hidden" v-model="model.ingDetail2Image" name="ingDetail2Image" class="form-control">
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailingDetail2Image">
                                            <label>Preview Image</label>
                                            <img :src="model.thumbnailingDetail2Image" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <img :src="model.ingDetail2Image" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Content Ingredient">
                            <ValidationProvider name="sectionContentIngredients" v-slot="{ errors }" rules="required">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionContentIngredients">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="contentIngredientTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.contentIngredientTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="contentIngredientDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.contentIngredientDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                            <h5 class="font-600 mb-2">Section Content Ingredient</h5>
                            <section>
                                <span v-for="(page, idx) in model.content_ingredients" :key="idx">
                                    <div class="row mt-3">
                                        <ValidationProvider name="title" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Title</label>
                                                <input type="text" v-model="model.content_ingredients[idx].title" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="subTitle" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Subtitle</label>
                                                <input type="text" v-model="model.content_ingredients[idx].subTitle" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="target" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Target</label>
                                                <input type="text" v-model="model.content_ingredients[idx].target" class="form-control" placeholder="Add a comma if the input is more than 1. Ex: Sample1, Sample2">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="benefit" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Benefit</label>
                                                <input type="text" v-model="model.content_ingredients[idx].benefit" class="form-control" placeholder="Add a comma if the input is more than 1. Ex: Sample1, Sample2">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="icon" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Icon</label>
                                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeIconContentIngredient($event, idx)" label="Icon" name="icon" class="form-control">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                
                                            </div>
                                        </ValidationProvider>
                                        <input type="hidden" v-model="model.content_ingredients[idx].icon" class="form-control" placeholder="Enter Alt Image">
                                    </div>
                                    <div class="row">
                                        <ValidationProvider class="col">
                                            <div class="form-group" v-if="model.content_ingredients[idx].thumbnailicon">
                                                <label>Preview Icon</label>
                                                <div class="close-preview" v-if="model.content_ingredients[idx].thumbnailicon">
                                                    <button type="button" @click="deleteIconContentIngredient($event, idx)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <img :src="model.content_ingredients[idx].thumbnailicon" class="responsive img-thumbnail" />
                                            </div>
                                            <div class="form-group" v-else>
                                                <label>Preview Icon</label>
                                                <div class="close-preview" v-if="model.content_ingredients[idx].icon">
                                                    <button type="button" @click="deleteIconContentIngredient($event, idx)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <img :src="model.content_ingredients[idx].icon" class="responsive img-thumbnail" />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Alt Image</label>
                                                <input type="text" v-model="model.content_ingredients[idx].imageAlt" class="form-control" placeholder="Enter Alt Image">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="imageLink" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Image</label>
                                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageContentIngredient($event, idx)" label="Image" name="imageLink" class="form-control">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                
                                            </div>
                                        </ValidationProvider>
                                        <input type="hidden" v-model="model.content_ingredients[idx].image" class="form-control" placeholder="Enter Alt Image">
                                    </div>
                                    <div class="row">
                                        <ValidationProvider class="col">
                                            <div class="form-group" v-if="model.content_ingredients[idx].thumbnailimage">
                                                <label>Preview Image</label>
                                                <div class="close-preview" v-if="model.content_ingredients[idx].thumbnailimage">
                                                    <button type="button" @click="deleteImageContentIngredient($event, idx)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <img :src="model.content_ingredients[idx].thumbnailimage" class="responsive img-thumbnail" />
                                            </div>
                                            <div class="form-group" v-else>
                                                <label>Preview Image</label>
                                                <div class="close-preview" v-if="model.content_ingredients[idx].image">
                                                    <button type="button" @click="deleteImageContentIngredient($event, idx)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <img :src="model.content_ingredients[idx].image" class="responsive img-thumbnail" />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <button type="button" class="btn btn-danger mr-2" @click="deleteContentIngredient(idx)" v-if="model.content_ingredients.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                                </span>
                                <button type="button" class="btn btn-primary" @click="addContentIngredient()"><i class="fa fa-plus"></i> Add Content</button>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Consultation">
                            <ValidationProvider name="sectionConsultation" v-slot="{ errors }" rules="required">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionConsultation">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="consultationTitle" v-slot="{ errors }" :rules="model.sectionConsultation == true ? 'required': ''" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required" v-show="model.sectionConsultation == true">*</span></label>
                                        <input type="text" v-model="model.consultationTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="consultationDescription" v-slot="{ errors }" :rules="model.sectionConsultation == true ? 'required': ''" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description <span class="alert-required" v-show="model.sectionConsultation == true">*</span></label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.consultationDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="consultationImageAlt" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alt Image</label>
                                        <input type="text" v-model="model.consultationImageAlt" class="form-control" placeholder="Enter Alt Image">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="consultationImage" v-slot="{ errors }" :rules="model.sectionConsultation == true ? 'required': ''" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Image <span class="alert-required" v-show="model.sectionConsultation == true">*</span></label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storeconsultationImage" label="Image" name="consultationImage" class="form-control">
                                        <input type="hidden" v-model="model.consultationImage" label="Image" name="consultationImage" class="form-control">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        
                                    </div>
                                </ValidationProvider>
                            </div>
                            <input type="hidden" v-model="model.consultationImage" class="form-control" placeholder="Enter Image">
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailconsultationImage">
                                    <label>Preview Image</label>
                                    <div class="close-preview" v-if="model.thumbnailconsultationImage !=''">
                                        <button type="button" @click="deleteconsultationImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                    </div>
                                    <img :src="model.thumbnailconsultationImage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <div class="close-preview" v-if="model.consultationImage !=''">
                                        <button type="button" @click="deleteconsultationImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                    </div>
                                    <img :src="model.consultationImage" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Testimonials">
                            <ValidationProvider name="sectionTestimoni" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionTestimoni">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="testimoniTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.testimoniTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="testimoniDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.testimoniDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <h5 class="font-600 mb-2">Section Testimonial</h5>
                            <section>
                                <span v-for="(page, idx) in model.testimoni" :key="idx">
                                    <div class="row mt-3">
                                        <ValidationProvider name="jobTitle" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Job Title</label>
                                                <input type="text" v-model="model.testimoni[idx].jobTitle" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="name" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Name</label>
                                                <input type="text" v-model="model.testimoni[idx].name" class="form-control" placeholder="Enter Name">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="note" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Note</label>
                                                <textarea name="" id="" cols="30" rows="5" v-model="model.testimoni[idx].note" class="form-control"></textarea>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Alt Image</label>
                                                <input type="text" v-model="model.testimoni[idx].imageAlt" class="form-control" placeholder="Enter Alt Image">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="imageLink" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Image</label>
                                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageTestimoni($event, idx)" label="Image" name="imageLink" class="form-control">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <input type="hidden" v-model="model.testimoni[idx].image" class="form-control" placeholder="Enter Alt Image">
                                    </div>
                                    <div class="row">
                                        <ValidationProvider class="col">
                                            <div class="form-group" v-if="model.testimoni[idx].thumbnailimageLink">
                                                <label>Preview Image</label>
                                                <div class="close-preview" v-if="model.testimoni[idx].thumbnailimageLink">
                                                    <button type="button" @click="deleteImageTestimoni($event, idx)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <img v-if="model.testimoni[idx].thumbnailimageLink != ''" :src="model.testimoni[idx].thumbnailimageLink" class="responsive img-thumbnail" />
                                            </div>
                                            <div class="form-group" v-else>
                                                <label>Preview Image</label>
                                                <div class="close-preview" v-if="model.testimoni[idx].image">
                                                    <button type="button" @click="deleteImageTestimoni($event, idx)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <img v-if="model.testimoni[idx].image != ''" :src="model.testimoni[idx].image" class="responsive img-thumbnail" />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <button type="button" class="btn btn-danger mr-2" @click="deleteTesti(idx)" v-if="model.testimoni.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                                </span>
                                <button type="button" class="btn btn-primary" @click="addTesti()"><i class="fa fa-plus"></i> Add Content</button>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Articles">
                            <ValidationProvider name="sectionArticle" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionArticle">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="articleTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.articleTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="articleDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.articleDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="articleId" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Articles</label>
                                    <multiselect 
                                        v-model="model.articleId"
                                        label="title"
                                        name="articles"
                                        track-by="id" 
                                        placeholder="Search Article"
                                        :options="article" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no article.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section F.A.Q">
                            <section>
                                <ValidationProvider name="sectionFaq" v-slot="{ errors }" rules="required">
                                    <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                        <label>Is Active ?</label>
                                        <select class="form-control" v-model="model.sectionFaq">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <ValidationProvider name="faqTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title F.A.Q</label>
                                        <input type="text" v-model="model.faqTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="faqSubtitle" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Subtitle F.A.Q</label>
                                        <textarea name="" id="" cols="30" rows="3" v-model="model.faqSubtitle" class="form-control" placeholder="Enter Subtitle"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <span v-for="(page, idx) in model.faqs" :key="idx">
                                    <div class="row mt-2">
                                        <ValidationProvider name="question" v-slot="{ errors }" :rules="model.sectionFaq == true ? 'required': ''" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Question <span class="alert-required" v-show="model.sectionFaq == true">*</span></label>
                                                <input type="text" v-model="model.faqs[idx].question" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="answer" v-slot="{ errors }" :rules="model.sectionFaq == true ? 'required': ''" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Answer <span class="alert-required" v-show="model.sectionFaq == true">*</span></label>
                                                <input type="text" v-model="model.faqs[idx].answer" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <button type="button" class="btn btn-danger mr-2" @click="deleteFaq(idx)" v-if="model.faqs.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                                </span>
                                <button type="button" class="btn btn-primary" @click="addFaq()"><i class="fa fa-plus"></i> Add Content</button>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Button Sitemap">
                            <div class="row">
                                <ValidationProvider name="buttonConsult1" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 1 <span class="alert-required">*</span></label>
                                        <select name="buttonConsult1" v-model="model.buttonConsult1" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="buttonConsult2" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 2 <span class="alert-required">*</span></label>
                                        <select name="buttonConsult2" v-model="model.buttonConsult2" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="buttonConsult3" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 3<span class="alert-required">*</span></label>
                                        <select name="buttonConsult3" v-model="model.buttonConsult3" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="buttonConsult4" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 4<span class="alert-required">*</span></label>
                                        <select name="buttonConsult4" id="" v-model="model.buttonConsult4" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                        </app-collapse-item>
                        <app-collapse-item title="Section SEO" :isVisible="false">
                            <div class="row">
                                <ValidationProvider name="Page Title" v-slot="{ errors }" class="col" rules="required|min:30|max:200">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="metaTitle">Page Title <span class="alert-required">*</span></label>
                                        <textarea rows="5" class="form-control" v-model="model.metaTitle" id="metaTitle" placeholder="Min 30 chars, max 200 chars"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Meta Description" v-slot="{ errors }" class="col" rules="required|min:100|max:200">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="metaDescription">Meta Description <span class="alert-required">*</span></label>
                                        <textarea rows="5" class="form-control" v-model="model.metaDescription" id="metaDescription" placeholder="Min 100 chars, max 200 chars"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                        </app-collapse-item>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <router-link to="/special-product-page" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { replaceAllEnterWithSpace } from '@/helper/string';
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            model: {
                namePage:'',
                imagePage: '',
                thumbnailimagePage: '',
                imagePageAlt: '',
                title: '',
                description: '',
                bannerColor: '',
                image: '',
                thumbnailimage: '',
                imageAlt: '',
                sectionIngredients: true,
                ingredientsTitle: '',
                ingredientsImage: '',
                thumbnailingredientsImage: '',
                ingredientsImageAlt: '',
                ingredientsDescription: '',
                ingredients1Title: '',
                ingredients1Description: '',
                ingredients1Icon: '',
                thumbnailingredients1Icon: '',
                ingredients2Title: '',
                ingredients2Description: '',
                ingredients2Icon: '',
                thumbnailingredients2Icon: '',
                ingDetail1preTitle: '',
                ingDetail1Title: '',
                ingDetail1Image: '',
                thumbnailingDetail1Image: '',
                ingDetail1ImageAlt: '', 
                ingDetail1Description: '',
                ingDetail2preTitle: '',
                ingDetail2Title: '',
                ingDetail2Image: '',
                thumbnailingDetail2Image: '', 
                ingDetail2ImageAlt: '',
                ingDetail2Description: '',
                sectionArticle: true,
                articleTitle: '',
                articleDescription: '',
                articleId: [],
                sectionTestimoni: true,
                testimoniTitle: '',
                testimoniDescription: '',
                sectionFaq: true,
                sectionContentIngredients: true,
                contentIngredientTitle: '',
                contentIngredientDescription: '',
                sectionConsultation: true,
                consultationTitle: '',
                consultationDescription: '',
                consultationImage: '',
                thumbnailconsultationImage: '',
                consultationImageAlt: '',
                buttonConsult1: '',
                buttonConsult2: '',
                buttonConsult3: '',
                buttonConsult4: '',
                faqTitle: '',
                faqSubtitle: '',
                content_ingredients: [          
                    {
                        title: null,
                        subTitle: null,
                        icon: null,
                        iconLink: null,
                        thumbnailicon: null,
                        imageLink: null,
                        image: null,
                        thumbnailimage: null,
                        imageAlt : null,
                        target: null,
                        benefit: null
                    },
                ],
                testimoni: [
                    {
                        name: null,
                        jobTitle: null,
                        imageAlt: null,
                        image: null,
                        thumbnailimageLink: null,
                        imageLink: null,
                        note: null
                    }
                ],
                faqs: [
                    {
                        question: null,
                        answer: null,
                    }
                ],
            },
            article: [],
            buttons: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        addTesti() {
            let _ = this
            _.model.testimoni.push({
                name: null,
                jobTitle: null,
                imageAlt: null,
                image: null,
                note: null
            })
        },
        deleteTesti(x) {
            let _ = this
            _.model.testimoni.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        addFaq() {
            let _ = this
            _.model.faqs.push({
                question: null,
                answer: null,
            })
        },
        deleteFaq(x) {
            let _ = this
            _.model.faqs.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        addContentIngredient() {
            let _ = this
            _.model.content_ingredients.push({
                title: null,
                subTitle: null,
                icon: null,
                thumbnailicon: null,
                image: null,
                thumbnailimage: null,
                imageAlt : null,
                target: null,
                benefit: null
            })
        },
        deleteContentIngredient(x) {
            let _ = this
            _.model.content_ingredients.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        get() {
            let _ = this
            _.axios.get('/dc-special-page/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.model.articleId = _.model.article
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getArticles() {
           let _ = this
            _.axios.get('/dc-articles/list-all')
            .then(resp=>{
                _.article = resp.data.data
            }) 
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        storeImagePage(event){
            this.model.imagePage = event.target.files[0];
            this.$set(this.model, 'thumbnailimagePage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeIngredientImage(event){
            this.model.ingredientsImage = event.target.files[0];
            this.$set(this.model, 'thumbnailingredientsImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deleteIngredientImage(){
            this.model.ingredientsImage = '';
            this.model.thumbnailingredientsImage = '';
            this.success=true;
            this.message_error=null;
        },
        storeIngredient1Icon(event){
            this.model.ingredients1Icon = event.target.files[0];
            this.$set(this.model, 'thumbnailingredients1Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deleteIngredient1Icon(){
            this.model.ingredients1Icon = '';
            this.model.thumbnailingredients1Icon = '';
            this.success=true;
            this.message_error=null;
        },
        storeIngredient2Icon(event){
            this.model.ingredients2Icon = event.target.files[0];
            this.$set(this.model, 'thumbnailingredients2Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deleteIngredient2Icon(){
            this.model.ingredients2Icon = '';
            this.model.thumbnailingredients2Icon = '';
            this.success=true;
            this.message_error=null;
        },
        storeingDetail1Image(event){
            this.model.ingDetail1Image = event.target.files[0];
            this.$set(this.model, 'thumbnailingDetail1Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deleteingDetail1Image(){
            this.model.ingDetail1Image = '';
            this.model.thumbnailingDetail1Image = '';
            this.success=true;
            this.message_error=null;
        },
        storeingDetail2Image(event){
            this.model.ingDetail2Image = event.target.files[0];
            this.$set(this.model, 'thumbnailingDetail2Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deleteingDetail2Image(){
            this.model.ingDetail2Image = '';
            this.model.thumbnailingDetail2Image = '';
            this.success=true;
            this.message_error=null;
        },
        storeconsultationImage(event){
            this.model.consultationImage = event.target.files[0];
            this.$set(this.model, 'thumbnailconsultationImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deleteconsultationImage(){
            this.model.consultationImage = '';
            this.model.thumbnailconsultationImage = '';
            this.success=true;
            this.message_error=null;
        },
        storeImageTestimoni(event, idx){
            this.model.testimoni[idx].imageLink = event.target.files[0];
            this.$set(this.model.testimoni[idx], 'thumbnailimageLink', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "testimoni");
            formData.append("file", this.model.testimoni[idx].imageLink);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.testimoni[idx].image = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        deleteImageTestimoni(event, idx){
            this.model.testimoni[idx].imageLink = '';
            this.model.testimoni[idx].thumbnailimageLink = '';
            this.model.testimoni[idx].image = '';
            this.success=true;
            this.message_error=null;
        },
        storeIconContentIngredient(event, idx){
            this.model.content_ingredients[idx].iconLink = event.target.files[0];
            this.$set(this.model.content_ingredients[idx], 'thumbnailicon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "content_ingredients");
            formData.append("file", this.model.content_ingredients[idx].iconLink);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.content_ingredients[idx].icon = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        deleteIconContentIngredient(event, idx){
            this.model.content_ingredients[idx].iconLink = '';
            this.model.content_ingredients[idx].thumbnailicon = '';
            this.model.content_ingredients[idx].icon = '';
            this.success=true;
            this.message_error=null;
        },
        storeImageContentIngredient(event, idx){
            this.model.content_ingredients[idx].imageLink = event.target.files[0];
            this.$set(this.model.content_ingredients[idx], 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "content_ingredients");
            formData.append("file", this.model.content_ingredients[idx].imageLink);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.content_ingredients[idx].image = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        deleteImageContentIngredient(event, idx){
            this.model.content_ingredients[idx].imageLink = '';
            this.model.content_ingredients[idx].thumbnailimage = '';
            this.model.content_ingredients[idx].image = '';
            this.success=true;
            this.message_error=null;
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("namePage", _.model.namePage);
                formData.append("imagePage", _.model.imagePage);
                formData.append("imagePageAlt", _.model.imagePageAlt);
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("image", _.model.image);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("sectionIngredients", _.model.sectionIngredients);
                formData.append("ingredientsTitle", _.model.ingredientsTitle);
                formData.append("ingredientsImage", _.model.ingredientsImage);
                formData.append("ingredientsImageAlt", _.model.ingredientsImageAlt);
                formData.append("ingredientsDescription", _.model.ingredientsDescription);
                formData.append("ingredients1Title", _.model.ingredients1Title);
                formData.append("ingredients1Description", _.model.ingredients1Description);
                formData.append("ingredients1Icon", _.model.ingredients1Icon);
                formData.append("ingredients2Title", _.model.ingredients2Title);
                formData.append("ingredients2Description", _.model.ingredients2Description);
                formData.append("ingredients2Icon", _.model.ingredients2Icon);
                formData.append("ingDetail1preTitle", _.model.ingDetail1preTitle);
                formData.append("ingDetail1Title", _.model.ingDetail1Title);
                formData.append("ingDetail1Image", _.model.ingDetail1Image);
                formData.append("ingDetail1ImageAlt", _.model.ingDetail1ImageAlt); 
                formData.append("ingDetail1Description", _.model.ingDetail1Description);
                formData.append("ingDetail2preTitle", _.model.ingDetail2preTitle);
                formData.append("ingDetail2Title", _.model.ingDetail2Title);
                formData.append("ingDetail2Image", _.model.ingDetail2Image);
                formData.append("ingDetail2ImageAlt", _.model.ingDetail2ImageAlt);
                formData.append("ingDetail2Description", _.model.ingDetail2Description);
                formData.append("sectionTestimoni", _.model.sectionTestimoni);
                formData.append("testimoniTitle", _.model.testimoniTitle);
                formData.append("testimoniDescription", _.model.testimoniDescription);
                formData.append("testimoni", JSON.stringify(_.model.testimoni));
                formData.append("sectionContentIngredients", _.model.sectionContentIngredients);
                formData.append("contentIngredientTitle", _.model.contentIngredientTitle);
                formData.append("contentIngredientDescription", _.model.contentIngredientDescription);
                formData.append("content_ingredients", JSON.stringify(_.model.content_ingredients));
                formData.append("sectionConsultation", _.model.sectionConsultation);
                formData.append("consultationTitle", _.model.consultationTitle);
                formData.append("consultationDescription", _.model.consultationDescription);
                formData.append("consultationImage", _.model.consultationImage);
                formData.append("consultationImageAlt", _.model.consultationImageAlt);
                formData.append("sectionArticle", _.model.sectionArticle);
                formData.append("articleTitle", _.model.articleTitle);
                formData.append("articleDescription", _.model.articleDescription);
                formData.append("articleId", JSON.stringify(_.model.articleId.map(p => p.id)));
                formData.append("sectionFaq", _.model.sectionFaq);
                formData.append("faqTitle", _.model.faqTitle);
                formData.append("faqSubtitle", _.model.faqSubtitle);
                formData.append("faqs", JSON.stringify(_.model.faqs));
                formData.append("buttonConsult1", _.model.buttonConsult1);
                formData.append("buttonConsult2", _.model.buttonConsult2);
                formData.append("buttonConsult3", _.model.buttonConsult3);
                formData.append("buttonConsult4", _.model.buttonConsult4);
                formData.append("metaTitle", replaceAllEnterWithSpace(_.model.metaTitle));
                formData.append("metaDescription", replaceAllEnterWithSpace(_.model.metaDescription));
                if (!_.$route.params.id) {
                    _.axios.post('/dc-special-page', formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/special-product-page')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })     
                } else {
                    _.axios.put('/dc-special-page/' + _.$route.params.id, formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/special-product-page')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })   
                }  
            }
        }
        
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.getArticles()
        _.getButtons()
    }
}
</script>