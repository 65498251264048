<template>
    <section>
        <div class="card">
            <div class="card-body">
                <Table 
                    :dataTable="table.dataTable"
                    :title="table.title"
                    :showAction="model.type !== 'Display'"
                >
                    <template v-slot:header>
                        <div class="row">
                            <div class="col-lg-8 col-xs-12">
                                <div class="btn-responsive" v-if="model.type !== 'Display'">
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalForm" @click="reset_model()">
                                        <i class="fa fa-plus"></i> Add
                                    </button>
                                </div>
                            </div>
                            <div class="col mb-1">
                                <div class="input-group form-search-rounded">
                                    <input class="form-control" v-model="search" type="search"
                                        placeholder="Search by keyword...">
                                    <span class="input-group-append">
                                        <div class="input-group-text bg-transparent">
                                            <i class="fa fa-search"></i>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>                
                    </template>

                    <template v-slot:thead>
                        <thead>
                            <tr>
                                <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:action="{ data }">
                        <div class="btn-action-block">
                            <switches @input="show_hide(data)" :emit-on-mount="false"
                                v-model="data.show" 
                                :label="data.show ? 'Show' : 'Hidden'"
                                :color="data.show ? 'green' : 'red'"
                                class="mr-1">
                            </switches>

                            <button type="button" style="vertical-align: bottom" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit" data-toggle="modal" data-target="#modalForm" @click="form = JSON.parse(JSON.stringify(data))">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" style="vertical-align: bottom" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </template> 
                </Table>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalForm" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver class="card-content" ref="observer">
                        <form @submit.prevent="store()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title">{{ form.id ? 'Edit' : 'Add' }} Rating</h5>
                            </div>
                            <div class="modal-body">
                                <ValidationProvider name="Avatar" v-slot="{ errors }">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <div v-if="form.avatar" class="text-center profile-avatar">
                                            <img :src="form.avatar" class="img-fluid rounded mt-1 mr-1 pointer" 
                                                alt="" style="width: 150px; height: 150px;"><br>
                                            <div class="edit-avatar">
                                                <button 
                                                    @click="form.avatar = null"
                                                    class="btn btn-rounded btn-danger" 
                                                    title="Delete"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div style="
                                                    text-align: center; 
                                                    border-radius: 10px; 
                                                    background: #E5E7E9;
                                                    padding-top: 25px;
                                                    padding-bottom: 25px;
                                                    cursor: pointer;
                                                    width: 150px;
                                                    height: 150px;
                                                " class="mr-1 mt-1 mb-1">
                                                <label for="file-upload" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                                                    <i class="fa fa-upload" aria-hidden="true"></i> 
                                                    <p style="font-size: 14px">Upload Avatar</p>
                                                </label>
                                                <input id="file-upload" class="input-file-avatar d-none" type="file" accept="image/*" @change="upload( $event )">
                                            </div>
                                            <small class="text-danger" v-show="image_size">The image file is too large, <br>allowed maximum size is {{max_file_size}}MB</small>
                                        </div>
                                        <input type="hidden" v-model="form.avatar">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Date" v-slot="{ errors }" rules="required">
                                    <div class="form-group">
                                        <label for="">Date</label>
                                        <date-picker 
                                            v-model="form.date"
                                            type="date" 
                                            value-type="YYYY-MM-DD" 
                                            placeholder="Choose date"
                                            class="w-100"
                                            :disabled-date="disabledEndDate">
                                        </date-picker>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Username" v-slot="{ errors }" rules="required">
                                    <div class="form-group">
                                        <label for="">Username</label>
                                        <input type="text" class="form-control" v-model="form.name" placeholder="Enter Username">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Rating" v-slot="{ errors }" rules="required">
                                    <div class="form-group">
                                        <label for="">Rating</label>
                                        <star-rating v-model="form.rating" :increment="1"></star-rating>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="form-group mb-0">
                                    <label for="">Review</label>
                                    <textarea v-model="form.review" id="" cols="30" rows="5" class="form-control" placeholder="Enter Review"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer p-2" style="justify-content: left;">
                                <button type="button" id="closeModalForm" class="btn text-primary waves-effect waves-light" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                                    <div v-if="isLoading">{{ form.id ? 'Updating' : 'Update' }} <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>{{ form.id ? 'Update' : 'Create' }}</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce"
import $ from 'jquery'
import StarRating from 'vue-star-rating'
import Switches from 'vue-switches';

export default {
	components: {
        Table,
        StarRating,
        Switches
	},
	data() {
		return {
			table: {
                title: '',
                dataTable: {
                    header: ['#', 'Date', 'Name', 'Rating', 'Review', 'Action'],
                    columns: ['counter', 'date', 'name', 'rating', 'review'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            model: {},
            form: {
                productId: this.$route.params.id,
                name: null,
                avatar: null,
                rating: null,
                review: null,
                date: null,
            },
            image_size: false,
            max_file_size: 0,
		}
	},
    mounted() {
        let _ = this
        _.show()
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
        _.max_file_size = process.env.VUE_APP_MAX_FILE_SIZE
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        disabledEndDate(date) {
		    return this.$moment() && date.setHours(0, 0, 0, 0) > new Date(this.$moment()).setHours(0, 0, 0, 0);
		},
        show() {
           let _ = this
            _.axios.get('/products/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.table.title = 'Rating: ' + _.model.title
                    if (resp.data.data.type === 'Display') {
                        _.table.dataTable.header = ['#', 'Date', 'Name', 'Rating', 'Review'];
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getList(page) {
            let _ = this
            _.axios.get('/ratings?productId='+ _.$route.params.id +'&page=' + (page ? page : 1) +'&limit=' + _.limit + '&sortBy=updatedAt.desc' + '&search=' + (_.search ? _.search : ''))
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Rating?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                focusCancel: true
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/ratings/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.form.id) {
                    _.update()
                    return
                }

                _.axios.post('/ratings', _.form)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.reset_model()
                        _.hide_modal()
                        _.getList()
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            let data = {
                ..._.form
            }

            delete data.id
            delete data.createdAt
            delete data.updatedAt

            _.axios.put('/ratings/' + _.form.id, data)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.reset_model()
                    _.hide_modal()
                    _.getList()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]

            if (file.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size = true
                return;
            } else {
                this.image_size = false
            }

            let formData = new FormData()
            formData.append('folder', 'rating')
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.form.avatar = resp.data.data.file
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        hide_modal() {
            $('#closeModalForm').click();
        },
        reset_model() {
            this.form = {
                productId: this.$route.params.id,
                name: null,
                avatar: null,
                rating: null,
                review: null,
                date: null,
            }
            this.$refs.observer.reset()
        },
        show_hide( data )
        {
            let _ = this
            _.axios.put('/ratings/' + data.id + '/show')
                .then(resp => {
                    console.log(resp.data)
                    // _.getList()
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>