<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} FAQ Library</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <div class="text-danger">
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="question" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Question <span class="alert-required">*</span></label>
                            <input type="text" v-model="model.question" class="form-control" placeholder="Enter Question">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="answer" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label>Answer <span class="alert-required">*</span></label>
                            <quill-editor v-model="model.answer" placeholder="Enter Answer" :options="editorOption"></quill-editor>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="categoryFaqId" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Category Faq <span class="alert-required">*</span></label>
                                <multiselect 
                                    v-model="model.categoryFaqId"
                                    label="name"
                                    name="categoryFaqId"
                                    track-by="id" 
                                    placeholder="Search Category Faq"
                                    :options="categories"
                                    class="single-select"
                                    :max-height="600">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="tagFaqId" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="descriptionInput">Tag Faq <span class="alert-required">*</span></label>
                                <multiselect 
                                    v-model="model.tagFaqId"
                                    label="name"
                                    name="tags"
                                    track-by="id" 
                                    placeholder="Search Tag FAQ"
                                    :options="tags" 
                                    :multiple="true" 
                                    :searchable="true" 
                                    :clear-on-select="false"
                                    :max-height="600">
                                    <span slot="noResult">Oops! There is no tag faq.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <hr>
                    <div class="text-left mt-1">
                        <router-link to="/faq-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    data() {
        return {
            model: {
                answer: '',
                question: '',
                categoryFaqId: '',
                tagFaqId: [],
            },
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            categories: [],
            tags: [],
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-master-faq/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    if (_.model.tagFaqId) {
                        _.model.categoryFaqId = _.model.category
                        _.model.tagFaqId = _.model.tags
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("answer", _.model.answer);
                formData.append("question", _.model.question);
                formData.append("categoryFaqId", _.model.categoryFaqId.id);
                formData.append("tagFaqId", JSON.stringify(_.model.tagFaqId.map(p => p.id)));
                // let model = {
				// 	answer: this.model.answer,
				// 	question: this.model.question,
                //     categoryFaqId: this.model.categoryFaqId,
				// 	tagFaqId: this.model.tagFaqId.map(p => p.id),
				// }
                if (!_.$route.params.id) {
                    _.axios.post('/dc-master-faq', formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/faq-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.isLoading = false
                        })    
                } 
                else {
                    _.axios.put('/dc-master-faq/' + _.$route.params.id, formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/faq-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.isLoading = false
                        })   
                }  
            }
        },
        getCategory() {
            let _ = this
            _.axios.get('/dc-category-faq/list-all')
            .then(resp=>{
                _.categories = resp.data.data
            })
        },
        getTag() {
            let _ = this
            _.axios.get('/dc-tag-faq/list-all')
            .then(resp=>{
                _.tags = resp.data.data
            })
        }
        
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.getCategory()
        _.getTag()
    }
}
</script>