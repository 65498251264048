<template>
    <div class="card">
        <div class="card-body">
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="description" v-slot="{ errors }" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Description</label>
                            <textarea name="" id="" cols="30" rows="5" v-model="model.description" class="form-control"></textarea>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">    
                        <ValidationProvider name="supportedImageAlt" v-slot="{ errors }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Alt Image</label>
                                <input type="text" v-model="model.supportedImageAlt" class="form-control" placeholder="Enter Alt Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                    </div>
                    <ValidationProvider name="supportedImage" v-slot="{ errors }" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Image</label>
                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="supportedImage" class="form-control">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <input type="hidden" v-model="model.supportedImage" class="form-control" placeholder="Enter Alt Image">
                    <ValidationProvider class="col">
                        <div class="form-group" v-if="model.thumbnailsupportedImage">
                            <label>Preview Image</label>
                            <div class="close-preview" v-if="model.thumbnailsupportedImage !=''">
                                <button type="button" @click="deletestoreImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                            </div>
                            <img :src="model.thumbnailsupportedImage" class="responsive img-thumbnail" />
                        </div>
                        <div class="form-group" v-else>
                            <label>Preview Image</label>
                            <div class="close-preview" v-if="model.supportedImage !=''">
                                <button type="button" @click="deletestoreImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                            </div>
                            <img :src="model.supportedImage" class="responsive img-thumbnail" />
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="phone" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Phone</label>
                                <input type="number" v-model="model.phone" class="form-control" placeholder="Enter Phone">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="email" v-slot="{ errors }" rules="email" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Email</label>
                                <input type="text" v-model="model.email" class="form-control" placeholder="Enter Email">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="address" v-slot="{ errors }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Address</label>
                                <textarea name="address" placeholder="Enter Address" cols="30" rows="3" v-model="model.address" class="form-control"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="whatsapp" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>WhatsApp</label>
                                <input type="text" v-model="model.whatsapp" class="form-control" placeholder="Enter WA Phone : +62xxxxx">
                                <small class="text-muted">ex : +62xxxxx </small>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="facebook" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Facebook</label>
                                <input type="url" v-model="model.facebook" class="form-control" placeholder="Enter URL https://www.facebook.com/your-username">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="youtube" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Youtube</label>
                                <input type="url" v-model="model.youtube" class="form-control" placeholder="Enter URL https://www.youtube.com/your-username">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="instagram" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Instagram</label>
                                <input type="url" v-model="model.instagram" class="form-control" placeholder="Enter URL https://www.instagram.com/your-username">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="tiktok" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Tiktok</label>
                                <input type="url" v-model="model.tiktok" class="form-control" placeholder="Enter URL https://www.tiktok.com/your-username">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="lemonEight" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Lemon Eight</label>
                                <input type="url" v-model="model.lemonEight" class="form-control" placeholder="Enter URL https://www.lemoneight.com/your-username">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="twitter" v-slot="{ errors }" class="col-6">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Twitter</label>
                                <input type="url" v-model="model.twitter" class="form-control" placeholder="Enter URL https://www.twitter.com/your-username">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="urlAppStore" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Url App Store</label>
                                <input type="url" v-model="model.urlAppStore" class="form-control" placeholder="Enter URL https://play.google.com/store/apps/xxxxx">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="urlPlayStore" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Url Play Store</label>
                                <input type="url" v-model="model.urlPlayStore" class="form-control" placeholder="Enter URL https://apps.apple.com/us/app/xxxxx">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            collapseType: 'margin',
            model: {
                description: '',
                supportedImage: '',
                thumbnailsupportedImage: '',
                supportedImageAlt: '',
                address: '',
                phone: '',
                email: '',
                facebook: '',
                youtube: '',
                instagram: '',
                tiktok: '',
                lemonEight: '',
                twitter: '',
                whatsapp: '',
                urlAppStore: '',
                urlPlayStore: ''
            },
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-footer')
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        storeImage(event){
            this.model.supportedImage = event.target.files[0];
            this.$set(this.model, 'thumbnailsupportedImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deletestoreImage(){
            this.model.supportedImage = '';
            this.model.thumbnailsupportedImage = '';
            this.success=true;
            this.message_error=null;
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("description", _.model.description);
                formData.append("supportedImageAlt", _.model.supportedImageAlt);
                formData.append("supportedImage", _.model.supportedImage);
                formData.append("address", _.model.address);
                formData.append("phone", _.model.phone);
                formData.append("email", _.model.email);
                formData.append("facebook", _.model.facebook);
                formData.append("youtube", _.model.youtube);
                formData.append("instagram", _.model.instagram);
                formData.append("tiktok", _.model.tiktok);
                formData.append("lemonEight", _.model.lemonEight);
                formData.append("twitter", _.model.twitter);
                formData.append("whatsapp", _.model.whatsapp);
                formData.append("urlAppStore", _.model.urlAppStore);
                formData.append("urlPlayStore", _.model.urlPlayStore);
                _.axios.put('/dc-footer', formData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.success = resp.data.success
                    _.isLoading = false
                })
                .catch(err => {
                    _.errors = err.response.data.message
                    _.message_error = err.response.data.message
                    _.success = err.response.data.success
                    _.isLoading = false
                })
            }
        },
    },
    mounted() {
        let _ = this;
        _.get();
    },
};
</script>
