<template>
	<div class="card">
		<div class="card-body">
			<h3 class="font-600 mb-2">{{ id ? 'Edit' : 'Add' }} Card Content</h3>
			<ValidationObserver class="card-content" ref="observer">
				<form @submit.prevent="submit">
					<ValidationProvider name="title" v-slot="{ errors }" rules="required">
						<div class="form-group" :class="{ 'validation-warning': isTitleExists || errors[0] }">
							<label for="title" class="required">Card Title</label>
							<input type="text" v-model="models.title" name="title" class="form-control" id="title" placeholder="Enter title" ref="titleField">
							<small class="text-muted" v-if="isTitleExists">The card title is already exist</small>
							<small class="text-muted" v-else-if="errors[0]">{{ errors[0] }}</small>
						</div>
					</ValidationProvider>

          <div class="row">
            <ValidationProvider ref="thumbnailImg" name="Thumbnail" v-slot="{ errors, validate }" class="col-3" :rules="typeof models.thumbnailUrl === 'string' ? 'image|size:2000|max-width-dimensions:240' : 'required|image|size:2000|max-width-dimensions:240'">
              <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                  <div class="row">
                    <label for="thumbnail" class="pl-1 required">Thumbnail</label>
                    <div class="col-12">
                      <div class="position-relative">
                          <img v-if="models.thumbnailUrl" @click="$refs.thumbnailUrl.click()" :src="getUrlOfFile(models.thumbnailUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail" />
                          <skeleton-img v-else @click="$refs.thumbnailUrl.click()" class="img-icon-thumbnail"/>
  
                          <button v-if="models.thumbnailUrl" @click="models.thumbnailUrl = $refs.thumbnailImg.value = null" class="btn btn-rounded btn-danger position-absolute" 
                              style="top: -10px; right: -5px"
                              title="Delete Image"
                              type="button">
                              <i class="fa fa-times"></i>
                          </button>
                      </div>
                      <input 
                          id="thumbnail"
                          ref="thumbnailUrl" 
                          type="file" 
                          accept=".png, .jpg, .jpeg" 
                          @change="(value) => {
                              validate(value)
                              .then((resp) => validateImage(resp, value, 'thumbnailUrl'));
                          }"
                          name="faqIcon" 
                          class="form-control d-none">
                      <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                </div>
              </div>
            </ValidationProvider>
          </div>

          <div class="mb-2" v-for="(content,index) in models.contents" :key="index">
            <h5>Content {{ index + 1 }}</h5>
            <ValidationProvider :name="`Content Option ${index + 1}`" v-slot="{ errors }" class="col" rules="required">
              <div class="form-group mb-0" :class="{ 'validation-warning': errors[0] }">
                <multiselect
                  @input="contentChange(index)"
                  v-model="content.contentType"
                  style="white-space: nowrap"
                  class="text-capitalize"
                  selectLabel=""
                  deselectLabel=""
                  :custom-label="contentLabel"
                  :allowEmpty="false"
                  :options="masters.contents"
                  :max-height="600">
                  <span slot="noResult">Oops! There is no type with those term.</span>
                  <span slot="option" slot-scope="props" class="text-capitalize">{{contentLabel(props.option)}}</span>
                </multiselect>
                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div class="row">
              <ValidationProvider ref="imageContentRef" :name="`Content Image ${index + 1}`" v-slot="{ errors, validate }" class="col-3" :rules="typeof content.imageUrl === 'string' ? 'image|size:2000|max-width-dimensions:1080' : 'required|image|size:2000|max-width-dimensions:1080'">
                <div class="form-group mb-0" :class="{ 'validation-warning': errors[0] }">
                  <div class="row">
                    <div class="col">
                      <div class="position-relative">
                          <img v-if="content.imageUrl" @click="$refs.contentImage[index].click()" :src="getUrlOfFile(content.imageUrl)" class="responsive m-0 cursor-pointer img-fluid img-story-banner" />
                          <skeleton-img v-else @click="$refs.contentImage[index].click()" class="img-story-banner"/>
                          <button v-if="content.imageUrl" @click="content.imageUrl = $refs.imageContentRef[index].value = null" class="btn btn-rounded btn-danger position-absolute" 
                              style="top: -10px; right: -5px"
                              title="Delete Image"
                              type="button">
                              <i class="fa fa-times"></i>
                          </button>
                      </div>
                      <input
                          ref="contentImage" 
                          type="file" 
                          accept=".png, .jpg, .jpeg" 
                          @change="(value) => {
                              validate(value)
                              .then((resp) => validateImage(resp, value, 'contents', 'imageUrl', index));
                          }"
                          name="faqIcon" 
                          class="form-control d-none">
                      <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </div>
                </div>
              </ValidationProvider>
              <div class="col d-flex flex-column justify-content-between">
                <div class="form-wrapper">
                  <button @click="$refs.contentImage[index].click()" type="button" class="btn btn-primary w-100 mb-2">
                    Upload Image
                  </button>

                  <ValidationProvider v-if="content.contentType === 'imageWithOneCTA' || content.contentType === 'imageWithTwoCTA'" :name="`Button Name Content ${index + 1}`" v-slot="{ errors }" rules="required">
                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                      <label for="firstButtonName" class="required">Button <span v-if="content.contentType === 'imageWithTwoCTA'">1</span> Name</label>
                      <input type="text" v-model="content.firstCta.ctaLabel" name="firstButtonName" class="form-control" id="firstButtonName" placeholder="Enter button name">
                      <small class="text-muted" v-if="errors[0]">{{errors[0]}}</small>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider v-if="content.contentType === 'imageWithOneCTA' || content.contentType === 'imageWithTwoCTA'" :name="`Button URL Content ${index + 1}`" v-slot="{ errors }" rules="required|url">
                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                      <label for="firstButtonUrl" class="required">Button <span v-if="content.contentType === 'imageWithTwoCTA'">1</span> URL</label>
                      <input type="url" v-model="content.firstCta.ctaRoute" name="firstButtonUrl" class="form-control" id="firstButtonUrl" placeholder="Enter button url">
                      <small class="text-muted" v-if="errors[0]">{{errors[0]}}</small>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider v-if="content.contentType === 'imageWithTwoCTA'" :name="`Button 2 Name Content ${index + 1}`" v-slot="{ errors }" rules="required">
                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                      <label for="secondButtonName" class="required">Button 2 Name</label>
                      <input type="text" v-model="content.secondCta.ctaLabel" name="secondButtonName" class="form-control" id="secondButtonName" placeholder="Enter button name">
                      <small class="text-muted" v-if="errors[0]">{{errors[0]}}</small>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider v-if="content.contentType === 'imageWithTwoCTA'" :name="`Button 2 URL Content ${index + 1}`" v-slot="{ errors }" rules="required|url">
                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                      <label for="secondButtonUrl" class="required">Button 2 URL</label>
                      <input type="url" v-model="content.secondCta.ctaRoute" name="secondButtonUrl" class="form-control" id="secondButtonUrl" placeholder="Enter button url">
                      <small class="text-muted" v-if="errors[0]">{{errors[0]}}</small>
                    </div>
                  </ValidationProvider>

                  <div v-if="content.contentType === 'imageWithProducts'">
                    <h5>Product Catalog</h5>                    
                    <div v-for="(_, prodIdx) in 2" :key="prodIdx" class="col px-0">
                      <div class="form-group mb-1">
                        <multiselect
                          v-model="content.productIds[prodIdx]"
                          selectLabel=""
                          track-by="id"
                          placeholder="Select product"
                          label="title"
                          :options="masters.products"
                          searchable
                          :max-height="600">
                          <span slot="noResult">Oops! There is no product with those term.</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <ValidationProvider ref="productContentRef" :name="`Product Option ${index + 1}`" v-slot="{ errors }" rules="required">
                        <input type="hidden" v-model="content.productIds">
                        <small v-if="errors[0] && content.productIds.length < 1" class="text-danger mb-1 d-block">You must first add 1 or 2 product recommendation</small>
                      </ValidationProvider>
                      <small>*max 2 product recommendations</small>
                    </div>
                  </div>

                  <div v-if="content.contentType === 'imageWithOtherContents'" class="mb-2">
                    <h5>School Content</h5>
                    <div v-for="(_, schoolIdx) in 4" :key="schoolIdx" class="col px-0">
                      <div class="form-group">
                        <multiselect
                          v-model="content.schoolCardIds[schoolIdx]"
                          selectLabel=""
                          track-by="id"
                          placeholder="Select card"
                          label="title"
                          :options="masters.schoolCards"
                          searchable
                          :max-height="600">
                          <span slot="noResult">Oops! There is no school content.</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <ValidationProvider ref="schoolContent" :name="`Content Option ${index + 1}`" v-slot="{ errors }" rules="required|lengthCard:3,true">
                        <input type="hidden" v-model="content.schoolCardIds">
                        <small v-if="errors[0] && content.schoolCardIds.filter(notNull => notNull).length < 1" class="text-danger mb-1 d-block">The Content Option is required</small>
                        <small v-if="errors[0] && content.schoolCardIds.filter(notNull => notNull).length == 3" class="text-danger mb-1 d-block">Can’t save 3 contents, please remove 1 or add 1 more content</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0 my-3">
              <button v-if="models.contents.length > 1" @click="deleteItem('contents', index)" type="button" class="btn btn-danger btn-sm mr-1">Delete Content</button>
              <button v-if="index === models.contents.length - 1" @click="pushItem('contents')" type="button" class="btn btn-primary btn-sm">Add Content</button>
            </div>
          </div>

					<div>
						<router-link to="/school-card" class="btn text-danger">Cancel</router-link>
						<button type="submit" class="btn btn-primary " :disabled="isLoading">
							<div v-if="isLoading">{{id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
							<div v-else>{{id ? 'Update' : 'Create'}}</div>
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {convertFiletoURL, getImages, getUrlOfFile} from '@/helper'

export default {
	data(){
		return {
			models: {
        title: "",
        thumbnailUrl: null,
        contents: [
          {
              contentType: "imageOnly",
              imageUrl: null,
              firstCta: {
                ctaLabel: null,
                ctaRoute: null
              },
              secondCta: {
                ctaLabel: null,
                ctaRoute: null
              },
              productIds: [],
              schoolCardIds: []
          }
        ]
			},
      duplicate_models: {},
      duplicate_title: '',
      isTitleExists: false,
      masters:{
        contents: [
          "imageOnly",
          "imageWithOneCTA",
          "imageWithTwoCTA",
          "imageWithProducts",
          "imageWithOtherContents",
        ],
        products: [],
        schoolCards: []
      },
			isLoading: false,
      submitWatcher: false,
      getUrlOfFile
		}
	},
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  created() {
		let _ = this
		_.duplicate_models = JSON.parse(JSON.stringify(_.models))
		_.getListOptions()
		_.getById()
    _.$watch('models.title', () => {
      clearTimeout(_.debounce)
      _.debounce = setTimeout(async () => {
        if(_.models.title !== _.duplicate_title) {
          // Check if title exist
          try {
            const {status} = await _.axios.post('otc/school-card/title-exist', {
              title: _.models.title
            })
            if(status === 200) {
              this.isTitleExists = false
            }
          } catch (error) {
            const { status } = error.response
            if(status === 400) {
              this.isTitleExists = true
            }
          }
        }
      }, 200)
    })
	},
  methods: {
    async contentChange(idx) {
      await this.$refs.observer.reset()
      this.$set(this.models.contents[idx], 'imageUrl', null)
      this.$set(this.models.contents[idx], 'firstCta', 
        {  
          ctaLabel: null,
          ctaRoute: null
        }
      )
      this.$set(this.models.contents[idx], 'secondCta', 
        {  
          ctaLabel: null,
          ctaRoute: null
        }
      )
      this.$set(this.models.contents[idx], 'productIds', [])
      this.$set(this.models.contents[idx], 'schoolCardIds', [])
    },
    contentLabel(value) {
      if(value === 'imageWithTwoCTA') {
        return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace('One', '1').replace('Two', '2').replace('CTA', 'CTAs');
      }
      return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace('One', '1').replace('Two', '2');
    },
    getListOptions(){
			let _ = this
			const getProducts = _.axios.get('misc/products')
			const getSchoolCards = _.axios.get('misc/school-card-in-directory')

			Promise.all([getProducts, getSchoolCards])
				.then(res => {
					const [products, schoolCards] = res
					this.masters.products = products.data.data
					this.masters.schoolCards = schoolCards.data.data
				})
		},
    async validateImage({valid}, event, type, key, index) {
      if(valid) {
        const file = await getImages(event)
        if(file) {
          if(typeof index === 'number') {
              this.$set(this.models[type][index], `${key}`, file)
          } else {
              this.models[type] = file
          }
        }
        event.target.value = null
      } else {
        event.target.value = null
      }
		},
		getById() {
			let _ = this
			if (_.$route.params.id) {
        _.axios.get(`otc/school-card/${_.$route.params.id}`)
					.then(res => {
						const {data} = res.data
            _.models.title = data.title
            _.duplicate_title = data.title
            _.models.thumbnailUrl = data.thumbnailUrl
            _.models.contents = data.otcSchoolCardContents.length ? data.otcSchoolCardContents.map(content => {
              const firstCta = content.firstCta || {
                ctaLabel: null,
                ctaRoute: null
              }
              const secondCta = content.secondCta || {
                ctaLabel: null,
                ctaRoute: null
              }
              return {
                contentType: content.contentType,
                imageUrl: content.imageUrl,
                firstCta,
                secondCta,
                productIds: content.schoolCardContentProducts,
                schoolCardIds: content.schoolCardContentCards
              }
            }) : [{
              contentType: "imageOnly",
              imageUrl: null,
              firstCta: {
                ctaLabel: null,
                ctaRoute: null
              },
              secondCta: {
                ctaLabel: null,
                ctaRoute: null
              },
              productIds: [],
              schoolCardIds: []
          }]
					})
			}
		},
		pushItem(type) {
			this.models[type].push(JSON.parse(JSON.stringify(this.duplicate_models[type][0])))
		},
		deleteItem(type, index) {
			this.models[type].splice(index, 1);
		},
		setInitialValue() {
			let _ = this
			_.duplicate_models = JSON.parse(JSON.stringify(this.models))
		},
		modifyOptionsObject(newModels) {
      newModels.contents = newModels.contents.map(content => {
        if(content.contentType === 'imageOnly') {
          content.firstCta = null
          content.secondCta = null
          content.productIds = []
          content.schoolCardIds = []
        } else if (content.contentType === 'imageWithOneCTA') {
          content.secondCta = null
          content.productIds = []
          content.schoolCardIds = []
        } else if (content.contentType === 'imageWithTwoCTA') {
          content.productIds = []
          content.schoolCardIds = []
        } else if (content.contentType === 'imageWithProducts') {
          content.productIds = content.productIds.filter(notNull => notNull).map(product => product.id)
          content.firstCta = null
          content.secondCta = null
          content.schoolCardIds = []
        } else {
          content.schoolCardIds = content.schoolCardIds.filter(notNull => notNull).map(card => card.id)
          content.firstCta = null
          content.secondCta = null
          content.productIds = []
        }
        return content
      })
			return newModels
		},

    localValidate() {
			let _ = this
      if(this.isTitleExists) {
        _.$refs.titleField.scrollIntoView({ 
					behavior: "smooth",
					block: 'center'
				})
        return false
			}

      let schoolContentIdx = 0
      let productContentIdx = 0

      this.models.contents = this.models.contents.map(content => {
        content.productIds = content.productIds.filter(notNull => notNull)
        content.schoolCardIds = content.schoolCardIds.filter(notNull => notNull)
        
        if(content.contentType === 'imageWithOtherContents') {
          this.$refs.schoolContent[schoolContentIdx].value = content.schoolCardIds.filter(notNull => notNull)
          schoolContentIdx++
        } else if(content.contentType === 'imageWithProducts') {
          this.$refs.productContentRef[productContentIdx].value = content.productIds.filter(notNull => notNull)
          productContentIdx++
        }
        return content
      })
      
      return true
    },

		async submit() {
			let _ = this
      _.submitWatcher = true
      const isLocalValid = this.localValidate()
      if(isLocalValid){
        const isValid = await _.$refs.observer.validate()
        if ( isValid ) {
          _.isLoading = true
          const modelsToURL = await convertFiletoURL(_.models, 'school-card')
          if(modelsToURL) {
            const newModels = _.modifyOptionsObject(modelsToURL)

            let request = ''
            let message = 'created'
            
            try {
              if (!_.$route.params.id) {
                request = await _.axios.post('otc/school-card', newModels)
              } else {
                message = 'updated'
                request = await _.axios.put(`otc/school-card/${_.$route.params.id}`, newModels)
              }
              if(request.status === 200) {
                _.$notify({
                  type: 'success',
                  title: 'Success',
                  text: `Card successfully ${message}`
                })
                _.isLoading = false
                _.$router.push('/school-card')
              }
            } catch (error) {
              _.$notify({
                type: 'error',
                title: 'Failed',
                text: error.response.data.message
              })
              _.isLoading = false
            }
          }
        } else {
          _.isLoading = false
        }
      }
		},
  }
}
</script>

<style>
span.vertical-shake {
  animation: vertical-shaking 0.35s infinite;
}

@keyframes vertical-shaking {
  0% { transform: translateY(0) }
  25% { transform: translateY(5px) }
  50% { transform: translateY(-5px) }
  75% { transform: translateY(5px) }
  100% { transform: translateY(0) }
}
</style>