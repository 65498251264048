<template>
    <form @submit.prevent="submit">
      <div class="form-group">
        <label for="pretitleInput">Transition Pre Heading</label>
        <input type="text" v-model="model.preTitle" name="pretitle" class="form-control" placeholder="Enter pre heading">
        <small class="text-danger" v-show="errors.preTitle">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Transition Title</label>
        <input type="text" v-model="model.title" class="form-control" placeholder="Enter title">
        <small class="text-danger" v-show="errors.title">The title field is required</small>
      </div>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="imageInput">Transition Image</label>
          <!-- <input type="file" @change="handleFileUpload( $event )"/> -->
          <div style="text-align: -webkit-center;">
                <div v-if="model.image != null">
                    <img :src="model.image" class="img-fluid" alt=""><br>
                    <button class="btn btn-danger mt-1 mb-1" @click="model.image = null">Delete Image</button>
                </div>
                <div v-else 
                    style="
                        text-align: center; 
                        border-radius: 10px; 
                        background: #E5E7E9;
                        padding: 30px;
                        cursor: pointer;
                    ">
                    <label for="file-upload" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                        <i class="fa fa-upload" aria-hidden="true"></i> 
                        <p style="font-size: 14px">Upload Image</p>
                    </label>
                    <input id="file-upload" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event )">
                </div>                                                        
            </div>
            <small class="text-danger" v-show="errors.image">The image field is required</small>
            <small class="text-danger" v-show="errors.image_size">The image file is too large, allowed maximum size is {{max_file_size}}MB</small>
        </div>

        <div class="form-group col-md-9">
          <label for="" >Transition Description</label>
          <textarea class="form-control" v-model="model.description" id="descriptionInput" placeholder="Enter description"></textarea>
          <small class="text-danger" v-show="errors.description">The description field is required</small>
        </div>
      </div>
      <hr/>
    </form>
</template>

<script>

export default {
    name: 'PageTypeTransition',
    data() {
      return {
        file: '',
        model: {
          title: "",
          image: null,
          description: ""
        },
        errors: {
          title: false,
          image: false,
          description: false,
          image_size: false
        },
        max_file_size: 2
      }
    },
    beforeMount() {
      if ( this.$route.params.id ) {
        let editContent = this.$store.getters.currentEditContent
        this.model = editContent
      }
    },
    methods: {
      handleFileUpload(event){
        this.file = event.target.files[0]

        if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
            event.preventDefault();
            this.errors.image_size = true
            return;
        } else {
          this.errors.image_size = false
        }

        let formData = new FormData()
        formData.append('folder', 'contents')
        formData.append('file', this.file)

        this.axios.post('/misc/upload',
            formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }).then(resp => {
                if ( resp.data.success ) {
                  let file = resp.data.data.file
                  this.model.image = file
                } else {
                  let file = 'https://via.placeholder.com/150?text=dummy+image'
                  this.model.image = file
                }
            }).catch(err => {
                console.log(err.response)
            })
      }
    },
    watch: {
      'model.preTitle': function(newValue) {
        this.$store.dispatch('contentTransition', { pageOption: { preTitle: newValue } })
      },
      'model.title': function(newValue) {
        this.$store.dispatch('contentTransition', { pageOption: { title: newValue } })
      },
      'model.description': function(newValue) {
        this.$store.dispatch('contentTransition', { pageOption: { description: newValue } })
      },
      'model.image': function(newValue) {
        this.$store.dispatch('contentTransition', { pageOption: { image: newValue } })
      }
    },
    mounted() {
      let _ = this
      _.$root.$on('errorListener', (data) => {
        _.errors = data
        _.$forceUpdate();
      })
      _.max_file_size = process.env.VUE_APP_MAX_FILE_SIZE
    }
}
</script>