<template>
	<div class="card">
        <div class="card-body">
			<h3 class="font-600 mb-2">Edit Login Announcement Board</h3>
            <ValidationObserver class="card-content" ref="observer">
	            <form @submit.prevent="submit">
                    <label for="" class="required">Image</label>
                    <div v-if="model.image != ''" class="text-center profile-avatar">
                        <img :src="model.image" class="img-fluid rounded m-1 pointer" alt="" style="width: 150px; height: 150px;" @click="lihat(model.image)"><br>
                        <div class="edit-avatar">
                            <button class="btn btn-rounded btn-danger" 
                                title="Delete Image" @click="model.image = ''"
                                type="button">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div v-else 
                        style="
                            text-align: center; 
                            border-radius: 10px; 
                            background: #E5E7E9;
                            padding-top: 25px;
                            padding-bottom: 25px;
                            cursor: pointer;
                            width: 150px;
                            height: 150px;
                        " class="mr-1 mt-1 mb-1">
                        <label for="file-upload" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                            <i class="fa fa-upload" aria-hidden="true"></i> 
                            <p style="font-size: 14px">Upload Image</p>
                        </label>
                        <input id="file-upload" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event )">
                    </div>
                    <small class="text-danger" v-show="image_size">The image file is too large, <br>allowed maximum size is {{max_file_size}}MB</small>
                    <small class="text-danger" v-show="no_image">The image field is required</small>
                    
	            	<ValidationProvider name="title" v-slot="{ errors }" rules="required" >
	                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
	                        <label for="" class="required">Title</label>
	                		<quill-editor v-model="model.title" :options="editorOption"></quill-editor>
	                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
	                    </div>
	                </ValidationProvider>
                    <ValidationProvider name="description" v-slot="{ errors }" rules="required" >
	                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
	                        <label for="" class="required">Description</label>
	                		<quill-editor v-model="model.description" :options="editorOption"></quill-editor>
	                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
	                    </div>
	                </ValidationProvider>

                    <div class="mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Updating<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Update</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    data() {
        return {
            model: {
                title: '',
                description: '',
                image: ''
            },
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            },
            isLoading: false,
            no_image: false,
            image_size: false,
            max_file_size: 0
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/announcements')
                .then(resp => {
                    _.model = resp.data.data
                })
        },
        async submit() {
			let _ = this
            if (_.model.image == '') {
                _.no_image = true
            } else {
                _.no_image = false
            }
            const isValid = await _.$refs.observer.validate()
            if (isValid && !_.no_image){
                _.isLoading = true
                let data = {
                    title:          _.model.title,
                    description:    _.model.description,
                    image:          _.model.image
                }
                _.axios.put('/announcements', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })
            }
        },
        handleFileUpload(event){
            let file = event.target.files[0]
            if (file.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size = true
                return;
            } else {
                this.image_size = false
            }

            let formData = new FormData()
            formData.append('folder', 'announcement')
            formData.append('file', file)

            this.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    this.model.image = resp.data.data.file
                }).catch(err => {
                    console.log(err.response)
                })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
    },
    mounted() {
        let _ = this
        _.get()
        _.max_file_size = process.env.VUE_APP_MAX_FILE_SIZE
    }
}
</script>