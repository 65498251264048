<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Clinical Team</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="name" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Name <span class="alert-required">*</span></label>
                                <input type="text" v-model="model.name" class="form-control" placeholder="Enter Title">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="STR" v-slot="{ errors }" rules="max:16" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>STR</label>
                                <input type="number" @keypress="numberOnly" v-model="model.str" class="form-control" placeholder="Enter STR number (max 16 digits)">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="description" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Description <span class="alert-required">*</span></label>
                            <quill-editor v-model="model.description" :options="editorOption"></quill-editor>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="specialisId" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label>Clinical Team Expertise <span class="alert-required">*</span></label>
                            <select name="specialisId" id="" v-model="model.specialisId" class="form-control">
                                <option value="">Select Clinical Team Expertise</option>
                                <option v-for="(data,index) in specialist" :key="index" :value="data.id">
                                    {{data.name}}
                                </option>
                            </select>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Alt Image</label>
                                <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="image" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Image <span class="alert-required">*</span></label>
                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                                <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider class="col">
                            <div class="form-group" v-if="model.thumbnail">
                                <label>Preview Image</label>
                                <img :src="model.thumbnail" class="responsive img-thumbnail" />
                            </div>
                            <div class="form-group" v-else>
                                <label>Preview Image</label>
                                <img :src="model.image" class="responsive img-thumbnail" />
                            </div>
                        </ValidationProvider>
                    </div>
                    <hr>
                    <div class="text-left mt-1">
                        <router-link to="/medical-advisor-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    data() {
        return {
            model: {
                name: '',
                str: null,
                description: '',
                specialisId: '',
                image: '',
                imageAlt: '',
                thumbnail: '',
                userId: ''
            },
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            specialist: [],
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-doctor/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                const data = {
                    name: _.model.name,
                    str: _.model.str || null,
                    description: _.model.description,
                    specialisId: String(_.model.specialisId),
                    imageAlt: _.model.imageAlt,
                    image: _.model.image,
                };
                if (!_.$route.params.id) {
                    _.axios.post('/dc-doctor', data)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/medical-advisor-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })     
                } 
                else {
                    _.axios.put('/dc-doctor/' + _.$route.params.id, data)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/medical-advisor-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })   
                }  
            }
        },
        storeImage(event){
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('folder', 'medical-advisor');
            formData.append('file', file);

            this.axios.post('/misc/upload',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(resp => {
                    if (resp.data.success) {
                        this.model.image = resp.data.data.file;
                        this.$set(this.model, 'thumbnail', resp.data.data.file);
                        this.success = true;
                        this.message_error = null;
                    }
                }).catch(err => {
                    console.log(err.response);
                });
        },
        getSpecialist() {
            let _ = this
            _.axios.get('/dc-specialis/list-all')
            .then(resp=>{
                _.specialist = resp.data.data
            })
        },
        numberOnly(event) {
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.getSpecialist()
    }
}
</script>