<template>
    <form @submit.prevent="submit">
      <div class="form-group">
        <label for="pretitleInput">Single Answer Pre Heading</label>
        <input type="text" v-model="model.preTitle" name="pretitle" class="form-control" placeholder="Enter pre heading">
        <small class="text-danger" v-show="errors.preTitle">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Single Answer Title</label>
        <input type="text" v-model="model.title" name="title" class="form-control" placeholder="Enter title">
        <small class="text-danger" v-show="errors.title">This field is required</small>
      </div>

      <div class="form-group">
        <label for="" >Single Answer Description</label>
        <textarea class="form-control" v-model="model.description" id="descriptionInput" placeholder="Enter description">
          
        </textarea>
        <small class="text-danger" v-show="errors.description">This field is required</small>
      </div>

      <label for="" >Single Answer List Option</label>

      <div class="form-row" v-for="(opt, key) in listOptions" :key="key">
        <div class="form-group col-md-11">
          <input type="text" v-model="listOptions[key].label" placeholder="Enter option" class="form-control">
          <small class="text-danger" v-show="errors.choices[key].label">This field is required</small>
        </div>

        <div class="form-group col-md-1">
          <button class="btn btn-danger" @click="rem(key)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <button class="btn btn-info mb-2" @click="add"><i class="fa fa-plus"></i></button>
      <hr/>
    </form>
</template>

<script>
export default {
    name: 'PageTypeSingleAnswer',
    data() {
      return {
        listOptions: [
          {
            id: this.randomString(),
            label: ''
          }
        ],
        model: {
          preTitle: '',
          title: '',
          description: ''
        },
        errors: {
          preTitle: false,
          title: false,
          description: false,
          choices: [
            {
              label: false
            }
          ]
        },
      }
    },
    beforeMount() {
      if ( this.$route.params.id ) {
        let editContent = this.$store.getters.currentEditContent
        this.model = editContent
        this.listOptions = editContent.choices

        this.listOptions.forEach(() => {
          this.errors.choices.push({
            label: false
          })
        })
      }
    },
    methods: {
      submit() {

      },
      add() {
        this.listOptions.push({
          id: this.randomString(),
          label: ''
        })

        this.errors.choices.push({
          label: false
        })
      },
      rem(key) {
        this.listOptions = this.listOptions.filter( (opt, i) => key != i )
      },
      randomString() {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 8; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
      }
    },
    watch: {
      'model.preTitle': function(newValue) {
        this.$store.dispatch('contentSingleAnswer', { pageOption: { preTitle: newValue } })
      },
      'model.title': function(newValue) {
        this.$store.dispatch('contentSingleAnswer', { pageOption: { title: newValue } })
      },
      'model.description': function(newValue) {
        this.$store.dispatch('contentSingleAnswer', { pageOption: { description: newValue } })
      },
      'listOptions': {
        handler: function(newValue) {
            this.$store.dispatch('contentSingleAnswer', {
             pageOption: {
              choices: newValue
             }
          })
        },
        deep: true
      }
    },
    mounted() {
      let _ = this
      _.$root.$on('errorListener', (data) => {
        _.errors = data
        _.$forceUpdate();
      })
    }
}
</script>