<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Clinical Team Page</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Section Banner">
                            <div class="row">
                                <ValidationProvider name="title" v-slot="{ errors }" class="col" rules="required">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required">*</span></label>
                                        <input type="text" v-model="model.title" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>     
                            </div>
                            <ValidationProvider name="description" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea name="" id="" cols="30" rows="5" v-model="model.description" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="bannerColor" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Background Header Color</label>
                                    <sketch-picker v-model="model.bannerColor" @input="updateColor"></sketch-picker>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Alt Image</label>
                                    <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="image" v-slot="{ errors }" rules="required" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Image <span class="alert-required">*</span></label>
                                    <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                                    <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.image" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Embed Video">
                            <ValidationProvider name="sectionEmbed" rules="required" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.sectionEmbed">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="embedTitle" v-slot="{ errors }" :rules="model.sectionEmbed == true ? 'required': ''" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required" v-show="model.sectionEmbed == true">*</span></label>
                                        <input type="text" v-model="model.embedTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>     
                            </div>
                            <ValidationProvider name="embedDescription" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea name="" id="" cols="30" rows="5" v-model="model.embedDescription" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="embedLink" v-slot="{ errors }" :rules="model.sectionEmbed == true ? 'required': ''" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Url Video <span class="alert-required" v-show="model.sectionEmbed == true">*</span></label>
                                    <input type="url" v-model="model.embedLink" class="form-control" placeholder="Enter URL ex: https://www.youtube.com/embed/xxxxxx">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailembed">
                                    <label>Preview Video</label>
                                    <iframe :src="model.thumbnailembed" height="450" class="img-thumbnail-video"></iframe>
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Video</label>
                                    <iframe :src="model.embedLink" height="450" class="img-thumbnail-video"></iframe>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Unique Selling Point">
                            <ValidationProvider name="sectionTreatment" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.sectionTreatment">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="treatmentTitle" v-slot="{ errors }" class="col" :rules="model.sectionTreatment == true ? 'required': ''">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required" v-show="model.sectionTreatment == true">*</span></label>
                                        <input type="text" v-model="model.treatmentTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="treatmentDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.treatmentDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr/>
                            <h5 class="font-600 mb-2">Section Treatment</h5>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="treatment1Title" v-slot="{ errors }" class="col" :rules="model.sectionTreatment == true ? 'required': ''">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 1 <span class="alert-required" v-show="model.sectionTreatment == true">*</span></label>
                                            <input type="text" v-model="model.treatment1Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="treatment1Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 1</label>
                                            <textarea name="" id="" cols="30" rows="5" v-model="model.treatment1Description" class="form-control"></textarea>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="treatment1Image" v-slot="{ errors }" :rules="model.sectionTreatment == true ? 'required': ''" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Image 1 <span class="alert-required" v-show="model.sectionTreatment == true">*</span></label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageTreatment1" label="Image" name="treatment1Image" class="form-control">
                                            <input type="hidden" v-model="model.treatment1Image" class="form-control" placeholder="Enter Alt Image">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailtreatment1Image">
                                            <label>Preview Image</label>
                                            <img :src="model.thumbnailtreatment1Image" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <img :src="model.treatment1Image" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="treatment2Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 2</label>
                                            <input type="text" v-model="model.treatment2Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="treatment2Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 2</label>
                                            <textarea name="" id="" cols="30" rows="5" v-model="model.treatment2Description" class="form-control"></textarea>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="treatment2Image" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Image 2</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageTreatment2" label="Image" name="treatment2Image" class="form-control">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <input type="hidden" v-model="model.treatment2Image" class="form-control" placeholder="Enter Alt Image">
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailtreatment2Image">
                                            <label>Preview Image</label>
                                            <img :src="model.thumbnailtreatment2Image" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <img :src="model.treatment2Image" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="treatment3Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 3</label>
                                            <input type="text" v-model="model.treatment3Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="treatment3Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 3</label>
                                            <textarea name="" id="" cols="30" rows="5" v-model="model.treatment3Description" class="form-control"></textarea>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="treatment3Image" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Image 3</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageTreatment3" label="Image" name="treatment3Image" class="form-control">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <input type="hidden" v-model="model.treatment3Image" class="form-control" placeholder="Enter Alt Image">
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group" v-if="model.thumbnailtreatment3Image">
                                            <label>Preview Image</label>
                                            <img :src="model.thumbnailtreatment3Image" class="responsive img-thumbnail" />
                                        </div>
                                        <div class="form-group" v-else>
                                            <label>Preview Image</label>
                                            <img :src="model.treatment3Image" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Clinical Team">
                            <ValidationProvider name="sectionDoctor" rules="required" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.sectionDoctor">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="doctorTitle" v-slot="{ errors }" class="col" :rules="model.sectionDoctor == true ? 'required': ''">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required" v-show="model.sectionDoctor == true">*</span></label>
                                        <input type="text" v-model="model.doctorTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="doctorDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.doctorDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="doctorId" v-slot="{ errors }" :rules="model.sectionDoctor == true ? 'required': ''" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Clinical Team <span class="alert-required" v-show="model.sectionDoctor == true">*</span></label>
                                    <multiselect 
                                        v-model="model.doctorId"
                                        label="name"
                                        name="doctors"
                                        track-by="id" 
                                        placeholder="Search Doctor"
                                        :options="doctors" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no doctor.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Button Sitemap">
                            <div class="row">
                                <ValidationProvider name="buttonConsult1" rules="required" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi 1 <span class="alert-required">*</span></label>
                                        <select name="buttonConsult1" id="" v-model="model.buttonConsult1" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                        </app-collapse-item>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            model: {
                title: '',
                description: '',
                bannerColor: '',
                image: '',
                thumbnailimage: '',
                imageAlt: '',
                sectionEmbed: true,
                embedLink: '',
                embedTitle: '',
                embedDescription: '',
                thumbnailembed: '',
                treatmentTitle: '',
                treatmentDescription: '',
                treatment1Image: '',
                thumbnailtreatment1Image: '',
                treatment1Title: '',
                treatment1Description: '',
                treatment2Image: '',
                thumbnailtreatment2Image: '',
                treatment2Title: '',
                treatment2Description: '',
                treatment3Image: '',
                thumbnailtreatment3Image: '',
                treatment3Title: '',
                treatment3Description: '',
                sectionDoctor: true,
                doctorTitle: '',
                doctorId: [],
                doctorDescription: '',
                buttonConsult1: '',
            },
            doctors: [],
            buttons: [],
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-advisor')
                .then(resp => {
                    _.model = resp.data.data
                    _.model.doctorId = _.model.doctors
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageTreatment1(event){
            this.model.treatment1Image = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatment1Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageTreatment2(event){
            this.model.treatment2Image = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatment2Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageTreatment3(event){
            this.model.treatment3Image = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatment3Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("image", _.model.image);
                formData.append("sectionEmbed", _.model.sectionEmbed);
                formData.append("embedLink", _.model.embedLink);
                formData.append("embedTitle", _.model.embedTitle);
                formData.append("embedDescription", _.model.embedDescription);
                formData.append("sectionTreatment", _.model.sectionTreatment);
                formData.append("treatmentTitle", _.model.treatmentTitle);
                formData.append("treatmentDescription", _.model.treatmentDescription);
                formData.append("treatment1Image", _.model.treatment1Image);
                formData.append("treatment1Title", _.model.treatment1Title);
                formData.append("treatment1Description", _.model.treatment1Description);
                formData.append("treatment2Image", _.model.treatment2Image);
                formData.append("treatment2Title", _.model.treatment2Title);
                formData.append("treatment2Description", _.model.treatment2Description);
                formData.append("treatment3Image", _.model.treatment3Image);
                formData.append("treatment3Title", _.model.treatment3Title);
                formData.append("treatment3Description", _.model.treatment3Description);
                formData.append("sectionDoctor", _.model.sectionDoctor);
                formData.append("doctorTitle", _.model.doctorTitle);
                formData.append("doctorDescription", _.model.doctorDescription);
                formData.append("doctorId", JSON.stringify(_.model.doctorId.map(p => p.id)));
                formData.append("buttonConsult1", _.model.buttonConsult1);
                _.axios.put('/dc-advisor', formData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.success = resp.data.success
                    _.$router.push('/advisor-page')
                    setTimeout(function() {
                        location.reload();
                    }, 3000);
                })
                .catch(err => {
                    _.errors = err.response.data.message
                    _.message_error = err.response.data.message
                    _.success = err.response.data.success
                    _.isLoading = false
                })
            }
        },
        getDoctors() {
           let _ = this
            _.axios.get('/dc-doctor/list-all')
            .then(resp=>{
                _.doctors = resp.data.data
            }) 
        },
    },
    mounted() {
        let _ = this
        _.get()
        _.getDoctors()
        _.getButtons()
    }
}
</script>