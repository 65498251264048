<template>
    <div class="card">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{id ? 'Edit' : 'Add'}} Content</h3>
            <ValidationObserver class="card-content" ref="observer">
              <form @submit.prevent="submit">
                <ValidationProvider name="title" ref="title" v-slot="{ errors }" rules="required|max:255">
                  <div class="form-group">
                    <label for="" class="required">Title</label>
                    <input type="text" v-model.lazy="model.title" class="form-control" id="titleInput" placeholder="Enter title">
                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="page type" v-slot="{ errors }" rules="required">
                  <div class="form-group">
                    <label for="" class="required">Page Type</label>
                    <multiselect 
                        v-model="selectedPageType"
                        label="name"
                        track-by="id" 
                        placeholder="Choose Page Type"
                        :options="pageTypeSelect" 
                        :allow-empty="false"
                        @input="model.pageTypeId = selectedPageType.id">
                        <span slot="noResult">Oops! Data not found.</span>
                    </multiselect>
                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
                <hr>
                <component :is="showComponent"/>
                <div class="">
                    <router-link to="/contents" class="btn text-danger">Cancel</router-link>
                    <button type="submit" class="btn btn-primary " :disabled="isLoading">
                        <div v-if="isLoading">{{id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>{{id ? 'Update' : 'Create'}}</div>
                    </button>
                </div>
              </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import Landing from '@/views/content/page_type/Landing'
import Transition from '@/views/content/page_type/Transition'
import SingleAnswer from '@/views/content/page_type/SingleAnswer'
import SingleAnswerWithTextbox from '@/views/content/page_type/SingleAnswerWithTextbox'
import MultipleAnswer from '@/views/content/page_type/MultipleAnswer'
import ImageOption from '@/views/content/page_type/ImageOption'
import Essay from '@/views/content/page_type/Essay'
import UploadPhoto from '@/views/content/page_type/UploadPhoto'
import Empty from '@/views/content/page_type/Empty'

export default {
    components: {
      Landing,
      Transition,
      SingleAnswer,
      SingleAnswerWithTextbox,
      MultipleAnswer,
      ImageOption,
      Empty
    },
    data() {
        return {
            id: this.$route.params.id,
            showComponent: Empty,
            title: null,
            pageTypeSelect: [],
            model: {
              title: "",
              pageTypeId: ""
            },
            isLoading: false,
            selectedPageType: ""
        }
    },
    beforeMount() {
      if (this.id){
        this.axios.get('/contents/' + this.id)
          .then(resp => {
            this.$store.dispatch('contentEdit', resp.data.data)
            this.selectedPageType = resp.data.data.pageType
          })
          .catch(err => console.log(err.response))
      }
    },
    mounted() {
      let _ = this
      _.getPageType()
    },
    methods: {
      getPageType() {
        let _ = this
        _.axios.get('/misc/page-types')
          .then(resp => {
            _.pageTypeSelect = resp.data.data
          })
          .catch(err => {
            console.log(err.response)
          })
      },
      async submit() {
        let _ = this
        let data = _.$store.state.content

        const isValid = await _.$refs.observer.validate();

        if (isValid){
          _.isLoading = true
          if (data.pageOption.lists) {
            data.pageOption.lists.forEach(function(item, index, object) {
              if (item.title == '') {
                object.splice(index, 1)
              }
            });
          } else if (data.pageOption.choices) {
            data.pageOption.choices.forEach(function(item, index, object) {
              if (item.title == '' || item.label == '') {
                object.splice(index, 1)
              }
            });
          }

          if ( _.id ) {
            _.axios.put('/contents/' + _.id, { ...data })
              .then((resp) => {
                _.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                _.$router.push('/contents')
                _.isLoading = false
              })
              .catch(() => {
                this.$refs.title.applyResult({
                    errors: ['Content title is already used'],
                    valid: false,
                    failedRules: {}
                });
                _.isLoading = false
              })
          } else {
            _.axios.post('/contents', { ...data })
              .then((resp) => {
                _.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                _.$router.push('/contents')
                _.isLoading = false
              })
              .catch(() => {
                this.$refs.title.applyResult({
                    errors: ['Content title is already used'],
                    valid: false,
                    failedRules: {}
                });
                _.isLoading = false
              })
          }
        }
      }
    },
    watch: {
      'model.title': function(newValue) {
        if ( newValue ) {
          this.$store.dispatch('content', { title: newValue })
          this.$store.dispatch('content', { slug: newValue.toLowerCase().replaceAll(' ', '-') })
        }
      },
      'model.pageTypeId': function(newValue) {
        if ( newValue ) {
          this.$store.dispatch('resetPageOption', newValue)
          this.$store.dispatch('content', { pageTypeId: newValue })

          switch (newValue) {
            case 1:
              this.showComponent = Landing;
              break;
            case 2:
              this.showComponent = Transition;
              break;
            case 3:
              this.showComponent = SingleAnswer;
              break;
            case 4:
              this.showComponent = SingleAnswerWithTextbox;
              break;
            case 5:
              this.showComponent = MultipleAnswer;
              break;
            case 6:
              this.showComponent = ImageOption;
              break;
            case 7:
              this.showComponent = Essay;
              break;
            case 8:
              this.showComponent = UploadPhoto;
              break;
            default:
              this.showComponent = Essay;
              break;
          }
        }
      },
      '$store.state.contentEdit': function(newValue) {
        this.model.title = newValue.title
        this.model.pageTypeId = newValue.pageTypeId
      }
    }
}
</script>