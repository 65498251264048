<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Testimony Library</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <div class="text-danger">
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="Customer Name" v-slot="{ errors }" rules="required|max:30" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="name">Customer Name <span class="alert-required">*</span></label>
                                <input type="text" id="name" v-model="model.name" class="form-control" placeholder="Enter Name">
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="form-group col-3">
                            <label for="age">Age</label>
                            <input type="number" id="age" @keypress="numberOnly" v-model.number="model.age" class="form-control" placeholder="Enter Age">
                        </div>
                        <ValidationProvider name="Sex" v-slot="{ errors }" rules="required" class="col-3">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="sex">Sex <span class="alert-required">*</span></label>
                                <select class="form-control" v-model="model.sex">
                                    <option value="" disabled selected hidden>Select sex</option>
                                    <option value="MALE">Laki-laki</option>
                                    <option value="FEMALE">Perempuan</option>
                                </select>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Condition Detail" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="condition">Condition Detail <span class="alert-required">*</span></label>
                                <multiselect
                                    v-model="model.selectedCondition"
                                    label="namePage"
                                    id="condition"
                                    track-by="id" 
                                    placeholder="Search Condition"
                                    :options="conditions"
                                    :multiple="false"
                                    :searchable="true"
                                    :max-height="600"
                                >
                                    <span slot="noResult">Oops! There is no condition.</span>
                                </multiselect>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="form-group col-3">
                            <label for="rating">Rating</label>
                            <star-rating
                                v-model="model.rating"
                                :increment="1"
                                :show-rating="false"
                                :star-size="32"
                                id="rating"
                                :clearable="true"
                            >
                            </star-rating>
                        </div>
                        <ValidationProvider name="Submission Date" v-slot="{ errors }" rules="required" class="col-3">
                            <div class="form-group">
                                <label for="date">Submission Date <span class="alert-required">*</span></label>
                                <date-picker
                                    v-model="model.date"
                                    type="date" 
                                    value-type="YYYY-MM-DD" 
                                    placeholder="Choose date"
                                    class="w-100"
                                    id="date"
                                    :disabled-date="disableFutureDate">
                                </date-picker>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Review" v-slot="{ errors }" rules="required|max:500" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="review">Review <span class="alert-required">*</span></label>
                                <textarea
                                    v-model="model.review"
                                    id="review"
                                    cols="30"
                                    rows="5"
                                    class="form-control"
                                    placeholder="Enter Review"
                                    style="resize: none;"
                                >
                                </textarea>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="row">
                        <ValidationProvider
                            name="Photo before"
                            v-slot="{ errors, validate }"
                            :rules="`${isPhotoBeforeRequired ? 'required|max-dimensions:500' : ''}`"
                            class="col"
                        >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="photoBefore">Photo: before <span class="alert-required">*</span></label>
                                <div class="form-control" style="height: auto;">
                                    <span class="btn btn-outline-primary btn-sm" @click="$refs.photoBefore.click()">Select Photo</span>
                                    <img
                                        v-if="model.photoBeforeUrl"
                                        :src="model.photoBeforeUrl"
                                        width="240"
                                        height="300"
                                        class="mt-1"
                                        style="object-fit: cover; object-position: center;"
                                    />
                                    <small class="block mt-1">Aspect ratio: 4 x 5</small>
                                    <small class="block" style="margin-top: 4px;">Recommended dimensions: 500 x 625 pixels</small>
                                    <small class="block" style="margin-top: 4px;">Max width: 500 pixels</small>
                                </div>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    @change="(e) => {
                                        isPhotoBeforeRequired = true;
                                        validate(e).then((res) => storePhotoBefore(res, e));
                                    }"
                                    id="photoBefore"
                                    class="hidden"
                                    placeholder="Upload Photo Before"
                                    ref="photoBefore"
                                >
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            name="Photo after"
                            v-slot="{ errors, validate }"
                            :rules="`${isPhotoAfterRequired ? 'required|max-dimensions:500' : ''}`"
                            class="col"
                        >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="photoAfter">Photo: after <span class="alert-required">*</span></label>
                                <div class="form-control" style="height: auto;">
                                    <span class="btn btn-outline-primary btn-sm" @click="$refs.photoAfter.click()">Select Photo</span>
                                    <img
                                        v-if="model.photoAfterUrl"
                                        :src="model.photoAfterUrl"
                                        width="240"
                                        height="300"
                                        class="mt-1"
                                        style="object-fit: cover; object-position: center;"
                                    />
                                    <small class="block mt-1">Aspect ratio: 4 x 5</small>
                                    <small class="block" style="margin-top: 4px;">Recommended dimensions: 500 x 625 pixels</small>
                                    <small class="block" style="margin-top: 4px;">Max width: 500 pixels</small>
                                </div>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    @change="(e) => {
                                        isPhotoAfterRequired = true;
                                        validate(e).then((res) => storePhotoAfter(res, e));
                                    }"
                                    id="photoAfter"
                                    class="hidden"
                                    placeholder="Upload Photo After"
                                    ref="photoAfter"
                                >
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Period" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="period">Period <span class="alert-required">*</span></label>
                                <div class="d-flex flex-row align-items-center" style="gap: .5rem">
                                    <input type="number" id="period" @keypress="numberOnly" v-model.number="model.period" class="form-control" placeholder="Enter Period">
                                    <span>month(s)</span>
                                </div>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="text-left mt-4">
                        <router-link to="/testimony-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{ isEdit ? 'Update' : 'Create' }}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

import StarRating from 'vue-star-rating';
import { replaceAllEnterWithSpace } from '@/helper/string';

export default {
    components: {
        StarRating,
	},
    data() {
        return {
            model: {
                name: '',
                age: null,
                sex: "",
                rating: null,
                date: null,
                review: '',
                photoBefore: null,
                photoAfter: null,
                photoBeforeUrl: '',
                photoAfterUrl: '',
                period: null,
                selectedCondition: null,
            },
            conditions: [],
            isLoading: false,
            errors: null,
            messageError: null,
            isEdit: Boolean(this.$route.params.id),
            isPhotoBeforeRequired: true,
            isPhotoAfterRequired: true,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getDetail() {
            this.isLoading = true;
            this.axios.get(`/testimony-library/${this.$route.params.id}`)
                .then(resp => {
                    const { data } = resp.data;
                    this.model = {
                        name: data.name,
                        age: data.age,
                        sex: data.sex,
                        rating: data.rating,
                        date: data.date,
                        review: data.review,
                        photoBeforeUrl: data.photoBefore,
                        photoAfterUrl: data.photoAfter,
                        period: data.period,
                        selectedCondition: this.conditions.find(c => c.id === data.conditionId),
                    };
                    this.isPhotoBeforeRequired = false;
                    this.isPhotoAfterRequired = false;
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    });
                    this.$router.push('/testimony-library');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getConditions() {
            return new Promise((resolve) => {
                this.axios.get('/dc-condition-page/list-all')
                    .then(resp => {
                        this.conditions = resp.data.data;
                        resolve(true);
                    });
            });
        },
        numberOnly(event) {
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        },
        disableFutureDate(date) {
		    return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
		},
        storePhotoBefore({ valid }, event){
            if (valid) {
                this.$set(this.model, 'photoBefore', event.target.files[0]);
                this.$set(this.model, 'photoBeforeUrl', window.URL.createObjectURL(event.target.files[0]));
            }
        },
        storePhotoAfter({ valid }, event){
            if (valid) {
                this.$set(this.model, 'photoAfter', event.target.files[0]);
                this.$set(this.model, 'photoAfterUrl', window.URL.createObjectURL(event.target.files[0]));
            }
        },
        async save() {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                const formDataPhotoBefore = new FormData();
                formDataPhotoBefore.append('folder', 'contents');
                formDataPhotoBefore.append('file', this.model.photoBefore);

                const formDataPhotoAfter = new FormData();
                formDataPhotoAfter.append('folder', 'contents');
                formDataPhotoAfter.append('file', this.model.photoAfter);

                const headers = {
                    'Content-Type': 'multipart/form-data',
                };

                try {
                    this.isLoading = true;
                    let [responsePhotoBefore, responsePhotoAfter] = [null, null];
                    if (this.isEdit) {
                        responsePhotoBefore = this.model.photoBefore
                            ? await this.axios.post('/misc/upload', formDataPhotoBefore, { headers })
                            : null;
                        responsePhotoAfter = this.model.photoAfter
                            ? await this.axios.post('/misc/upload', formDataPhotoAfter, { headers })
                            : null;
                    } else {
                        [responsePhotoBefore, responsePhotoAfter] = await Promise.all([
                            this.axios.post('/misc/upload', formDataPhotoBefore, { headers }),
                            this.axios.post('/misc/upload', formDataPhotoAfter, { headers }),
                        ]);
                    }

                    const payload = {
                        name: this.model.name,
                        age: this.model.age === null || this.model.age === '' ? null : this.model.age,
                        sex: this.model.sex,
                        conditionId: this.model.selectedCondition.id,
                        rating: this.model.rating || null,
                        date: this.model.date,
                        review: replaceAllEnterWithSpace(this.model.review),
                        photoBefore: responsePhotoBefore ? responsePhotoBefore.data.data.file : this.model.photoBeforeUrl,
                        photoAfter: responsePhotoAfter ? responsePhotoAfter.data.data.file : this.model.photoAfterUrl,
                        period: this.model.period,
                    };

                    if (this.isEdit) {
                        this.axios.put(`/testimony-library/${this.$route.params.id}`, payload)
                            .then(resp => {
                                this.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                });
                                this.$router.push('/testimony-library');
                            })
                            .catch(err => {
                                this.errors = err.response.data.message;
                                this.messageError = err.response.data.message;
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    } else {
                        this.axios.post('/testimony-library', payload)
                            .then(resp => {
                                this.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                });
                                this.$router.push('/testimony-library');
                            })
                            .catch(err => {
                                this.errors = err.response.data.message;
                                this.messageError = err.response.data.message;
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    }
                } catch (err) {
                    this.isLoading = false;
                    this.errors = true;
                    this.messageError = 'Failed to upload photo';
                }
            }
        }
        
    },
    async mounted() {
        await this.getConditions();
        if (this.isEdit) {
            this.getDetail();
        }
    }
}
</script>
