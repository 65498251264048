<template>
  <div class="card">
    <div class="card-body">
      <Table 
        :dataTable="table.dataTable"
        :title="table.title">
        <template v-slot:header>
          <div class="row">
            <div class="col-lg-8 col-xs-12">
                <div class="btn-responsive">
                    <button type="button" class="btn btn-primary" @click="$router.push('/school-card/add')">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
            </div>
            <div class="col mb-1">
                <div class="input-group form-search-rounded">
                    <input class="form-control" v-model="search" type="search"
                        placeholder="Search by keyword...">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <i class="fa fa-search"></i>
                        </div>
                    </span>
                </div>
            </div>
          </div>                
        </template>

        <template v-slot:thead>
          <thead>
            <tr>
                <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
            </tr>
          </thead>
        </template>

        <template v-slot:action="{ data }">
          <div class="btn-action-block d-flex justify-content-center align-items-center">
            <router-link :to="'/school-card/edit/' + data.id">
                <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                    <i class="fa fa-edit"></i>
                </button>
            </router-link>
            <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                <i class="fa fa-trash"></i>
            </button>
          </div>
        </template> 
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";
import { truncate } from '@/helper';

export default {
	components: {
        Table
	},
	data() {
		return {
            table: {
                title: 'School Card List',
                dataTable: {
                    header: ['#', 'Card Title', 'Action'],
                    columns: ['counter', 'title'],
                    lists: {},
                }
            },
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            debounce: null
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('otc/school-card?page=' + (page ? page : 1) + '&search=' + _.search + '&limit=' + _.limit + '&sortBy=updatedAt.DESC')
                .then((resp)=>{
                    _.table.dataTable.lists = resp.data.data
                    _.table.dataTable.lists.rows = _.table.dataTable.lists.rows.map(row => {
                        row.title = truncate(row.title, 80)
                        return row
                    })
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete card?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                focusCancel: true
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('otc/school-card/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                _.$swal.fire({
                                    title: 'Failed!',
                                    text: err.response.data.message,
                                    icon: 'error',
                                    confirmButtonColor: '#0036A0',
                                    confirmButtonText: 'OK'
                                })
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>