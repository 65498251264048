<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/vuexy-login-bg.jpg" class="img-fluid" alt="">
        </div>
        <div class="login-form text-center">
            <img src="/assets/img/error/404.png" alt="404" class="img-fluid" width="50%">
                <p class="font-500 mt-2 f-20">Page Not Found</p>
                <p class="text-secondary f-14">
                    The page you are looking for doesn't exist or an other error occured.<br>
                    <a @click="back()">Go Back</a>, or head over to <router-link to="/">Dashboard</router-link>
                </p>
        </div>
    </div> 
</template>
<script>

export default {
    methods: {
        back() {
            this.$router.back()
        }
    }
}
</script>
