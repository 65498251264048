<template>
    <div class="card">
        <div class="card-body">
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="title" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Title <span class="alert-required">*</span></label>
                                <input type="text" v-model="model.title" class="form-control" placeholder="Enter Title">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="bannerColor" v-slot="{ errors }" rules="" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Background Header Color</label>
                                <sketch-picker v-model="model.bannerColor" @input="updateColor"></sketch-picker>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">    
                        <ValidationProvider name="imageAlt" v-slot="{ errors }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Alt Image</label>
                                <input type="text" v-model="model.imageAlt" class="form-control" placeholder="Enter Alt Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                    </div>
                    <ValidationProvider name="image" v-slot="{ errors }" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Image</label>
                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="image" class="form-control">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <input type="hidden" v-model="model.image" class="form-control" placeholder="Enter Alt Image">
                    <ValidationProvider class="col">
                        <div class="form-group" v-if="model.thumbnailImage">
                            <label>Preview Image</label>
                            <div class="close-preview" v-if="model.thumbnailImage !=''">
                                <button type="button" @click="deletestoreImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                            </div>
                            <img :src="model.thumbnailImage" class="responsive img-thumbnail" />
                        </div>
                        <div class="form-group" v-else>
                            <label>Preview Image</label>
                            <div class="close-preview" v-if="model.image !=''">
                                <button type="button" @click="deletestoreImage" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                            </div>
                            <img :src="model.image" class="responsive img-thumbnail" />
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="bodyTitle" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Body Title</label>
                                <input type="text" v-model="model.bodyTitle" class="form-control" placeholder="Enter Body Title">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="bodyDescription" v-slot="{ errors }" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Body Description</label>
                            <quill-editor v-model="model.bodyDescription" :options="editorOption"></quill-editor>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>

                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    data() {
        return {
            collapseType: 'margin',
            model: {
                title: '',
                bannerColor: '',
                image: '',
                thumbnailImage: '',
                imageAlt: '',
                bodyTitle: '',
                bodyDescription: ''
            },
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-terms')
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        deletestoreImage(){
            this.model.image = '';
            this.model.thumbnailImage = '';
            this.success=true;
            this.message_error=null;
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("title", _.model.title);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("image", _.model.image);
                formData.append("bodyTitle", _.model.bodyTitle);
                formData.append("bodyDescription", _.model.bodyDescription);
                _.axios.put('/dc-terms', formData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.success = resp.data.success
                    _.isLoading = false
                })
                .catch(err => {
                    _.errors = err.response.data.message
                    _.message_error = err.response.data.message
                    _.success = err.response.data.success
                    _.isLoading = false
                })
            }
        },
    },
    mounted() {
        let _ = this;
        _.get();
    },
};
</script>
