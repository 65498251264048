<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Mobile Homepage</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false || image_size || image_size_testi || image_size_after || image_size_before">
                <div class="text-danger" v-if="message_error=='File too large' || image_size || image_size_testi || image_size_after || image_size_before">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Section Banner"> 
                            <ValidationProvider name="sectionBanner" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionBanner">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <section>
                                <span v-for="(page, idx) in model.mwebBannerImageMultiple" :key="idx">
                                    <div class="row mt-3">
                                        <ValidationProvider name="link" v-slot="{ errors }" :rules="{regex:/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/}" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Url Image </label>
                                                <input type="text" v-model="model.mwebBannerImageMultiple[idx].link" @input="changeUrlImage($event, idx)" @change="changeUrlImage($event, idx)" class="form-control" placeholder="Enter URL https://www.url.com">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="image" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Image</label>
                                                <input input type="file" accept=".png, .jpg, .jpeg" @change="storeImageBanner($event, idx)" label="Image" name="image" class="form-control">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <input type="hidden" v-model="model.mwebBannerImageMultiple[idx].image" class="form-control" placeholder="Enter Alt Image">
                                    </div>
                                    <div class="row">
                                        <ValidationProvider class="col">
                                            <div class="form-group">
                                                <label>Preview Image</label>
                                                <img :src="model.mwebBannerImageMultiple[idx].image" class="responsive img-thumbnail" />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <button type="button" class="btn btn-danger mr-2" @click="deleteBanner(idx)" v-if="model.mwebBannerImageMultiple.length > 0"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                                </span>
                                <button type="button" class="btn btn-primary" @click="addBanner()"><i class="fa fa-plus"></i> Add Content</button>
                            </section>
                            <small class="text-danger" v-show="no_image">The image field is required</small>
                        </app-collapse-item>
                        <app-collapse-item title="Section Condition">
                            <ValidationProvider name="mwebSectionCondition" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.mwebSectionCondition">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <ValidationProvider name="mwebConditionTitle" v-slot="{ errors }" :rules="model.mwebSectionCondition == true ? 'required': ''" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Title <span class="alert-required" v-show="model.sectionBanner == true">*</span></label>
                                    <input type="text" v-model="model.mwebConditionTitle" class="form-control" placeholder="Enter Title">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="mwebConditionDescription" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description </label>
                                    <textarea name="mwebConditionDescription" id="" cols="30" rows="5" v-model="model.mwebConditionDescription" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="mwebListConditions" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Conditions</label>
                                    <multiselect 
                                        v-model="model.mwebListConditions"
                                        name="conditionpages"
                                        track-by="id" 
                                        placeholder="Search Condition"
                                        :options="conditionpages" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600"
                                        :custom-label="customLabelCondition">
                                        <span slot="noResult">Oops! There is no condition.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Promo Banner">   
                            <ValidationProvider name="mwebSectionPromoBanner" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.mwebSectionPromoBanner">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr/> 
                            <ValidationProvider name="bannerTitle" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Promo Banner Title</label>
                                    <quill-editor v-model="model.bannerTitle" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="mwebPromoBannerDescription" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Promo Banner Description</label>
                                    <quill-editor v-model="model.mwebPromoBannerDescription" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <div class="row">
                                <ValidationProvider name="bannerId" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="descriptionInput">Promo Banner</label>
                                        <multiselect 
                                            v-model="model.bannerId"
                                            label="name"
                                            name="banners"
                                            track-by="id" 
                                            placeholder="Search Promo Banner"
                                            :options="banners" 
                                            :multiple="true" 
                                            :searchable="true" 
                                            :clear-on-select="false" 
                                            :close-on-select="false" 
                                            :max-height="600">
                                            <span slot="noResult">Oops! There is no promo banner.</span>
                                        </multiselect>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Product">
                            <ValidationProvider name="mwebSectionProductLanding" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.mwebSectionProductLanding">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <section>
                                <span v-for="(page, idx) in model.productLanding" :key="idx">
                                    <div class="row mt-3">
                                        <ValidationProvider name="title" v-slot="{ errors }" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label>Title</label>
                                                <input type="text" v-model="model.productLanding[idx].title" class="form-control" placeholder="Enter Title">
                                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="subTitle" v-slot="{ errors }" class="col">
                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                <label>Sub Title</label>
                                                <input type="text" v-model="model.productLanding[idx].subTitle" class="form-control" placeholder="Enter Sub Title">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="row">
                                        <ValidationProvider name="productId" v-slot="{ errors }" rules="" class="col">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="descriptionInput">Catalog Product</label>
                                                <multiselect
                                                    v-model="model.productLanding[idx].productId"
                                                    label="title"
                                                    name="products"
                                                    track-by="id" 
                                                    placeholder="Search Catalog Product"
                                                    :options="products" 
                                                    :multiple="true" 
                                                    :searchable="true" 
                                                    :clear-on-select="false" 
                                                    :close-on-select="false" 
                                                    :max-height="600">
                                                    <span slot="noResult">Oops! There is no catalog product.</span>
                                                </multiselect>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <ValidationProvider name="url see all" v-slot="{ errors }" :rules="{regex:/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/}" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Url See All </label>
                                            <input  type="text" v-model="model.productLanding[idx].link" class="form-control" placeholder="Enter URL https://www.url.com">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <button type="button" class="btn btn-danger mr-2" @click="deleteProductLanding(idx)" v-if="model.productLanding.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                                </span>
                                <button type="button" class="btn btn-primary" @click="addProductLanding()"><i class="fa fa-plus"></i> Add Content</button>
                            </section>
                        </app-collapse-item>
                        <app-collapse-item title="Section Testimonials">
                            <ValidationProvider name="sectionTestimoni" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionTestimoni">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="testimoniTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.testimoniTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="testimoniDescription" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea name="testimoniDescription" id="" cols="30" rows="5" v-model="model.testimoniDescription" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="How to Section">
                            <ValidationProvider name="mwebSectionStep" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.mwebSectionStep">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="mwebStepTitle" v-slot="{ errors }" rules="" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.mwebStepTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="mwebStepDescription" v-slot="{ errors }" rules="" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description </label>
                                        <quill-editor v-model="model.mwebStepDescription" :options="editorOption"></quill-editor>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr/>
                            <h5 class="font-600 mb-2">Step Section</h5>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="mwebStep1Title" v-slot="{ errors }" rules="" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 1 </label>
                                            <input type="text" v-model="model.mwebStep1Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="mwebStep1Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 1</label>
                                            <quill-editor v-model="model.mwebStep1Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="url 1" v-slot="{ errors }" :rules="{regex:/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/}" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Url 1</label>
                                            <input type="text" v-model="model.mwebStep1Url" class="form-control" placeholder="Enter URL https://www.url.com">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="mwebStep1Icon" v-slot="{ errors }" rules="" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Icon 1</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storemwebStep1Icon($event)" label="Image" name="mwebStep1Icon" class="form-control">
                                            <input type="hidden" v-model="model.mwebStep1Icon" class="form-control" placeholder="Enter Image">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            <small class="text-danger" v-show="image_size_icon1">The image file is too large, allowed maximum size is {{max_file_size}}MB</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group">
                                            <label>Preview Icon</label>
                                            <img :src="model.mwebStep1Icon" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="mwebStep1Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 2</label>
                                            <input type="text" v-model="model.mwebStep2Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="mwebStep1Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 2</label>
                                            <quill-editor v-model="model.mwebStep2Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="url 2" v-slot="{ errors }" :rules="{regex:/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/}" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Url 2</label>
                                            <input type="text" v-model="model.mwebStep2Url" class="form-control" placeholder="Enter URL https://www.url.com">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="mwebStep2Icon" v-slot="{ errors }" rules="" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Icon 2</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storemwebStep2Icon($event)" label="Image" name="mwebStep2Icon" class="form-control">
                                            <input type="hidden" v-model="model.mwebStep2Icon" class="form-control" placeholder="Enter Image">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            <small class="text-danger" v-show="image_size_icon2">The image file is too large, allowed maximum size is {{max_file_size}}MB</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group">
                                            <label>Preview Icon</label>
                                            <img :src="model.mwebStep2Icon" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                            <hr/>
                            <section>
                                <div class="row">
                                    <ValidationProvider name="mwebStep3Title" v-slot="{ errors }" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Title 3</label>
                                            <input type="text" v-model="model.mwebStep3Title" class="form-control" placeholder="Enter Title">
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="mwebStep3Description" v-slot="{ errors }" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Description 3</label>
                                            <quill-editor v-model="model.mwebStep3Description" :options="editorOption"></quill-editor>
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider name="mwebStep3Url" v-slot="{ errors }" :rules="{regex:/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/}" class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label>Url 3</label>
                                            <input type="text" v-model="model.mwebStep3Url" class="form-control" placeholder="Enter URL https://www.url.com">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="mwebStep3Icon" v-slot="{ errors }" rules="" class="col">
                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                            <label>Icon 3</label>
                                            <input input type="file" accept=".png, .jpg, .jpeg" @change="storemwebStep3Icon($event)" label="Image" name="mwebStep3Icon" class="form-control">
                                            <input type="hidden" v-model="model.mwebStep3Icon" class="form-control" placeholder="Enter Image">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            <small class="text-danger" v-show="image_size_icon3">The image file is too large, allowed maximum size is {{max_file_size}}MB</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <ValidationProvider class="col">
                                        <div class="form-group">
                                            <label>Preview Icon</label>
                                            <img :src="model.mwebStep3Icon" class="responsive img-thumbnail" />
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </section>
                        </app-collapse-item>     
                        <app-collapse-item title="Section Clinical Team">
                            <ValidationProvider name="sectionDoctor" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionDoctor">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="doctorTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.doctorTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="doctorDescription" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea name="doctorDescription" id="" cols="30" rows="5" v-model="model.doctorDescription" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="doctorId" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Clinical Team</label>
                                    <multiselect 
                                        v-model="model.doctorId"
                                        label="name"
                                        name="doctors"
                                        track-by="id" 
                                        placeholder="Search Doctor"
                                        :options="doctors" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no doctor.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Article">
                            <ValidationProvider name="sectionArticle" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Active ?</label>
                                    <select class="form-control" v-model="model.sectionArticle">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="articleTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title</label>
                                        <input type="text" v-model="model.articleTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="articleDescription" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <textarea name="articleDescription" id="" cols="30" rows="5" v-model="model.articleDescription" class="form-control"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="articleId" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Articles</label>
                                    <multiselect 
                                        v-model="model.articleId"
                                        label="title"
                                        name="articles"
                                        track-by="id" 
                                        placeholder="Search Article"
                                        :options="articles" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false"
                                        :limit="3" 
                                        :options-limit="300"
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no article.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section F.A.Q">
                            <section>
                                <ValidationProvider name="sectionFaq" v-slot="{ errors }">
                                    <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                        <label>Is Active ?</label>
                                        <select class="form-control" v-model="model.sectionFaq">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <ValidationProvider name="faqTitle" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title F.A.Q</label>
                                        <input type="text" v-model="model.faqTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="faqSubtitle" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Subtitle F.A.Q</label>
                                        <textarea name="faqSubtitle" id="" cols="30" rows="3" v-model="model.faqSubtitle" class="form-control" placeholder="Enter Subtitle"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="row">
                                    <div class="form-group col">
                                        <label for="descriptionInput">FAQ</label>
                                        <multiselect
                                            v-model="model.faqs"
                                            label="question"
                                            name="faqs"
                                            track-by="id"
                                            placeholder="Search FAQ"
                                            :options="faqs"
                                            :multiple="true"
                                            :searchable="true"
                                            :clear-on-select="false"
                                            :close-on-select="false"
                                            :max-height="600"
                                        >
                                            <span slot="noResult">Oops! There is no FAQs.</span>
                                        </multiselect>
                                    </div>
                                </div>
                            </section>
                        </app-collapse-item>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large' || !isValidURLAll ||  !isValidURL1 ||  !isValidURL2 ||  !isValidURL3 || !isValidURL || !isValidURLImage">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import ld from 'lodash'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            model: {
                mwebBannerImageMultiple: [
                    {
                        image: null,
                        imageLink: null,
                        thumbnailimageLink: null,
                        link: null
                    }
                ],
                mwebBannerLink: '',
                bannerTitle: '',
                mwebSectionPromoBanner: true,
                mwebPromoBannerDescription: '',
                preTitle: '',
                title: '',
                bannerId: [],
                description: '',
                bannerColor: '',
                image: '',
                thumbnailimage: '',
                imageAlt: '',
                sectionBanner: true,
                polyClinicTitle: '',
                polyClinicDescription: '',
                polyClinicId: [],
                sectionPolyClinic: true,
                treatmentTitle: '',
                treatmentDescription: '',
                treatment1Image: '',
                thumbnailtreatment1Image: '',
                treatment1ImageAlt: '',
                treatment1Title: '',
                treatment1Description: '',
                treatment2Image: '',
                thumbnailtreatment2Image: '',
                treatment2ImageAlt: '',
                treatment2Title: '',
                treatment2Description: '',
                treatment3Image: '',
                thumbnailtreatment3Image: '',
                treatment3ImageAlt: '',
                treatment3Title: '',
                treatment3Description: '',
                sectionStepTreat: '',
                stepTreatTitle: '',
                stepTreatDescription: '',
                stepTreat1Image: '',
                thumbnailstepTreat1Image: '',
                stepTreat1ImageAlt: '',
                stepTreat1Title: '',
                stepTreat1Description: '',
                stepTreat2Image: '',
                thumbnailstepTreat2Image: '',
                stepTreat2ImageAlt: '',
                stepTreat2Title: '',
                stepTreat2Description: '',
                stepTreat3Image: '',
                thumbnailstepTreat3Image: '',
                stepTreat3ImageAlt: '',
                stepTreat3Title: '',
                stepTreat3Description: '',
                sectionTreatment: true,
                sectionTestimoni: true,
                testimoniTitle: '',
                testimoniDescription: '',
                sectionDoctor: '',
                doctorTitle: '',
                doctorId: [],
                doctorDescription: '',
                sectionArticle: true,
                articleTitle: '',
                articleDescription: '',
                buttonConsult1: '',
                buttonConsult2: '',
                buttonConsult3: '',
                articleId: [],
                sectionFaq: true,
                faqTitle: '',
                faqSubtitle: '',
                mwebSectionCondition: true,
                mwebConditionTitle: '',
                mwebConditionDescription: '',
                mwebListConditions : [],
                mwebSectionStep: true,
                mwebStepTitle: '',
                mwebStepDescription: '',
                mwebStep1Title: '',
                mwebStep1Description: '',
                mwebStep1Icon: '',
                thumbnailmwebStep1Icon: '',
                mwebStep1Url: '',
                mwebStep2Title: '',
                mwebStep2Description: '',
                mwebStep2Icon: '',
                thumbnailmwebStep2Icon: '',
                mwebStep2Url: '',
                mwebStep3Title: '',
                mwebStep3Description: '',
                mwebStep3Icon: '',
                thumbnailmwebStep3Icon: '',
                mwebStep3Url: '',
                mwebSectionProductLanding: true,
                productLanding: [
                    {
                        title:null,
                        subTitle: null,
                        link: null,
                        productId: []
                    }
                ],
                faqs: [
                    {
                        answer: null,
                        question: null,
                    }
                ],
            },
            articles: [],
            doctors: [],
            polys: [],
            banners: [],
            buttons: [],
            conditionpages: [],
            products: [],
            faqs: [],
            isLoading: false,
            errors: null,
            error_invalid: false,
            message_error: null,
            success: true,
            no_image: false,
            image_size: false,
            image_size_testi: false,
            image_size_before: false,
            image_size_after: false,
            image_size_icon1: false,
            image_size_icon2: false,
            image_size_icon3: false,
            max_file_size: 2,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            },
            isValidURLImage: true,
            isValidURL: true,
            isValidURLAll: true,
            isValidURL1: true,
            isValidURL2: true,
            isValidURL3: true,
        }
    },
    methods: {
        disabledDate(date) {
            return new Date() < date;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        changeUrlImage(event, idx){
            let links = event.target.value
            const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
            if(links == null || links == '' || links == undefined){
                this.isValidURLImage = true
            } else {
                this.isValidURLImage = regex.test(links)
            }
            this.model.mwebBannerImageMultiple[idx].link = links;
        },
        changeUrlAll(e){
            const url = e.target.value
            this.isURLValidAll(url);
        },
        isURLValidAll: function(inputUrl) {
            const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm;
            if(inputUrl == null || inputUrl == '' || inputUrl == undefined){
                this.isValidURLAll = true
            } else {
                this.isValidURLAll = regex.test(inputUrl)
            }
        },
        changeUrl1(e){
            const url = e.target.value
            this.isURLValid1(url);
        },
        isURLValid1: function(inputUrl) {
            const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm;
            if(inputUrl == null || inputUrl == '' || inputUrl == undefined){
                this.isValidURL1 = true
            } else {
                this.isValidURL1 = regex.test(inputUrl)
            }
        },
        changeUrl2(e){
            const url = e.target.value
            this.isURLValid2(url);
        },
        isURLValid2: function(inputUrl) {
            const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm;
            if(inputUrl == null || inputUrl == '' || inputUrl == undefined){
                this.isValidURL2 = true
            } else {
                this.isValidURL2 = regex.test(inputUrl)
            }
        },
        changeUrl3(e){
            const url = e.target.value
            this.isURLValid3(url);
        },
        isURLValid3: function(inputUrl) {
            const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm;
            if(inputUrl == null || inputUrl == '' || inputUrl == undefined){
                this.isValidURL3 = true;
            } else {
                this.isValidURL3 = regex.test(inputUrl)
            }
        },
        change:function(e){
            const url = e.target.value
            this.isURLValid(url);
        },
        isURLValid: function(inputUrl) {
            const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm;
            if(inputUrl == null || inputUrl == '' || inputUrl == undefined){
                this.isValidURL = true
            } else {
                this.isValidURL = regex.test(inputUrl)
            }
        },
        addBanner() {
            let _ = this
            _.model.mwebBannerImageMultiple.push({
                image: null,
                link: null
            })
        },
        deleteBanner(x) {
            let _ = this
            _.model.mwebBannerImageMultiple.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        addProductLanding() {
            let _ = this
            _.model.productLanding.push({
                title:null,
                subTitle: null,
                link: null,
                productId: []
            })
        },
        deleteProductLanding(x) {
            let _ = this
            _.model.productLanding.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        get() {
            let _ = this
            _.axios.get('/m-landing-page/new-detail')
                .then(resp => {
                    _.model = resp.data.data
                    _.model.bannerId = _.model.banners
                    _.model.polyClinicId = _.model.polys
                    _.model.doctorId = _.model.doctors
                    _.model.articleId = _.model.articles

                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageTreatment1(event){
            this.model.treatment1Image = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatment1Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageTreatment2(event){
            this.model.treatment2Image = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatment2Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageTreatment3(event){
            this.model.treatment3Image = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatment3Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageStepTreatment1(event){
            this.model.stepTreat1Image = event.target.files[0];
            this.$set(this.model, 'thumbnailstepTreat1Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageStepTreatment2(event){
            this.model.stepTreat2Image = event.target.files[0];
            this.$set(this.model, 'thumbnailstepTreat2Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageStepTreatment3(event){
            this.model.stepTreat3Image = event.target.files[0];
            this.$set(this.model, 'thumbnailstepTreat3Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImageBanner(event, idx){
            let images = event.target.files[0]
            if (images.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size = true
                return;
            } else {
                this.image_size = false
            }
            this.model.mwebBannerImageMultiple[idx].image = images;
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "mwebBannerImageMultiple");
            formData.append("file", this.model.mwebBannerImageMultiple[idx].image);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.mwebBannerImageMultiple[idx].image = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        storemwebStep1Icon(event){
            this.model.mwebStep1Icon = event.target.files[0];
            if (this.model.mwebStep1Icon.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size_icon1 = true
                return;
            } else {
                this.image_size_icon1 = false
            }
            this.$set(this.model, 'thumbnailmwebStep1Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "mwebstepimage");
            formData.append("file", this.model.mwebStep1Icon);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.mwebStep1Icon = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        storemwebStep2Icon(event){
            this.model.mwebStep2Icon = event.target.files[0];
            if (this.model.mwebStep2Icon.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size_icon2 = true
                return;
            } else {
                this.image_size_icon2 = false
            }
            this.$set(this.model, 'thumbnailmwebStep2Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "mwebstepimage");
            formData.append("file", this.model.mwebStep2Icon);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.mwebStep2Icon = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        storemwebStep3Icon(event){
            this.model.mwebStep3Icon = event.target.files[0];
            if (this.model.mwebStep3Icon.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size_icon3 = true
                return;
            } else {
                this.image_size_icon3 = false
            }
            this.$set(this.model, 'thumbnailmwebStep3Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "mwebstepimage");
            formData.append("file", this.model.mwebStep3Icon);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.mwebStep3Icon = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        handleFileUpload(event){
            this.file = event.target.files[0]
            if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size = true
                return;
            } else {
                this.image_size = false
            }

            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData()
            formData.append('folder', 'image-banner')
            formData.append('file', this.file)
            this.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    this.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    this.model.mwebBannerImageMultiple.push(resp.data.data.file)
                    this.success = resp.data.success
                    this.message_error = resp.data.message
                    this.isLoading = false
                }).catch(err => {
                    this.errors = err.response.data.message
                    this.message_error = err.response.data.message
                    this.success = err.response.data.success
                    this.isLoading = false
                })
        },
        deleteFile (url){
            let _ = this
            _.model.mwebBannerImageMultiple = ld.filter(_.model.mwebBannerImageMultiple, function(o){
                return o != url;
            })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        updateRating() {
            this.isLoading = false
            this.errors = null
            this.message_error = null
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                let tampproduct = []
                _.model.productLanding.forEach(item => {
                    tampproduct.push({
                        title:item.title,
                        subTitle: item.subTitle,
                        link: item.link,
                        productId: JSON.stringify(item.productId.map(p => p.id))
                    })
                });
                _.isLoading = true
                let formData = new FormData();
                formData.append("mwebBannerImageMultiple", JSON.stringify(_.model.mwebBannerImageMultiple));
                formData.append("mwebBannerLink", _.model.mwebBannerLink);
                formData.append("preTitle", _.model.preTitle);
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("image", _.model.image);
                formData.append("mwebSectionPromoBanner", _.model.mwebSectionPromoBanner);
                formData.append("bannerTitle", _.model.bannerTitle);
                formData.append("mwebPromoBannerDescription", _.model.mwebPromoBannerDescription)
                formData.append("bannerId", JSON.stringify(_.model.bannerId.map(p => p.id)));
                formData.append("sectionBanner", _.model.sectionBanner);
                formData.append("sectionPolyClinic", _.model.sectionPolyClinic);
                formData.append("polyClinicTitle", _.model.polyClinicTitle);
                formData.append("polyClinicDescription", _.model.polyClinicDescription);
                formData.append("polyClinicId", JSON.stringify(_.model.polyClinicId.map(p => p.id)));
                formData.append("sectionTreatment", _.model.sectionTreatment);
                formData.append("treatmentTitle", _.model.treatmentTitle);
                formData.append("treatmentDescription", _.model.treatmentDescription);
                formData.append("treatment1Image", _.model.treatment1Image);
                formData.append("treatment1ImageAlt", _.model.treatment1ImageAlt);
                formData.append("treatment1Title", _.model.treatment1Title);
                formData.append("treatment1Description", _.model.treatment1Description);
                formData.append("treatment2Image", _.model.treatment2Image);
                formData.append("treatment2ImageAlt", _.model.treatment2ImageAlt);
                formData.append("treatment2Title", _.model.treatment2Title);
                formData.append("treatment2Description", _.model.treatment2Description);
                formData.append("treatment3Image", _.model.treatment3Image);
                formData.append("treatment3ImageAlt", _.model.treatment3ImageAlt);
                formData.append("treatment3Title", _.model.treatment3Title);
                formData.append("treatment3Description", _.model.treatment3Description);
                formData.append("sectionStepTreat", _.model.sectionStepTreat);
                formData.append("stepTreatTitle", _.model.stepTreatTitle);
                formData.append("stepTreatDescription", _.model.stepTreatDescription);
                formData.append("stepTreat1Image", _.model.stepTreat1Image);
                formData.append("stepTreat1ImageAlt", _.model.stepTreat1ImageAlt);
                formData.append("stepTreat1Title", _.model.stepTreat1Title);
                formData.append("stepTreat1Description", _.model.stepTreat1Description);
                formData.append("stepTreat2Image", _.model.stepTreat2Image);
                formData.append("stepTreat2ImageAlt", _.model.stepTreat2ImageAlt);
                formData.append("stepTreat2Title", _.model.stepTreat2Title);
                formData.append("stepTreat2Description", _.model.stepTreat2Description);
                formData.append("stepTreat3Image", _.model.stepTreat3Image);
                formData.append("stepTreat3ImageAlt", _.model.stepTreat3ImageAlt);
                formData.append("stepTreat3Title", _.model.stepTreat3Title);
                formData.append("stepTreat3Description", _.model.stepTreat3Description);
                formData.append("sectionTestimoni", _.model.sectionTestimoni);
                formData.append("testimoniTitle", _.model.testimoniTitle);
                formData.append("testimoniDescription", _.model.testimoniDescription);
                formData.append("sectionDoctor", _.model.sectionDoctor);
                formData.append("doctorTitle", _.model.doctorTitle);
                formData.append("doctorDescription", _.model.doctorDescription);
                formData.append("doctorId", JSON.stringify(_.model.doctorId.map(p => p.id)));
                formData.append("buttonConsult1", _.model.buttonConsult1);
                formData.append("buttonConsult2", _.model.buttonConsult2);
                formData.append("buttonConsult3", _.model.buttonConsult3);
                formData.append("sectionArticle", _.model.sectionArticle);
                formData.append("articleTitle", _.model.articleTitle);
                formData.append("articleDescription", _.model.articleDescription);
                formData.append("articleId", JSON.stringify(_.model.articleId.map(p => p.id)));
                formData.append("sectionFaq", _.model.sectionFaq);
                formData.append("faqTitle", _.model.faqTitle);
                formData.append("faqSubtitle", _.model.faqSubtitle);
                formData.append("faqIds", JSON.stringify(_.model.faqs.map(faq => faq.id)));
                formData.append("mwebSectionCondition", _.model.mwebSectionCondition);
                formData.append("mwebConditionTitle", _.model.mwebConditionTitle);
                formData.append("mwebConditionDescription", _.model.mwebConditionDescription);
                formData.append("mwebListCondition", JSON.stringify(_.model.mwebListConditions.map(p => p.id)));
                formData.append("mwebSectionStep", _.model.mwebSectionStep);
                formData.append("mwebStepTitle", _.model.mwebStepTitle);
                formData.append("mwebStepDescription", _.model.mwebStepDescription);
                formData.append("mwebStep1Title", _.model.mwebStep1Title);
                formData.append("mwebStep1Description", _.model.mwebStep1Description);
                formData.append("mwebStep1Icon", _.model.mwebStep1Icon);
                formData.append("mwebStep1Url", _.model.mwebStep1Url);
                formData.append("mwebStep2Title", _.model.mwebStep2Title);
                formData.append("mwebStep2Description", _.model.mwebStep2Description);
                formData.append("mwebStep2Icon", _.model.mwebStep2Icon);
                formData.append("mwebStep2Url", _.model.mwebStep2Url);
                formData.append("mwebStep3Title", _.model.mwebStep3Title);
                formData.append("mwebStep3Description", _.model.mwebStep3Description);
                formData.append("mwebStep3Icon", _.model.mwebStep3Icon);
                formData.append("mwebStep3Url", _.model.mwebStep3Url);
                formData.append("productLanding", JSON.stringify(tampproduct));
                formData.append("mwebSectionProductLanding", _.model.mwebSectionProductLanding);
                _.axios.put('/m-landing-page', formData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.success = resp.data.success
                    setTimeout(function() {
                        location.reload();
                    }, 3000);
                    _.isLoading = false
                })
                .catch(err => {
                    _.errors = err.response.data.message
                    _.message_error = err.response.data.message
                    _.success = err.response.data.success
                    _.isLoading = false
                })
            }
        },
        getArticles() {
           let _ = this
            _.axios.get('/dc-articles/list-all')
            .then(resp=>{
                _.articles = resp.data.data
            }) 
        },
        getDoctors() {
           let _ = this
            _.axios.get('/dc-doctor/list-all')
            .then(resp=>{
                _.doctors = resp.data.data
            }) 
        },
        getBanners() {
            let _ = this
            _.axios.get('/misc/promotions')
            .then(resp=>{
                _.banners = resp.data.data
            })
        },
        getPolyclinics() {
            let _ = this
            _.axios.get('/dc-poly/list-all')
            .then(resp=>{
                _.polys = resp.data.data
            })
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        getConditions() {
           let _ = this
            _.axios.get('/dc-condition-page/list-all')
            .then(resp=>{
                _.conditionpages = resp.data.data
            }) 
        },
        getCatalogProduct() {
           let _ = this
            _.axios.get('/misc/products')
            .then(resp=>{
                _.products = resp.data.data
            }) 
        },
        getFaqs() {
            this.axios.get('/dc-master-faq/list-all')
                .then(res => {
                    this.faqs = res.data.data;
                });
        },
        customLabelCondition({ namePage }) {
            return `${namePage}`
        },
    },
    mounted() {
        this.get();
        this.getArticles();
        this.getDoctors();
        this.getPolyclinics();
        this.getBanners();
        this.getButtons();
        this.getConditions();
        this.getCatalogProduct();
        this.getFaqs();
    }
}
</script>